import api from './index'

export interface IMarketSegment {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostMarketSegments extends Omit<IMarketSegment, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchMarketSegments extends IPostMarketSegments {}

interface IGetMarketSegmentsParams {
  start: number
  perPage: number
  search?: string
}

export async function postMarketSegment(data: IPostMarketSegments): Promise<{id: number}> {
  try {
    const res = await api.post(`/marketSegments`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getMarketSegments(
  params: IGetMarketSegmentsParams = {start: 0, perPage: 1000}
): Promise<{data: IMarketSegment[]; total: number}> {
  try {
    const res = await api.get(`/marketSegments`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getMarketSegment(id: number): Promise<IMarketSegment> {
  try {
    const res = await api.get(`/marketSegments/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchMarketSegment(id: number, data: IPatchMarketSegments): Promise<{id: number}> {
  try {
    const res = await api.patch(`/marketSegments/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteMarketSegment(id: number): Promise<void> {
  try {
    const res = await api.delete(`/marketSegments/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {ReactNode, useMemo} from 'react'
import {Breadcrumb, Space} from 'antd'
import {useLocation} from 'react-router-dom'
import {defaultItems} from './SideMenu'
import styled from 'styled-components'

export default function BreadcrumbPageHeader() {
  const location = useLocation()

  const flattenRoutes = defaultItems.reduce((prev: {key: string; label: string; icon?: ReactNode}[], curr) => {
    prev.push({key: curr.key, label: curr.label, icon: curr.icon})
    if (curr.children) {
      prev.push(...curr.children.map((child) => ({key: child.key, label: child.label})))
    }
    return prev
  }, [])

  const breadcrumbRoutes: any[] = useMemo(() => {
    const pathSnippets = location.pathname.split('/').filter((i) => i)
    return pathSnippets.reduce((prev: any[], curr, index) => {
      const key = `/${pathSnippets.slice(0, index + 1).join('/')}`
      const route = flattenRoutes.find((r) => r.key === key)
      if (route) {
        prev.push({icon: route.icon, title: route.label || route.key})
      }
      return prev
    }, [])
  }, [location.pathname])

  return (
    <>
      <Icon>{breadcrumbRoutes[0].icon}</Icon>
      <BreadcrumbStyled separator="">
        {breadcrumbRoutes.map((route, i) => (
          <BreadcrumbItemStyled key={i}>
            <Space>{route.title}</Space>
          </BreadcrumbItemStyled>
        ))}
      </BreadcrumbStyled>
    </>
  )
}

const Icon = styled.div`
  width: 64px;
  height: 64px;
  margin: 0 10px 0 -24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
  color: white;
  background: ${process.env.REACT_APP_THEME_COLOR};

  svg {
    width: 25px;
    height: 25px;
  }
`

const BreadcrumbStyled = styled(Breadcrumb)`
  margin-right: auto;
  ol {
    display: flex;
    flex-direction: column;
    line-height: 20px;

    li {
      font-size: 12px;
    }

    li:last-child {
      font-size: 21px;
      font-weight: 600;
      color: ${process.env.REACT_APP_THEME_COLOR};
      margin-bottom: 5px;
    }
  }
`

const BreadcrumbItemStyled = styled(Breadcrumb.Item)``

import api from './index'

export interface IImageCommon {
  id: number
  name: string
  titleImage: string
  createdAt: string
  updatedAt: string
}

export interface IPostCategory {
  productBrandId: number
  productOrderTypeId: number
  productSalesPlatformId: number
  titleImage: string
  parentId: number
}

export interface IPatchCategory {
  productBrandId?: number
  productOrderTypeId?: number
  productSalesPlatformId?: number
  titleImage?: string
  parentId?: number
}

export interface IProductCategory {
  id: number
  parentId: number
  parent: string
  children: string[]
  titleImage: string
  productBrand: IImageCommon
  productOrderType: IImageCommon
  productSalesPlatform: IImageCommon
  productSalesProduct: IImageCommon
  productSalesProductDetail: IImageCommon
  createdAt: string
  updatedAt: string
}

export async function postCategories(data: IPostCategory): Promise<{id: number}> {
  try {
    const res = await api.post(`/categories`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCategories(): Promise<{data: IProductCategory[]; total: number}> {
  try {
    const res = await api.get(`/categories`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCategory(id: number): Promise<IProductCategory> {
  try {
    const res = await api.get(`/categories/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchCategory(id: number, data: IPatchCategory): Promise<{id: number}> {
  try {
    const res = await api.patch(`/categories/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteCategory(id: number): Promise<{id: number}> {
  try {
    const res = await api.delete(`/categories/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

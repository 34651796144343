import api from './index'

interface ICommon {
  id: number
  name: string
  korean: string
  titleImage: string
  comment: string
  seq: number
  hidden: boolean
  createdAt: string
  updatedAt: string
  code?: string
}

export interface IFinalValue extends ICommon {
  changeColors: ICommon[]
}

export interface IPostFinalValue extends Omit<IFinalValue, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchFinalValue extends IPostFinalValue {}

interface IGetFinalValueParams {
  start: number
  perPage: number
  search?: string
}

export async function postFinalValue(data: IPostFinalValue): Promise<{id: number}> {
  try {
    const res = await api.post(`/finalValues`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFinalValues(
  params: IGetFinalValueParams = {start: 0, perPage: 1000}
): Promise<{data: IFinalValue[]; total: number}> {
  try {
    const res = await api.get(`/finalValues`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFinalValue(id: number): Promise<IFinalValue> {
  try {
    const res = await api.get(`/finalValues/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchFinalValue(id: number, data: IPatchFinalValue): Promise<{id: number}> {
  try {
    const res = await api.patch(`/finalValues/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteFinalValue(id: number): Promise<void> {
  try {
    const res = await api.delete(`/finalValues/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

const PATH = '/app-version'

export interface Version {
  id: number
  version: string
  updatedAt: string
}

export interface IPatchVersion extends Partial<Version> {}

export async function getVersion(): Promise<{data: Version[]}> {
  try {
    const res = await api.get(`${PATH}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchVersion(data: IPatchVersion): Promise<{id: string}> {
  try {
    const res = await api.patch(`${PATH}/1`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {forwardRef, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {getProduct, IProduct} from '../../../../../api/products'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

function Show({record}: {record: IProduct}) {
  const [data, setData] = useState<IProduct | null>(null)

  async function handleGetProduct() {
    const data = await getProduct(record.id)
    setData(data)
  }

  useEffect(() => {
    handleGetProduct()
  }, [])

  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{data?.materialDescription}</Descriptions.Item>
        <Descriptions.Item label="품목번호">{data?.material}</Descriptions.Item>
        <Descriptions.Item label="바코드">{data?.barcode}</Descriptions.Item>
        <Descriptions.Item label="수량">{data?.quantity}</Descriptions.Item>
        <Descriptions.Item label="판매 단위">{data?.quantityUnit}</Descriptions.Item>
        <Descriptions.Item label="안전재고 수량">{data?.safetyQuantity}</Descriptions.Item>
        <Descriptions.Item label="판매단위별 수">{data?.salesNumber}</Descriptions.Item>
        <Descriptions.Item label="구매단위별 수">{data?.purchaseNumber}</Descriptions.Item>
        <Descriptions.Item label="배송 방법">{data?.deliveryType}</Descriptions.Item>
        <Descriptions.Item label="최종 구매 통화">{data?.currencyType}</Descriptions.Item>
        <Descriptions.Item label="채널">{data?.channel?.name}</Descriptions.Item>
        <Descriptions.Item label="디테일 유형 1">{data?.detailType1?.name}</Descriptions.Item>
        <Descriptions.Item label="디테일 유형 2">{data?.detailType2?.name}</Descriptions.Item>
        <Descriptions.Item label="카테고리">{data?.family?.name}</Descriptions.Item>
        <Descriptions.Item label="카테고리 디테일">{data?.familyDetail?.name}</Descriptions.Item>
        <Descriptions.Item label="품목그룹">{data?.itemGroup?.name}</Descriptions.Item>
        <Descriptions.Item label="고객 상태">{data?.patientCategory?.name}</Descriptions.Item>
        <Descriptions.Item label="라인">{data?.line?.name}</Descriptions.Item>
        <Descriptions.Item label="판매 플랫폼">{data?.salesPlatform?.name}</Descriptions.Item>
        <Descriptions.Item label="세그먼트">{data?.segment?.name}</Descriptions.Item>
        <Descriptions.Item label="브랜드">{data?.brand?.name}</Descriptions.Item>
        <Descriptions.Item label="제품군">{data?.orderType?.name}</Descriptions.Item>
        <Descriptions.Item label="마케팅">{data?.marketingPlatform?.name}</Descriptions.Item>
        <Descriptions.Item label="판매 제품 상세">{data?.salesProductDetail?.name}</Descriptions.Item>
        <Descriptions.Item label="판매여부">{data?.onSale ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
        <Descriptions.Item label="상태">{data?.status}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

const ProductModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '상품 ', actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (!form) return
    form.submit()
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1000}
      onAction={handleAction}
      render={(type, record) => {
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default ProductModal

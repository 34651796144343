import {Input, InputNumber, Select, Space, Switch, Typography} from 'antd'
import styled from 'styled-components'

function getColumnItem({
  keys = [''],
  required = false,
  type = 'string',
  enumVal = [''],
  setExcelData,
  isError = false,
  usingKeyValue = '',
  dataLength = 0,
  isUseKeyValue = false,
  key = '',
  disableSelect = false,
  ...props
}) {
  function setState(item, rowIndex, type) {
    if (!props.selectedKeys[props.index]) {
      props.setSelectedKeys((prev) => {
        prev[props.index] = `직접 입력 (${props.index}열)`
        return [...prev]
      })
    }
    setExcelData((prev) => {
      prev[rowIndex][props.selectedKeys[props.index]] = item
      return prev
    })
  }

  return {
    dataIndex: props.selectedKeys[props.index],
    title: (
      <ColumnWrapper>
        <Typography>
          <Space>
            <Typography.Text>{props.title}</Typography.Text>
            {!!required && <Typography.Text type="danger">*</Typography.Text>}
          </Space>
        </Typography>
        {!disableSelect && (
          <StyledSelect
            disabled={
              (props?.excelData && isUseKeyValue && props?.excelData[0] && !props?.excelData[0][key]) || !keys[0]
            }
            value={props.selectedKeys[props.index]}
            onChange={(key: any) => {
              props.setSelectedKeys((prev) => {
                prev[props.index] = key
                return [...prev]
              })
              if (usingKeyValue)
                setExcelData((prev) => {
                  for (let i = 0; i < dataLength; i++) {
                    // prev[i][usingKeyValue] = `ASP PH${key.replace(/[^0-9]/g, '')}`
                    prev[i][usingKeyValue] = key.toUpperCase()
                  }
                  return prev
                })
            }}
            allowClear={!required}
          >
            {isUseKeyValue ? (
              <Select.Option value={key}>{key}</Select.Option>
            ) : (
              keys.map((key) => (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              ))
            )}
          </StyledSelect>
        )}
      </ColumnWrapper>
    ),
    render: (value, record, rowIndex) => {
      if (type === 'boolean') {
        if (['X', 'O'].includes(value)) return <Switch checked={value === 'false' ? false : true} disabled />
        else return <></>
      } else if (type === 'number') {
        return (
          <StyledInputNumber
            disabled={!!usingKeyValue}
            defaultValue={typeof value !== 'object' ? value : ''}
            onChange={(item) => setState(item, rowIndex, type)}
          />
        )
      } else if (enumVal[0]) {
        return (
          <StyledSelect
            defaultValue={typeof value !== 'object' ? value : ''}
            onChange={(item) => setState(item, rowIndex, type)}
          >
            {enumVal.map((item) => (
              <Select.Option value={item}>{item}</Select.Option>
            ))}
          </StyledSelect>
        )
      } else if (isError) {
        return <>{typeof value !== 'object' ? value : ''}</>
      } else if (disableSelect) return <>-</>
      else {
        return (
          <Input
            defaultValue={typeof value !== 'object' ? value : ''}
            onChange={(e) => setState(e.target.value, rowIndex, type)}
            disabled={!!usingKeyValue}
          />
        )
      }
    }
  }
}

export default getColumnItem

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledSelect = styled(Select)`
  width: 150px;
`

const StyledInputNumber = styled(InputNumber)`
  width: 150px;
`

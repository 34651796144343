import api from './index'

export interface IProgramButtons {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProgramButtons extends Omit<IProgramButtons, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProgramButtons extends IPostProgramButtons {}

interface IGetProgramButtonsParams {
  start: number
  perPage: number
  search?: string
}

export async function postProgramButtons(data: IPostProgramButtons): Promise<{id: number}> {
  try {
    const res = await api.post(`/productProgramButton`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProgramButtons(
  params: IGetProgramButtonsParams = {start: 0, perPage: 1000}
): Promise<{data: IProgramButtons[]; total: number}> {
  try {
    const res = await api.get(`/productProgramButton`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProgramButtonsItem(id: number): Promise<IProgramButtons> {
  try {
    const res = await api.get(`/productProgramButton/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProgramButtons(id: number, data: IPatchProgramButtons): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productProgramButton/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProgramButtons(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productProgramButton/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IHandle {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostHandles extends Omit<IHandle, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchHandles extends IPostHandles {}

interface IGetHandlesParams {
  start: number
  perPage: number
  search?: string
}

export async function postHandle(data: IPostHandles): Promise<{id: number}> {
  try {
    const res = await api.post(`/handles`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getHandles(
  params: IGetHandlesParams = {start: 0, perPage: 1000}
): Promise<{data: IHandle[]; total: number}> {
  try {
    const res = await api.get(`/handles`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getHandle(id: number): Promise<IHandle> {
  try {
    const res = await api.get(`/handles/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchHandle(id: number, data: IPatchHandles): Promise<{id: number}> {
  try {
    const res = await api.patch(`/handles/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteHandle(id: number): Promise<void> {
  try {
    const res = await api.delete(`/handles/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

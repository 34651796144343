export default {
  name: /^[0-9a-zA-Z!_]{4,20}$/,
  password: /^[\da-zA-Z!@#$%^&*()?+-_~=/]{6,40}$/,
  userPassword: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
  phone: /^01([016789]?)([0-9]{3,4})([0-9]{4})$/,
  tel: /^[0-9]{8,11}$/,
  date: /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/,
  telWithDash: (str: string) => str && str.replace(/(^02.{0}|^01.|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  priceWithComma: /\B(?=(\d{3})+(?!\d))/
}

import api from './index'

export interface IMarketingPlatform {
  id: number
  name: string
  titleImage: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostMarketingPlatforms extends Omit<IMarketingPlatform, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchMarketingPlatforms extends IPostMarketingPlatforms {}

interface IGetMarketingPlatformsParams {
  start: number
  perPage: number
  search?: string
}

export async function postMarketingPlatform(data: IPostMarketingPlatforms): Promise<{id: number}> {
  try {
    const res = await api.post(`/marketingPlatforms`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getMarketingPlatforms(
  params: IGetMarketingPlatformsParams = {start: 0, perPage: 1000}
): Promise<{data: IMarketingPlatform[]; total: number}> {
  try {
    const res = await api.get(`/marketingPlatforms`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getMarketingPlatform(id: number): Promise<IMarketingPlatform> {
  try {
    const res = await api.get(`/marketingPlatforms/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchMarketingPlatform(id: number, data: IPatchMarketingPlatforms): Promise<{id: number}> {
  try {
    const res = await api.patch(`/marketingPlatforms/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteMarketingPlatform(id: number): Promise<void> {
  try {
    const res = await api.delete(`/marketingPlatforms/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

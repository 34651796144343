import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, List, Modal, Select, Tag} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {getAudiograms, IAudiogram} from '../../../../../api/audiograms'
import {deleteIteOption, IteOption, patchIteOption, postIteOption} from '../../../../../api/iteOption'
import {getShellTypes} from '../../../../../api/shellTypes'
import {getShellColors} from '../../../../../api/shellColors'
import {getPlates} from '../../../../../api/plates'
import {getOutputs} from '../../../../../api/outputs'
import {getBents} from '../../../../../api/bents'
import {getBentStyles} from '../../../../../api/bentStyles'
import {getHandles} from '../../../../../api/handles'
import {getEarwaxOptions} from '../../../../../api/earwaxOptions'
import {getCoatings} from '../../../../../api/coatings'
import {getCanalsLengths} from '../../../../../api/canalsLength'
import {getShellSizes} from '../../../../../api/shellSizes'
import {getVolumeControllers} from '../../../../../api/volumeControllers'
import {getWireless} from '../../../../../api/wireless'
import {getProgramButtons} from '../../../../../api/programButtons'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IAudiogram>
  onAction: (type: ActionType, record: Partial<IAudiogram>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IteOption}) {
  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="메모">{record.memo}</Descriptions.Item>
        <Descriptions.Item label="쉘 타입">
          <List dataSource={record.productShellTypes} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="쉘 컬러">
          <List dataSource={record.productShellColors} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="플레이트">
          <List dataSource={record.productPlates} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="출력">
          <List dataSource={record.productOutputs} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="벤트">
          <List dataSource={record.productBents} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="벤트 스타일">
          <List dataSource={record.productBentStyles} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="손잡이">
          <List dataSource={record.productHandles} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="귀지 방지 옵션">
          <List
            dataSource={record.productEarwaxOptions}
            renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="코팅">
          <List dataSource={record.productCoatings} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="카날 길이">
          <List
            dataSource={record.productCanalLengths}
            renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="쉘 사이즈">
          <List dataSource={record.productShellSizes} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="무선">
          <List dataSource={record.productWireless} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
        </Descriptions.Item>
        <Descriptions.Item label="볼륨 조절기">
          <List
            dataSource={record.productVolumeControllers}
            renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="프로그램 버튼">
          <List
            dataSource={record.productProgramButtons}
            renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="청력도">
          <div style={{width: 100}}>
            {record.productAudiograms.length ? record.productAudiograms.map(({name}, index) => <Tag>{name}</Tag>) : '-'}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  const [options, setOptions] = useState<any>({})

  async function getter(api, key) {
    const {data} = await api()
    setOptions((prev) => ({...prev, [key]: data}))
  }

  useEffect(() => {
    if (form && record) {
      getter(getShellTypes, 'shellTypes')
      getter(getShellColors, 'shellColors')
      getter(getShellSizes, 'shellSizes')
      getter(getPlates, 'plates')
      getter(getOutputs, 'outputs')
      getter(getBents, 'bents')
      getter(getBentStyles, 'bentStyles')
      getter(getHandles, 'handles')
      getter(getEarwaxOptions, 'earwaxOptions')
      getter(getCoatings, 'coatings')
      getter(getCanalsLengths, 'canalLengths')
      getter(getShellSizes, 'shellSizes')
      getter(getWireless, 'wireless')
      getter(getVolumeControllers, 'volumeControllers')
      getter(getProgramButtons, 'programButtons')
      getter(getAudiograms, 'audiograms')
      form.setFieldsValue({
        ...record,
        id: record.id,
        shellTypeIds: record?.productShellTypes?.map(({id}) => id),
        shellColorIds: record?.productShellColors?.map(({id}) => id),
        shellSizeIds: record?.productShellSizes?.map(({id}) => id),
        plateIds: record?.productPlates?.map(({id}) => id),
        outputIds: record?.productOutputs?.map(({id}) => id),
        bentIds: record?.productBents?.map(({id}) => id),
        bentStyleIds: record?.productBentStyles?.map(({id}) => id),
        handleIds: record?.productHandles?.map(({id}) => id),
        earwaxOptionIds: record?.productEarwaxOptions?.map(({id}) => id),
        coatingIds: record?.productCoatings?.map(({id}) => id),
        canalLengthIds: record?.productCanalLengths?.map(({id}) => id),
        wirelessIds: record?.productWireless?.map(({id}) => id),
        volumeControllerIds: record?.productVolumeControllers?.map(({id}) => id),
        programButtonIds: record?.productProgramButtons?.map(({id}) => id),
        audiogramIds: record?.productAudiograms?.map(({id}) => id)
      })
    }
  }, [form, record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item label="이름" name="name" rules={[{required: true, message: '이름을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="메모" name="memo">
        <Input />
      </Form.Item>

      <Form.Item label="쉘 타입" name="shellTypeIds">
        <Select mode="multiple">
          {options?.shellTypes
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="쉘 컬러" name="shellColorIds">
        <Select mode="multiple">
          {options?.shellColors
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="플레이트" name="plateIds">
        <Select mode="multiple">
          {options?.plates
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="출력" name="outputIds">
        <Select mode="multiple">
          {options?.outputs
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="벤트" name="bentIds">
        <Select mode="multiple">
          {options?.bents
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="벤트 스타일" name="bentStyleIds">
        <Select mode="multiple">
          {options?.bentStyles
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="손잡이" name="handleIds">
        <Select mode="multiple">
          {options?.handles
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="귀지 방지 옵션" name="earwaxOptionIds">
        <Select mode="multiple">
          {options?.earwaxOptions
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="코팅" name="coatingIds">
        <Select mode="multiple">
          {options?.coatings
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="카날 길이" name="canalLengthIds">
        <Select mode="multiple">
          {options?.canalLengths
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="쉘 사이즈" name="shellSizeIds">
        <Select mode="multiple">
          {options?.shellSizes
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="무선" name="wirelessIds">
        <Select mode="multiple">
          {options?.wireless
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="볼륨조절기" name="volumeControllerIds">
        <Select mode="multiple">
          {options?.wireless
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="프로그램 버튼" name="programButtonIds">
        <Select mode="multiple">
          {options?.programButtons
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="청력도" name="audiogramIds">
        <Select mode="multiple">
          {options?.audiograms
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

const IteOptionModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = 'ITE 옵션 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: any) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values
        if (id) {
          await patchIteOption(id, {...rest})
        } else {
          await postIteOption({...rest})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response && e.response.data.message === 'already_in_use') {
          Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteIteOption(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit type={type} form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default IteOptionModal

import React from 'react'
import styled from 'styled-components'
import {Button} from 'antd'
import {LeftOutlined, RightOutlined} from '@ant-design/icons'

function Trigger({collapsed}: {collapsed: boolean}) {
  return (
    <TriggerWrapper>
      {collapsed ? (
        <TriggerButton type="primary" shape="circle">
          <RightOutlined />
        </TriggerButton>
      ) : (
        <TriggerButton type="primary" shape="circle">
          <LeftOutlined />
        </TriggerButton>
      )}
    </TriggerWrapper>
  )
}

export default Trigger

const TriggerButton = styled(Button)`
  box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
`

const TriggerWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`

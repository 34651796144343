import api from './index'

export interface IDataGroup {
  id: number
  name: string
  image: string
  parentId: string
  children?: string
  createdAt: string
  updatedAt: string
  sortOrder: number
  data: {
    id: number
    title: string
    content: string
    files: string
    isFixation: string
    sortOrder: number
    createdAt: string
    updatedAt: string
    brands: {id: number; name: string}[]
  }[]
}

export interface IPostDataGroups extends Omit<IDataGroup, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchDataGroups extends IPostDataGroups {
  id?: number
}

export async function postDataGroups(data: IPostDataGroups): Promise<{id: number}> {
  try {
    const res = await api.post(`/dataGroups`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDataGroups(): Promise<{data: IDataGroup[]}> {
  try {
    const res = await api.get(`/dataGroups`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDataGroup(id: number): Promise<IDataGroup> {
  try {
    const res = await api.get(`/dataGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchDataGroup(id: number, data: IPatchDataGroups): Promise<{id: number}> {
  try {
    const res = await api.patch(`/dataGroups/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteDataGroup(id: number): Promise<void> {
  try {
    const res = await api.delete(`/dataGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {useEffect, useState} from 'react'
import {useRecoilState} from 'recoil'
import {userState} from '../states/user.state'
import {ResultStatusType} from 'antd/lib/result'
import {Spin} from 'antd'
import {getAuth} from '../api/auth'
import ErrorLayout from '../layouts/ErrorLayout'

export default function withInitialize(WrappedComponent: React.ComponentType): React.ComponentType {
  return function () {
    const [, setUser] = useRecoilState(userState)
    const [loading, setLoading] = useState(true)
    const [errorStatus, setErrorStatus] = useState<ResultStatusType | undefined>(undefined)

    useEffect(() => {
      ;(async function () {
        setUser(true)
        try {
          const res = await getAuth()
          setUser(res)
        } catch (e: any) {
          if (e.response && e.response.status === 401) {
            setUser(false)
          } else {
            setErrorStatus(e.response.status || '500')
          }
        } finally {
          setLoading(false)
        }
      })()
    }, [])

    if (errorStatus) {
      return <ErrorLayout status={errorStatus} />
    }

    return (
      <Spin spinning={loading} tip="Loading..." style={{minHeight: '100vh', display: 'flex', alignItems: 'center'}}>
        <WrappedComponent />
      </Spin>
    )
  }
}

import api from './index'

export interface ILog {
  id: number
  name: string
  file: string
  content: string
  createdAt: string
}

interface IGetLogParams {
  start: number
  perPage: number
  search?: string
}

export async function getLogs(
  params: IGetLogParams = {start: 0, perPage: 1000}
): Promise<{data: ILog[]; total: number}> {
  try {
    const res = await api.get(`/sftp-log`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getLog(id: number): Promise<ILog> {
  try {
    const res = await api.get(`/sftp-log/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

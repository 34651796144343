import regex from './regex'

export default {
  name: [
    {
      pattern: regex.name,
      message: '영문 혹수 숫자 그리고 ! 또는 _ 를 사용해 4자 이상 20자를 입력해주세요'
    }
  ],
  password: [
    {
      pattern: regex.password,
      message: '영문 혹은 숫자와 특수문자를 사용해 6자 이상 40자 이하를 입력해주세요'
    }
  ],
  userPassword: [
    {
      pattern: regex.userPassword,
      message: '영문, 숫자, 특수문자를 사용해 8자 이상을 입력해주세요'
    }
  ],
  tel: [
    {
      pattern: regex.tel,
      message: '정확한 전화번호를 입력해주세요'
    }
  ],
  phone: [
    {
      pattern: regex.phone,
      message: '정확한 휴대폰 번호를 입력해주세요'
    }
  ]
}

import api from './index'

export interface IPatientCategory {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostPatientCategories extends Omit<IPatientCategory, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchPatientCategories extends IPostPatientCategories {}

interface IGetPatientCategoriesParams {
  start: number
  perPage: number
  search?: string
}

export async function postPatientCategory(data: IPostPatientCategories): Promise<{id: number}> {
  try {
    const res = await api.post(`/patientCategories`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPatientCategories(
  params: IGetPatientCategoriesParams = {start: 0, perPage: 1000}
): Promise<{data: IPatientCategory[]; total: number}> {
  try {
    const res = await api.get(`/patientCategories`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPatientCategory(id: number): Promise<IPatientCategory> {
  try {
    const res = await api.get(`/patientCategories/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchPatientCategory(id: number, data: IPatchPatientCategories): Promise<{id: number}> {
  try {
    const res = await api.patch(`/patientCategories/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deletePatientCategory(id: number): Promise<void> {
  try {
    const res = await api.delete(`/patientCategories/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

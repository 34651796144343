import api from './index'

export interface ICoating {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostCoatings extends Omit<ICoating, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchCoatings extends IPostCoatings {}

interface IGetCoatingsParams {
  start: number
  perPage: number
  search?: string
}

export async function postCoating(data: IPostCoatings): Promise<{id: number}> {
  try {
    const res = await api.post(`/coatings`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCoatings(
  params: IGetCoatingsParams = {start: 0, perPage: 1000}
): Promise<{data: ICoating[]; total: number}> {
  try {
    const res = await api.get(`/coatings`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCoating(id: number): Promise<ICoating> {
  try {
    const res = await api.get(`/coatings/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchCoating(id: number, data: IPatchCoatings): Promise<{id: number}> {
  try {
    const res = await api.patch(`/coatings/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteCoating(id: number): Promise<void> {
  try {
    const res = await api.delete(`/coatings/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

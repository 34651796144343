import React, {useContext, useEffect, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import dayjs from 'dayjs'
import PageContext from '../../../../contexts/PageContext'
import {Input, Modal} from 'antd'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import ProductModal from './components/ProductModal'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {ProductDispatchContext} from '../../../../contexts/ProductModalContext'
import {getProductBrands} from '../../../../api/productBrands'
import {getProductOrderTypes} from '../../../../api/productOrderTypes'
import {getMarketingPlatforms} from '../../../../api/marketingPlatforms'
import ImageView from '../../../../components/ImageView'
import {getProductOption, getProductOptionCharges} from '../../../../api/productOption'

function ProductOptionCharges() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const extDataDispatch = useContext(ProductDispatchContext)

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {
      dataIndex: 'titleImage',
      title: '대표 이미지',
      render: (titleImage) => <ImageView preview={false} src={titleImage} height={100} />
    },
    {dataIndex: 'materialDescription', title: '이름'},
    {dataIndex: 'material', title: '품목번호'},
    {dataIndex: 'quantity', title: '수량', render: (quantity, record) => quantity * record.quantityUnit},
    {dataIndex: 'onSale', title: '판매여부', render: (onSale) => (onSale ? <CheckOutlined /> : <CloseOutlined />)},
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function handleSearchId(id: string) {
    try {
      const data = await getProductOption(+id)
      setModalActions(['show', 'delete', 'edit'])
      showTableModal(null, data)
    } catch (e: any) {
      Modal.error({title: '검색 오류', content: '해당 ID의 상품이 없습니다.'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    async function getList(getApi, key) {
      const {data} = await getApi()
      extDataDispatch({type: 'SET_VALUE', key, value: data})
    }
    getList(getProductBrands, 'brands')
    getList(getProductOrderTypes, 'orderTypes')
    getList(getMarketingPlatforms, 'marketingPlatforms')
    return () => extDataDispatch({type: 'INIT_VALUES'})
  }, [])

  return (
    <>
      <BasicTable
        rowKey="id"
        showSearch
        searchPlaceHolder="이름으로 검색"
        columns={columns}
        topButtons={[
          <Input.Search placeholder={'ID로 검색'} style={{width: 300}} enterButton onSearch={handleSearchId} />
        ]}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <ProductModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(ProductOptionCharges, getProductOptionCharges)

import api from './index'

export interface IProductCharger {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductChargers extends Omit<IProductCharger, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductChargers extends IPostProductChargers {}

interface IGetProductChargersParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductCharger(data: IPostProductChargers): Promise<{id: number}> {
  try {
    const res = await api.post(`/productChargers`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductChargers(
  params: IGetProductChargersParams = {start: 0, perPage: 1000}
): Promise<{data: IProductCharger[]; total: number}> {
  try {
    const res = await api.get(`/productChargers`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductCharger(id: number): Promise<IProductCharger> {
  try {
    const res = await api.get(`/productChargers/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductCharger(id: number, data: IPatchProductChargers): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productChargers/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductCharger(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productChargers/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

interface IPostFileUpload {
  type: 'image' | 'file'
  kind: string
  path: string
  name: string
}

interface IGetFileUploadParams {
  type: 'image' | 'file'
  name?: string
  mimeType: string
}

interface IGetFileCheckParams {
  name?: string
  path?: string
}

export async function postFileUpload(data: IPostFileUpload) {
  try {
    const res = await api.post(`/files/upload`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFileUpload(params: IGetFileUploadParams) {
  try {
    const res = await api.get(`/files/upload`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFileCheck(params: IGetFileCheckParams) {
  try {
    const res = await api.get(`/files/check`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

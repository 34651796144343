import {RouteObject} from 'react-router'
import Products from '../pages/console/product/products'
import Families from '../pages/console/product/families'
import FamilyDetails from '../pages/console/product/familyDetails'
import Lines from '../pages/console/product/lines'
import MarketSegments from '../pages/console/product/marketSegments'
import PatientCategories from '../pages/console/product/patientCategories'
import ProductChannels from '../pages/console/product/productChannels'
import ProductChargers from '../pages/console/product/productChargers'
import ProductColors from '../pages/console/product/productColors'
import ProductDetailType1 from '../pages/console/product/productDetailType1'
import ProductDetailType2 from '../pages/console/product/productDetailType2'
import ProductItemGroups from '../pages/console/product/productItemGroups'
import ProductReceiverDirection from '../pages/console/product/productReceiverDirection'
import ProductReceiverLength from '../pages/console/product/productReceiverLength'
import ProductReceiverPower from '../pages/console/product/productReceiverPower'
import SalesPlatforms from '../pages/console/product/salesPlatforms'
import Segments from '../pages/console/product/segments'
import ProductBrands from '../pages/console/product/productBrands'
import MarketingPlatforms from '../pages/console/product/marketingPlatforms'
import ProductOrderTypes from '../pages/console/product/productOrderTypes'
import SalesProducts from '../pages/console/product/salesProducts'
import Audiograms from '../pages/console/iteOption/audiograms'
import FinalValues from '../pages/console/product/finalValues'
import Categories from '../pages/console/product/categories'
import SalesProductDetail from '../pages/console/product/salesProductDetail'
import ProductOptionReceivers from '../pages/console/product/productOptionReceivers'
import ProductOptionCharges from '../pages/console/product/productOptionCharges'

export const productRoute: RouteObject[] = [
  {
    path: '/console/product/products',
    element: <Products />
  },
  {
    path: '/console/product/categories',
    element: <Categories />
  },
  {
    path: '/console/product/productBrands',
    element: <ProductBrands />
  },
  {
    path: '/console/product/marketingPlatforms',
    element: <MarketingPlatforms />
  },
  {
    path: '/console/product/productOrderTypes',
    element: <ProductOrderTypes />
  },
  {
    path: '/console/product/salesProducts',
    element: <SalesProducts />
  },
  {
    path: '/console/product/salesProductDetail',
    element: <SalesProductDetail />
  },
  {
    path: '/console/product/productChannels',
    element: <ProductChannels />
  },
  {
    path: '/console/product/productColors',
    element: <ProductColors />
  },
  {
    path: '/console/product/productDetailType1',
    element: <ProductDetailType1 />
  },
  {
    path: '/console/product/productDetailType2',
    element: <ProductDetailType2 />
  },
  {
    path: '/console/product/families',
    element: <Families />
  },
  {
    path: '/console/product/familyDetails',
    element: <FamilyDetails />
  },
  {
    path: '/console/product/productItemOptions',
    element: <ProductItemGroups />
  },
  {
    path: '/console/product/marketSegments',
    element: <MarketSegments />
  },
  {
    path: '/console/product/patientCategories',
    element: <PatientCategories />
  },
  {
    path: '/console/product/lines',
    element: <Lines />
  },
  {
    path: '/console/product/salesPlatforms',
    element: <SalesPlatforms />
  },
  {
    path: '/console/product/segments',
    element: <Segments />
  },
  {
    path: '/console/product/productOptionReceivers',
    element: <ProductOptionReceivers />
  },
  {
    path: '/console/product/productOptionCharges',
    element: <ProductOptionCharges />
  },
  {
    path: '/console/product/productReceiverDirection',
    element: <ProductReceiverDirection />
  },
  {
    path: '/console/product/productReceiverLength',
    element: <ProductReceiverLength />
  },
  {
    path: '/console/product/productReceiverPower',
    element: <ProductReceiverPower />
  },
  {
    path: '/console/product/productChargers',
    element: <ProductChargers />
  },
  {
    path: '/console/product/audiograms',
    element: <Audiograms />
  },
  {
    path: '/console/product/finalValues',
    element: <FinalValues />
  }
]

import api from './index'

export interface IProductBrand {
  id: number
  titleImage: string
  name: string
  seq: number
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductBrands extends Omit<IProductBrand, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductBrands extends IPostProductBrands {}

interface IGetProductBrandsParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductBrand(data: IPostProductBrands): Promise<{id: number}> {
  try {
    const res = await api.post(`/productBrands`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductBrands(
  params: IGetProductBrandsParams = {start: 0, perPage: 1000}
): Promise<{data: IProductBrand[]; total: number}> {
  try {
    const res = await api.get(`/productBrands`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductBrand(id: number): Promise<IProductBrand> {
  try {
    const res = await api.get(`/productBrands/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductBrand(id: number, data: IPatchProductBrands): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productBrands/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductBrand(id: number): Promise<void> {
  try {
    const res = await api.patch(`/productBrands/${id}`, {hidden: true})
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {useEffect, useState} from 'react'
import {Button, Card, Form, Input, Modal, Skeleton, Space, message} from 'antd'
import {getVersion, patchVersion} from '../../../../api/version'
import withPageContext from '../../../../hocs/withPageContext'

function Version() {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  async function importVersion() {
    setLoading(true)
    const {data} = await getVersion()

    form.setFieldsValue(data[0])
    setLoading(false)
  }

  async function handleFinish(value) {
    try {
      await patchVersion(value)
      message.success('정상적으로 수정되었습니다.')
    } catch (e: any) {
      Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
    }
  }

  useEffect(() => {
    importVersion()
  }, [])

  if (loading)
    return (
      <Card>
        <Skeleton />
      </Card>
    )

  return (
    <Card>
      <Form form={form} onFinish={handleFinish} validateTrigger={['onSubmit', 'onChange']} layout="vertical">
        <Form.Item name="version" label="버전">
          <Input />
        </Form.Item>
        <section style={{gridColumnStart: 1, gridColumnEnd: 4, display: 'flex'}}>
          <Space style={{marginLeft: 'auto'}}>
            <Button htmlType="submit">적용</Button>
          </Space>
        </section>
      </Form>
    </Card>
  )
}

export default withPageContext(Version)

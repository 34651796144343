import {ReactNode} from 'react'
import api from './index'
import {IImageCommon} from './productCategories'

export interface IVendor {
  id: number
  name: string
  zipcode: string
  vendorGroupId?: number
  priceGroupId: number
  code: string
  largeCategory: string
  mediumCategory: string
  smallCategory: string
  manager: string
  enabled: boolean
  memo: string
  accountId: string
  address: string
  tel: string
  phone: string
  group: {id: number; name: string}
  priceGroup: {
    id: number
    name: string
    createdAt: string
    updatedAt: string
  }
  status: 'registered' | 'confirmed'
  createdAt: string
  updatedAt: string
  error?: ReactNode
  brands: IImageCommon[]
  mainCode?: {id: number}
}

export interface IPostVendors
  extends Omit<IVendor, 'id' | 'memo' | 'accountId' | 'group' | 'priceGroup' | 'status' | 'createdAt' | 'updatedAt'> {
  brands: any
}

export interface IPostVendorCoupon {
  id: number
  couponId: number
}

export interface IPatchVendors extends IPostVendors {
  id?: number
}

interface IGetVendorsParams {
  start: number
  perPage: number
  search?: string
  groupId?: string
}

export async function postVendor(data: IPostVendors): Promise<{id: number}> {
  try {
    const res = await api.post(`/vendors`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postVendorCoupon({id, couponId}: IPostVendorCoupon): Promise<{vendorCouponId: number}> {
  try {
    const res = await api.post(`/vendors/${id}/coupons/${couponId}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getVendors(
  params: IGetVendorsParams = {start: 0, perPage: 1000}
): Promise<{data: IVendor[]; total: number}> {
  try {
    const res = await api.get(`/vendors`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getVendor(id: number): Promise<IVendor> {
  try {
    const res = await api.get(`/vendors/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchVendor(id: number, data: IPatchVendors): Promise<{id: number}> {
  try {
    const res = await api.patch(`/vendors/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchVendorPassword(id: number, data: {password: string}): Promise<{id: number}> {
  try {
    const res = await api.patch(`/vendors/${id}/password`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteVendor(id: number): Promise<void> {
  try {
    const res = await api.delete(`/vendors/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IAdministrator {
  id: number
  name: string
  password: string
  accountId: string
  authorization: 'master' | 'manager'
  memo: string
  createdAt: string
  updatedAt: string
}

export interface IPostAdministrators extends Omit<IAdministrator, 'id' | 'createdAt' | 'updatedAt' | 'memo'> {}

export interface IPatchAdministrators
  extends Omit<IPostAdministrators, 'id' | 'password' | 'createdAt' | 'updateAt' | 'memo'> {
  password?: string
}

interface IGetAdministratorsParams {
  start: number
  perPage: number
  search?: string
  groupId?: number
}

export async function postAdministrator(data: IPostAdministrators): Promise<{id: number}> {
  try {
    const res = await api.post(`/administrators`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getAdministrators(
  params: IGetAdministratorsParams = {start: 0, perPage: 1000}
): Promise<{data: IAdministrator[]; total: number}> {
  try {
    const res = await api.get(`/administrators`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getAdministrator(id: number): Promise<IAdministrator> {
  try {
    const res = await api.get(`/administrators/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchAdministrator(id: number, data: IPatchAdministrators): Promise<{id: number}> {
  try {
    const res = await api.patch(`/administrators/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteAdministrator(id: number): Promise<void> {
  try {
    const res = await api.delete(`/administrators/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {useMemo} from 'react'
import {uploadImage} from '../../libs/uploadImage'
import Editor from './index'

function uploadAdapter(loader: any) {
  return {
    upload: () => {
      return new Promise((resolve) => {
        loader.file.then(async (file: any) => {
          const imagePath = await uploadImage(file)
          const splitedPath = imagePath ? imagePath.split('/') : []
          //splitedPath.length && splitedPath[3] === 'images' && splitedPath.splice(3, 0, 'undefined')
          resolve({default: splitedPath.join('/')})
        })
      })
    }
  }
}

function uploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader)
  }
}

const defaultConfig = {
  extraPlugins: [uploadPlugin],
  placeholder: '내용을 입력하세요'
}

function ImageEditor({config: propsConfig, ...rest}: IEditorProps) {
  const config = useMemo(() => {
    if (!propsConfig) return defaultConfig
    return {...defaultConfig, ...propsConfig}
  }, [propsConfig])

  return <Editor {...rest} config={config} />
}

export default ImageEditor

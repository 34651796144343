import api from './index'

export interface IAccProduct {
  id: number
  titleImage: string
  materialDescription: string
  material: string
  barcode: string
  quantity: number
  quantityUnit: number
  salesNumber: number
  purchaseNumber: number
  deliveryType: string
  currencyType: string
  wireless: boolean
  volumeController: boolean
  programButton: boolean
  earImpressionFile: string
  addFile: string
  memo: string
  onSale: boolean
  createdAt: string
  updatedAt: string
}

export interface ISalesProduct {
  id: number
  name: string
  korean: string
  titleImage: string
  hidden: boolean
  products: IAccProduct[]
  createdAt: string
  updatedAt: string
}

export interface IPostSalesProducts extends Omit<ISalesProduct, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchSalesProducts extends IPostSalesProducts {}

interface IGetSalesProductsParams {
  start: number
  perPage: number
  search?: string
}

export async function postSalesProducts(data: IPostSalesProducts): Promise<{id: number}> {
  try {
    const res = await api.post(`/salesProducts`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSalesProducts(
  params: IGetSalesProductsParams = {start: 0, perPage: 1000}
): Promise<{data: ISalesProduct[]; total: number}> {
  try {
    const res = await api.get(`/salesProducts`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSalesProduct(id: number): Promise<ISalesProduct> {
  try {
    const res = await api.get(`/salesProducts/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchSalesProduct(id: number, data: IPatchSalesProducts): Promise<{id: number}> {
  try {
    const res = await api.patch(`/salesProducts/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteSalesProduct(id: number): Promise<void> {
  try {
    const res = await api.delete(`/salesProducts/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

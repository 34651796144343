import api from './index'

export interface IPriceGroup {
  id: number
  name: string
  createdAt: string
  updatedAt: string
}

export interface IPostPriceGroup {
  name: string
}

export interface IPatchPriceGroup extends IPostPriceGroup {}

interface IGetPriceGroupsParams {
  start: number
  perPage: number
  search?: string
}

export async function postPriceGroup(data: IPostPriceGroup): Promise<{id: number}> {
  try {
    const res = await api.post(`/priceGroups`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPriceGroups(
  params: IGetPriceGroupsParams = {start: 0, perPage: 1000}
): Promise<{data: IPriceGroup[]; total: number}> {
  try {
    const res = await api.get(`/priceGroups`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPriceGroup(id: number): Promise<IPriceGroup> {
  try {
    const res = await api.get(`/priceGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchPriceGroup(id: number, data: IPatchPriceGroup): Promise<{id: number}> {
  try {
    const res = await api.patch(`/priceGroups/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deletePriceGroup(id: number): Promise<void> {
  try {
    const res = await api.delete(`/priceGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

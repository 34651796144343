import {RouteObject} from 'react-router'
import Banners from '../pages/console/etc/banners'
import Coupons from '../pages/console/etc/coupons'
import DeliveryTimes from '../pages/console/etc/deliveryTimes'
import Notices from '../pages/console/etc/notices'
import Orders from '../pages/console/etc/orders'
import SafetyQuantities from '../pages/console/etc/safetyQuantities'
import SftpLog from '../pages/console/etc/sftpLog'
import CouponIssue from '../pages/console/etc/couponIssue'
import DataItem from '../pages/console/etc/data/{id}'
import Data from '../pages/console/etc/data'
import Version from '../pages/console/etc/version'
import SapLog from '../pages/console/etc/sapLog'

export const etcRoute: RouteObject[] = [
  {
    path: '/console/etc/orders',
    element: <Orders />
  },
  {
    path: '/console/etc/notices',
    element: <Notices />
  },
  {
    path: '/console/etc/data',
    element: <Data />
  },
  {
    path: '/console/etc/data/:id',
    element: <DataItem />
  },
  {
    path: '/console/etc/coupons',
    element: <Coupons />
  },
  {
    path: '/console/etc/couponIssue',
    element: <CouponIssue />
  },
  {
    path: '/console/etc/banners',
    element: <Banners />
  },
  {
    path: '/console/etc/deliveryTimes',
    element: <DeliveryTimes />
  },
  {
    path: '/console/etc/safetyQuantities',
    element: <SafetyQuantities />
  },
  {
    path: '/console/etc/sftpLog',
    element: <SftpLog />
  },
  {
    path: '/console/etc/version',
    element: <Version />
  },
  {
    path: '/console/etc/sapLog',
    element: <SapLog />
  }
]

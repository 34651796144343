import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, Modal, Switch} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {deleteBentStyle, IBentStyle, patchBentStyle, postBentStyle} from '../../../../../api/bentStyles'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IBentStyle>
  onAction: (type: ActionType, record: Partial<IBentStyle>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IBentStyle}) {
  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="숨기기">{record.hidden ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  useEffect(() => {
    if (form && record) {
      form.setFieldsValue({...record})
    }
  }, [form, record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item label="이름" name="name" rules={[{required: true, message: '이름을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="숨기기" name="hidden" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  )
}

const BentStyleModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '벤트 스타일 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IBentStyle) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values
        if (id) {
          await patchBentStyle(id, {...rest})
        } else {
          await postBentStyle({...rest})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response && e.response.data.message === 'already_in_use') {
          Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteBentStyle(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit type={type} form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default BentStyleModal

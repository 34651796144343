import api from './index'

export interface IShellType {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostShellTypes extends Omit<IShellType, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchShellTypes extends IPostShellTypes {}

interface IGetShellTypesParams {
  start: number
  perPage: number
  search?: string
}

export async function postShellType(data: IPostShellTypes): Promise<{id: number}> {
  try {
    const res = await api.post(`/shellTypes`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getShellTypes(
  params: IGetShellTypesParams = {start: 0, perPage: 1000}
): Promise<{data: IShellType[]; total: number}> {
  try {
    const res = await api.get(`/shellTypes`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getShellType(id: number): Promise<IShellType> {
  try {
    const res = await api.get(`/shellTypes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchShellType(id: number, data: IPatchShellTypes): Promise<{id: number}> {
  try {
    const res = await api.patch(`/shellTypes/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteShellType(id: number): Promise<void> {
  try {
    const res = await api.delete(`/shellTypes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IProductDetailType2 {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductDetailType2 extends Omit<IProductDetailType2, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductDetailType2 extends IPostProductDetailType2 {}

interface IGetProductDetailType2Params {
  start: number
  perPage: number
  search?: string
}

export async function postProductDetailType2(data: IPostProductDetailType2): Promise<{id: number}> {
  try {
    const res = await api.post(`/productDetailType2`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductDetailType2s(
  params: IGetProductDetailType2Params = {start: 0, perPage: 1000}
): Promise<{data: IProductDetailType2[]; total: number}> {
  try {
    const res = await api.get(`/productDetailType2`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductDetailType2(id: number): Promise<IProductDetailType2> {
  try {
    const res = await api.get(`/productDetailType2/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductDetailType2(id: number, data: IPatchProductDetailType2): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productDetailType2/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductDetailType2(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productDetailType2/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import axios from 'axios'

const instance = axios.create({baseURL: process.env.REACT_APP_API_URL, withCredentials: true, timeout: 20000})

if (process.env.NODE_ENV !== 'production') {
  instance.interceptors.request.use(
    (config) => {
      const {url, method, params, data} = config
      // eslint-disable-next-line no-console
      console.log(`[Request]: ${JSON.stringify({url, params, method, data}, null, 2)}`)
      return config
    },
    (error) => Promise.reject(error)
  )
  instance.interceptors.response.use((response) => {
    // eslint-disable-next-line no-console
    console.log(`[Response Data]: ${JSON.stringify(response.data, null, 2)}`)
    return response
  }, errorHandler)
} else {
  instance.interceptors.response.use((response) => response, errorHandler)
}

async function errorHandler(e: any) {
  // eslint-disable-next-line no-console
  console.log(`[Error Data]: ${JSON.stringify(e.response.data, null, 2)}`)
  try {
    if (
      (e.response.status === 401 && e.response.data.message === 'invalid_session') ||
      (e.response.status === 403 && e.response.data.message === 'forbidden')
    ) {
      window.open('/', '_self')
    }
  } catch (e) {}
  throw e
}

export default instance

import api from './index'

export interface IProductReceiverLength {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductReceiverLength extends Omit<IProductReceiverLength, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductReceiverLength extends IPostProductReceiverLength {}

interface IGetProductReceiverLengthParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductReceiverLength(data: IPostProductReceiverLength): Promise<{id: number}> {
  try {
    const res = await api.post(`/productReceiverLength`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductReceiverLengths(
  params: IGetProductReceiverLengthParams = {start: 0, perPage: 1000}
): Promise<{data: IProductReceiverLength[]; total: number}> {
  try {
    const res = await api.get(`/productReceiverLength`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductReceiverLength(id: number): Promise<IProductReceiverLength> {
  try {
    const res = await api.get(`/productReceiverLength/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductReceiverLength(id: number, data: IPatchProductReceiverLength): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productReceiverLength/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductReceiverLength(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productReceiverLength/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IFamilyDetail {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostFamilyDetails extends Omit<IFamilyDetail, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchFamilyDetails extends IPostFamilyDetails {}

interface IGetFamilyDetailsParams {
  start: number
  perPage: number
  search?: string
}

export async function postFamilyDetail(data: IPostFamilyDetails): Promise<{id: number}> {
  try {
    const res = await api.post(`/familyDetails`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFamilyDetails(
  params: IGetFamilyDetailsParams = {start: 0, perPage: 1000}
): Promise<{data: IFamilyDetail[]; total: number}> {
  try {
    const res = await api.get(`/familyDetails`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFamilyDetail(id: number): Promise<IFamilyDetail> {
  try {
    const res = await api.get(`/familyDetails/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchFamilyDetail(id: number, data: IPatchFamilyDetails): Promise<{id: number}> {
  try {
    const res = await api.patch(`/familyDetails/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteFamilyDetail(id: number): Promise<void> {
  try {
    const res = await api.delete(`/familyDetails/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, Modal, Select} from 'antd'
import {Rule} from 'antd/lib/form'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import {
  deleteAdministrator,
  IAdministrator,
  IPatchAdministrators,
  IPostAdministrators,
  patchAdministrator,
  postAdministrator
} from '../../../../../api/administrators'
import rules from '../../../../../libs/rules'
import dayjs from 'dayjs'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IAdministrator>
  onAction: (type: ActionType, record: Partial<IAdministrator>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IAdministrator}) {
  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="ID">{record.accountId}</Descriptions.Item>
        <Descriptions.Item label="권한">{record.authorization}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  useEffect(() => {
    if (form && record) form.setFieldsValue({...record})
    if (type === 'add') form.setFieldsValue({authorization: 'master'})
  }, [form, record])

  let passwordRules: Rule[]
  if (type === 'add') {
    passwordRules = [
      ...rules.password,
      {
        required: true,
        message: '패스워드를 입력해주세요'
      }
    ]
  } else {
    passwordRules = rules.password
  }
  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item
        label="이름"
        name="name"
        rules={[
          {required: true, message: '이름를 입력해주세요'},
          {type: 'string', min: 2, max: 20, message: '2 ~ 20자 이내로 입력해주세요.'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="ID" name="accountId" rules={[{required: true, message: 'ID를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="권한" name="authorization" rules={[{required: true, message: '권한을 선택해주세요'}]}>
        <Select defaultValue="master">
          <Select.Option key="master">시스템 관리자</Select.Option>
          <Select.Option key="manager">운영 담당자</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="패스워드" name="password" rules={passwordRules}>
        <Input.Password autoComplete="new-password" />
      </Form.Item>
    </Form>
  )
}

const AdministratorModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '관리자 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IAdministrator) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, name, password, accountId, authorization, ...rest} = values
        if (id) {
          const data: IPatchAdministrators = {name, accountId, authorization}
          if (password) data.password = password
          await patchAdministrator(id, {...rest, ...data})
        } else {
          const data: IPostAdministrators = {name, accountId, authorization, password}
          await postAdministrator({...rest, ...data})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response && e.response.data.message === 'already_in_use') {
          Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete') {
      await deleteAdministrator(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit type={type} form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default AdministratorModal

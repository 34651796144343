import api from './index'

export interface IAuth {
  id: number
  name: string
  nickname?: string
  profileUrl?: string
}

export async function postAuth(accountId: string, password: string, remember: boolean) {
  try {
    const {data} = await api.post(`/auth`, {accountId, password, remember})
    return data
  } catch (e) {
    throw e
  }
}

export async function getAuth(): Promise<IAuth> {
  const {data} = await api.get('/auth')
  return data
}

export async function deleteAuth(): Promise<void> {
  await api.delete('/auth')
}

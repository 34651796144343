import api from './index'

export interface IProductItemGroup {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductItemGroups extends Omit<IProductItemGroup, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductItemGroups extends IPostProductItemGroups {}

interface IGetProductItemGroupsParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductItemGroup(data: IPostProductItemGroups): Promise<{id: number}> {
  try {
    const res = await api.post(`/productItemGroups`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductItemGroups(
  params: IGetProductItemGroupsParams = {start: 0, perPage: 1000}
): Promise<{data: IProductItemGroup[]; total: number}> {
  try {
    const res = await api.get(`/productItemGroups`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductItemGroup(id: number): Promise<IProductItemGroup> {
  try {
    const res = await api.get(`/productItemGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductItemGroup(id: number, data: IPatchProductItemGroups): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productItemGroups/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductItemGroup(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productItemGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IParcel {
  id: number
  name: string
  hidden: boolean
  url: string
  createdAt: string
  updatedAt: string
}

export interface IPostParcels extends Omit<IParcel, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchParcel extends IPostParcels {
  id?: number
}

interface IGetParcelParams {
  search?: string
  start: number
  perPage: number
}

export async function postParcel(data: IPostParcels): Promise<{id: number}> {
  try {
    const res = await api.post(`/parcels`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getParcels(
  params: IGetParcelParams = {start: 0, perPage: 1000}
): Promise<{data: IParcel[]; total: number}> {
  try {
    const res = await api.get(`/parcels`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getParcel(id: number): Promise<IParcel> {
  try {
    const res = await api.get(`/parcels/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchParcel(id: number, data: IPatchParcel): Promise<{id: number}> {
  try {
    const res = await api.patch(`/parcels/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import dayjs from 'dayjs'
import PageContext from '../../../../contexts/PageContext'
import {Button, Space} from 'antd'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import ProductColorModal from './components/ProductColorModal'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {getProductColors} from '../../../../api/productColors'

function ProductColors() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {
      dataIndex: 'hex',
      title: 'HEX코드',
      render: (hex) => (
        <Space>
          <div style={{width: 20, height: 20, borderRadius: 3, backgroundColor: hex, border: '1px solid gray'}} /> {hex}
        </Space>
      )
    },
    {dataIndex: 'name', title: '이름'},
    {dataIndex: 'code', title: '코드'},
    {dataIndex: 'type', title: '타입'},
    {dataIndex: 'hidden', title: '숨기기', render: (hidden) => (hidden ? <CheckOutlined /> : <CloseOutlined />)},
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  return (
    <>
      <BasicTable
        rowKey="id"
        columns={columns}
        showSearch
        searchPlaceHolder="이름으로 검색"
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            상품 컬러 등록
          </Button>
        ]}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'delete', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <ProductColorModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(ProductColors, getProductColors)

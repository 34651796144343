import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import PageContext from '../../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import OrderModal from './components/OrderModal'
import {getLogs} from '../../../../api/sftpLog'
import dayjs from 'dayjs'
import qs from 'query-string'
import {Input, Modal, Select} from 'antd'
import {useNavigate} from 'react-router'

function SFTPLog() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const query = qs.parse(location.search) as Record<string, string>
  const navigate = useNavigate()

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {dataIndex: 'name', title: '제목'},
    {dataIndex: 'content', title: '내용'},
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD A hh:mm:ss')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  function onSelectFilter(key: string | string[], value: string | string[]) {
    const search = {...query}

    if (Array.isArray(key))
      key.forEach((keyItem, index) => {
        if (!value[index]) delete search[keyItem]
        else search[keyItem] = value[index]
      })
    else if (typeof key === 'string' && !Array.isArray(value)) {
      if (!value || value === 'null') delete search[key]
      else search[key] = value
    }

    navigate({
      pathname: location.pathname,
      search: qs.stringify(search)
    })
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  return (
    <>
      <BasicTable
        showSearch
        rowKey="id"
        topButtons={[
          <Select allowClear placeholder="검색 조건" onChange={(value) => onSelectFilter('name', value)}>
            <Select.Option value="product">상품명</Select.Option>
            <Select.Option value="vendor">업체명</Select.Option>
            <Select.Option value="price">가격</Select.Option>
            <Select.Option value="quantity">수량</Select.Option>
            <Select.Option value="orderStatus">주문상태</Select.Option>
            <Select.Option value="order">주문</Select.Option>
            <Select.Option value="dwom">DWOM</Select.Option>
          </Select>
        ]}
        columns={columns}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <OrderModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(SFTPLog, getLogs)

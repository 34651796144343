import React from 'react'
import Avatar from './AvatarDropdown'
import styled from 'styled-components'
import BreadcrumbPageHeader from '../BreadcrumbPageHeader'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  margin-left: auto;
  padding: 0 24px;
  overflow: hidden;

  .action {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;

    > span {
      color: fade(#000, 85%);
      vertical-align: middle;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }

    &:global(.opened) {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .search {
    padding: 0 12px;

    &:hover {
      background: transparent;
    }
  }

  .account {
    .avatar {
      margin: 0 8px 0 0;
      color: #1890ff;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }

    .empty {
      margin: 0 8px 0 0;
      color: fade(#000, 85%);
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
`

export default function GlobalHeaderRight() {
  return (
    <Wrapper>
      <BreadcrumbPageHeader />
      <Avatar />
    </Wrapper>
  )
}

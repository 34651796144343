import api from './index'

export interface IPlate {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostPlates extends Omit<IPlate, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchPlates extends IPostPlates {}

interface IGetPlatesParams {
  start: number
  perPage: number
  search?: string
}

export async function postPlate(data: IPostPlates): Promise<{id: number}> {
  try {
    const res = await api.post(`/plates`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPlates(
  params: IGetPlatesParams = {start: 0, perPage: 1000}
): Promise<{data: IPlate[]; total: number}> {
  try {
    const res = await api.get(`/plates`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPlate(id: number): Promise<IPlate> {
  try {
    const res = await api.get(`/plates/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchPlate(id: number, data: IPatchPlates): Promise<{id: number}> {
  try {
    const res = await api.patch(`/plates/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deletePlate(id: number): Promise<void> {
  try {
    const res = await api.delete(`/plates/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

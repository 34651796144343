import {MenuProps} from 'antd/lib/menu'
import {Link, useLocation} from 'react-router-dom'
import React, {useMemo} from 'react'
import {Menu} from 'antd'
import {ApartmentOutlined, DollarOutlined, EllipsisOutlined, GiftOutlined, UserOutlined} from '@ant-design/icons'
import {routes} from '../../routes'
import {SubMenuType} from 'antd/lib/menu/hooks/useItems'

export const defaultItems = [
  {
    label: '사용자',
    key: '/console/user',
    icon: <UserOutlined />,
    children: [
      {label: '운영진 관리', key: '/console/user/administrators'},
      {label: '업체 관리', key: '/console/user/vendors'},
      {label: '업체 그룹 관리', key: '/console/user/vendorGroups'}
    ]
  },
  {
    label: '판매가',
    key: '/console/price',
    icon: <DollarOutlined />,
    children: [
      {label: '판매가 관리', key: '/console/price/prices'},
      {label: '판매가 그룹 관리', key: '/console/price/priceGroups'}
    ]
  },
  {
    label: '상품 및 상품 옵션',
    key: '/console/product',
    icon: <GiftOutlined />,
    children: [
      {label: '상품 관리', key: '/console/product/products'},
      {label: '상품 카테고리 관리', key: '/console/product/categories'},
      {label: '상품 브랜드 관리', key: '/console/product/productBrands'},
      {label: '마케팅 관리', key: '/console/product/marketingPlatforms'},
      {label: '제품군 관리', key: '/console/product/productOrderTypes'},
      {label: '판매 제품 관리', key: '/console/product/salesProducts'},
      {label: '판매 제품 상세 관리', key: '/console/product/salesProductDetail'},
      {label: '상품 채널 관리', key: '/console/product/productChannels'},
      {label: '상품 컬러 관리', key: '/console/product/productColors'},
      {label: '상품 디테일 유형 1 관리', key: '/console/product/productDetailType1'},
      {label: '상품 디테일 유형 2 관리', key: '/console/product/productDetailType2'},
      {label: '상품 패밀리 관리', key: '/console/product/families'},
      {label: '상품 패밀리 디테일 관리', key: '/console/product/familyDetails'},
      {label: '상품 품목 그룹 관리', key: '/console/product/productItemGroups'},
      {label: '등급', key: '/console/product/marketSegments'},
      {label: '상품 고객 상태 관리', key: '/console/product/parientCategories'},
      {label: '상품 생산 라인 관리', key: '/console/product/lines'},
      {label: '상품 판매 플랫폼 관리', key: '/console/product/salesPlatforms'},
      {label: '상품 세그먼트 관리', key: '/console/product/segments'},
      {label: '상품 충전기 관리', key: '/console/product/productChargers'},
      {label: '상품 충전기 품목 관리', key: '/console/product/productOptionCharges'},
      {label: '상품 리시버 방향 관리', key: '/console/product/productReceiverDirection'},
      {label: '상품 리시버 길이 관리', key: '/console/product/productReceiverLength'},
      {label: '상품 리시버 출력 관리', key: '/console/product/productReceiverPower'},
      {label: '상품 리시버 품목 관리', key: '/console/product/productOptionReceivers'},
      {label: '상품 최종 분류 값 관리', key: '/console/product/finalValues'}
    ]
  },
  {
    label: 'ITE 옵션',
    key: '/console/iteOption',
    icon: <ApartmentOutlined />,
    children: [
      {label: 'ITE 옵션', key: '/console/iteOption/iteOptions'},
      {label: '청력도 관리', key: '/console/iteOption/audiograms'},
      {label: '벤트 관리', key: '/console/iteOption/bents'},
      {label: '벤트 스타일 관리', key: '/console/iteOption/bentStyles'},
      {label: '카날 길이 관리', key: '/console/iteOption/canalsLength'},
      {label: '코팅 관리', key: '/console/iteOption/coatings'},
      {label: '귀지 방지 옵션 관리', key: '/console/iteOption/earwaxOptions'},
      {label: '손잡이 관리', key: '/console/iteOption/handles'},
      {label: '출력 관리', key: '/console/iteOption/outputs'},
      {label: '플레이트 관리', key: '/console/iteOption/plates'},
      {label: '쉘 컬러 관리', key: '/console/iteOption/shellColor'},
      {label: '쉘 사이즈 관리', key: '/console/iteOption/shellSizes'},
      {label: '쉘 타입 관리', key: '/console/iteOption/shellTypes'},
      {label: '무선 관리', key: '/console/iteOption/wireless'},
      {label: '볼륨 조절기 관리', key: '/console/iteOption/volumeControllers'},
      {label: '프로그램 버튼 관리', key: '/console/iteOption/programButtons'}
    ]
  },
  {
    label: '기타',
    key: '/console/etc',
    icon: <EllipsisOutlined />,
    children: [
      {label: '주문 관리', key: '/console/etc/orders'},
      {label: '공지사항 관리', key: '/console/etc/notices'},
      {label: '자료실 관리 (구버전)', key: '/console/etc/datas'},
      {label: '자료실 관리', key: '/console/etc/data'},
      {label: '쿠폰 관리', key: '/console/etc/coupons'},
      {label: '쿠폰 발행', key: '/console/etc/couponIssue'},
      {label: '로그인 배너 관리', key: '/console/etc/banners'},
      {label: '배송 출발 시간 관리', key: '/console/etc/deliveryTimes'},
      {label: '안전재고 추가', key: '/console/etc/safetyQuantities'},
      {label: 'SFTP 로그', key: '/console/etc/sftpLog'},
      {label: 'SAP 로그', key: '/console/etc/sapLog'},
      {label: '버전 관리', key: '/console/etc/version'}
    ]
  }
]

function getMenuItems() {
  const filteredItems: SubMenuType[] = []
  const consoleRoutes = routes.find((r) => r.path === '/console')

  if (consoleRoutes) {
    defaultItems.forEach((item) => {
      if (item.children) {
        item.children.forEach(({label, ...child}) => {
          if (consoleRoutes?.children?.find((r) => r.path === child!.key)) {
            const pushedItem = filteredItems.find((i) => i && i.key === item.key)
            const singleItem = {label: <Link to={child.key}>{label}</Link>, ...child}
            if (pushedItem) {
              pushedItem.children.push(singleItem)
            } else {
              const {children, ...rest} = item
              filteredItems.push({...rest, children: [singleItem]})
            }
          }
        })
      } else {
        filteredItems.push(item)
      }
    })
  }

  return filteredItems
}

export default function SideMenu(props: MenuProps) {
  const location = useLocation()
  const items = useMemo(() => {
    return getMenuItems()
  }, [])
  return (
    <Menu
      {...props}
      items={items}
      defaultSelectedKeys={[location.pathname]}
      defaultOpenKeys={[location.pathname.split('/', 3).join('/')]}
    />
  )
}

import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, Modal, Select, Tag} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import ImageView from '../../../../../components/ImageView'
import {
  deleteCategory,
  getCategories,
  IPostCategory,
  IProductCategory,
  patchCategory,
  postCategories
} from '../../../../../api/productCategories'
import {getProductBrands} from '../../../../../api/productBrands'
import {getProductOrderTypes} from '../../../../../api/productOrderTypes'
import {getSalesPlatforms} from '../../../../../api/salesPlatforms'
import Upload from '../../../../../components/Upload'
import {uploadImage} from '../../../../../libs/uploadImage'
import {getSalesProducts} from '../../../../../api/salesProducts'
import {getSalesProductDetails} from '../../../../../api/salesProductDetails'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPostCategory>
  onAction: (type: ActionType, record: Partial<IPostCategory>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record, categories}: {record: IProductCategory; categories: IProductCategory[]}) {
  const parents: any = []

  function getParents(category: any) {
    const searchedParent = categories.find(({id}) => id === category.parentId)
    if (searchedParent) {
      if (searchedParent.parentId) getParents(searchedParent)
      parents.push(searchedParent)
    }
  }

  getParents(record)

  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="대표 이미지">
          <ImageView src={record.titleImage} width={100} height={100} />
        </Descriptions.Item>
        <Descriptions.Item label="부모 카테고리">
          {parents.length
            ? parents.map((parent, index) =>
                parent.productBrand ? (
                  <>
                    <Tag color="yellow">Brand</Tag>
                    {parent.productBrand.name}
                    {index !== parents.length - 1 && ' → '}
                  </>
                ) : parent.productSalesPlatform ? (
                  <>
                    <Tag color="green">Sales Platform</Tag>
                    {parent.productSalesPlatform.name}
                    {index !== parents.length - 1 && ' → '}
                  </>
                ) : parent.productOrderType ? (
                  <>
                    <Tag color="blue">Order Type</Tag>
                    {parent.productOrderType.name}
                    {index !== parents.length - 1 && ' → '}
                  </>
                ) : parent.productSalesProduct ? (
                  <>
                    <Tag color="orange">Sales Product</Tag>
                    {parent.productSalesProduct.name}
                    {index !== parents.length - 1 && ' → '}
                  </>
                ) : parent.productSalesProductDetail ? (
                  <>
                    <Tag color="red">Sales Product Detail</Tag>
                    {parent.productSalesProductDetail.name}
                    {index !== parents.length - 1 && ' → '}
                  </>
                ) : (
                  ''
                )
              )
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label="이름">
          {record ? (
            record.productBrand ? (
              <>
                <Tag color="yellow">Brand</Tag>
                {record.productBrand.name}
              </>
            ) : record.productSalesPlatform ? (
              <>
                <Tag color="green">Sales Platform</Tag>
                {record.productSalesPlatform.name}
              </>
            ) : record.productOrderType ? (
              <>
                <Tag color="blue">Order Type</Tag>
                {record.productOrderType.name}
              </>
            ) : record.productSalesProduct ? (
              <>
                <Tag color="orange">Sales Product</Tag>
                {record.productSalesProduct.name}
              </>
            ) : record.productSalesProductDetail ? (
              <>
                <Tag color="red">Sales Product Detail</Tag>
                {record.productSalesProductDetail.name}
              </>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({categories, type, form, record, handleFinish}) {
  const [listDatas, setListDatas] = useState<any>({
    brands: [],
    orderTypes: [],
    platforms: [],
    products: [],
    productDetails: []
  })

  async function getListDatas() {
    const {data: brands} = await getProductBrands()
    const {data: orderTypes} = await getProductOrderTypes()
    const {data: platforms} = await getSalesPlatforms()
    const {data: products} = await getSalesProducts()
    const {data: productDetails} = await getSalesProductDetails()
    setListDatas({brands, orderTypes, platforms, products, productDetails})
  }

  useEffect(() => {
    if (form && record) {
      getListDatas()
      form.setFieldsValue({
        ...record,
        productBrandId: record?.productBrand?.id,
        productOrderTypeId: record?.productOrderType?.id,
        productSalesPlatformId: record?.productSalesPlatform?.id,
        productSalesProductId: record?.productSalesProduct?.id,
        productSalesProductDetailId: record?.productSalesProductDetail?.id
      })
    }
  }, [form, record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item label="대표 이미지" name="titleImage">
        <Upload max={1} title="titleImage" />
      </Form.Item>
      <Form.Item label="부모 카테고리" name="parentId">
        <Select style={{width: 700}} allowClear>
          {categories &&
            categories.map((category: any) => {
              const parents: any = []

              function getParents(category: any) {
                const searchedParent = categories.find(({id}) => id === category.parentId)
                if (searchedParent) {
                  if (searchedParent.parentId) getParents(searchedParent)
                  parents.push(searchedParent)
                }
              }

              getParents(category)

              return (
                <>
                  <Select.Option key={category.id} value={category.id}>
                    {parents.length
                      ? parents.map((parent, index) =>
                          parent.productBrand ? (
                            <>
                              <Tag color="yellow">Brand</Tag>
                              {parent.productBrand.name}
                              {' → '}
                            </>
                          ) : parent.productSalesPlatform ? (
                            <>
                              <Tag color="green">Sales Platform</Tag>
                              {parent.productSalesPlatform.name}
                              {' → '}
                            </>
                          ) : parent.productOrderType ? (
                            <>
                              <Tag color="blue">Order Type</Tag>
                              {parent.productOrderType.name}
                              {' → '}
                            </>
                          ) : parent.productSalesProduct ? (
                            <>
                              <Tag color="orange">Sales Product</Tag>
                              {parent.productSalesProduct.name}
                              {' → '}
                            </>
                          ) : parent.productSalesProductDetail ? (
                            <>
                              <Tag color="red">Sales Product Detail</Tag>
                              {parent.productSalesProductDetail.name}
                              {' → '}
                            </>
                          ) : (
                            ''
                          )
                        )
                      : ''}
                    {category.productBrand ? (
                      <>
                        <Tag color="yellow">Brand</Tag>
                        {category.productBrand.name}
                      </>
                    ) : category.productSalesPlatform ? (
                      <>
                        <Tag color="green">Sales Platform</Tag>
                        {category.productSalesPlatform.name}
                      </>
                    ) : category.productOrderType ? (
                      <>
                        <Tag color="blue">Order Type</Tag>
                        {category.productOrderType.name}
                      </>
                    ) : category.productSalesProduct ? (
                      <>
                        <Tag color="orange">Sales Product</Tag>
                        {category.productSalesProduct.name}
                      </>
                    ) : category.productSalesProductDetail ? (
                      <>
                        <Tag color="red">Sales Product Detail</Tag>
                        {category.productSalesProductDetail.name}
                      </>
                    ) : (
                      ''
                    )}
                  </Select.Option>
                </>
              )
            })}
        </Select>
      </Form.Item>
      <Form.Item label="브랜드" name="productBrandId">
        <Select allowClear showSearch filterOption={(input: any, option: any) => option.children.indexOf(input) >= 0}>
          {listDatas.brands
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((brand: any) => (
              <Select.Option key={brand.id} value={brand.id}>
                {brand.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="제품군" name="productOrderTypeId">
        <Select allowClear showSearch filterOption={(input: any, option: any) => option.children.indexOf(input) >= 0}>
          {listDatas.orderTypes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((orderType: any) => (
              <Select.Option key={orderType.id} value={orderType.id}>
                {orderType.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="플랫폼" name="productSalesPlatformId">
        <Select allowClear showSearch filterOption={(input: any, option: any) => option.children.indexOf(input) >= 0}>
          {listDatas.platforms.map((platform: any) => (
            <Select.Option key={platform.id} value={platform.id}>
              {platform.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="판매 상품" name="productSalesProductId">
        <Select allowClear showSearch filterOption={(input: any, option: any) => option.children.indexOf(input) >= 0}>
          {listDatas.products
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((platform: any) => (
              <Select.Option key={platform.id} value={platform.id}>
                {platform.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="판매 상품 상세" name="productSalesProductDetailId">
        <Select showSearch filterOption={(input: any, option: any) => option.children.indexOf(input) >= 0}>
          {listDatas.productDetails
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((platform: any) => (
              <Select.Option key={platform.name} value={platform.id}>
                {platform.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

const CategoryModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '상품 카테고리 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [categories, setCategories] = useState<any>([])

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: any) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values
        if (rest?.titleImage?.length) rest.titleImage = await uploadImage(rest.titleImage)
        if (id) {
          await patchCategory(id, {...rest})
        } else {
          await postCategories({...rest})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response && e.response.data.message === 'already_in_use') {
          Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteCategory(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  async function parentCategory() {
    try {
      const {data} = await getCategories()
      setCategories(data)
    } catch (e: any) {
      Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
    }
  }

  return (
    <BasicTableModal
      onOpen={parentCategory}
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1100}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit
              categories={categories}
              type={type}
              form={form}
              record={record}
              handleFinish={(values) => handleFinish(type, values)}
            />
          )
        return (
          <Form form={form}>
            <Show categories={categories} record={record} />
          </Form>
        )
      }}
    />
  )
})

export default CategoryModal

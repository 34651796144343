import React, {createContext, useCallback, useEffect, useReducer, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import qs from 'query-string'
import {message} from 'antd'

type Action<T> =
  | {type: 'GET_LIST'; payload: PageContextProps<T>}
  | {type: 'GET_LIST_LOADING_START'}
  | {type: 'GET_LIST_LOADING_END'}
  | {type: 'RESET'}

const defaultValue = {
  loading: true,
  data: [],
  params: {},
  total: 0
}

export type PageContextProps<T> = {
  loading: boolean
  params: T
  total: number
} & T

const PageContext = createContext<{
  api?: Promise<any>
  apiHandler: Function
  state: PageContextProps<any>
  dispatch: React.Dispatch<Action<any>>
}>({
  state: defaultValue,
  apiHandler: () => null,
  dispatch: () => null
})

function reducer(state: any, action: Action<any>) {
  switch (action.type) {
    case 'GET_LIST_LOADING_START':
      return {...state, loading: true}
    case 'GET_LIST_LOADING_END':
      return {...state, loading: false}
    case 'GET_LIST':
      return {...state, data: action.payload.data, params: action.payload.params}
    case 'RESET':
      return {...defaultValue}
    default:
      break
  }
}

export function PageProvider(props: any) {
  const location = useLocation()
  const mountedRef = useRef(false)
  const [state, dispatch] = useReducer(reducer, {...defaultValue, ...props.value})

  const apiHandler = useCallback(
    async (params: any) => {
      dispatch({type: 'GET_LIST_LOADING_START'})
      try {
        const {page, pageSize, ...restParams} = params
        const apiParams = {
          start: (page - 1) * pageSize,
          perPage: pageSize,
          ...restParams
        }
        const data = await state.api(apiParams)
        if (mountedRef.current) dispatch({type: 'GET_LIST', payload: {data, params}})
      } catch (e: any) {
        throw e
      } finally {
        if (mountedRef.current) dispatch({type: 'GET_LIST_LOADING_END'})
      }
    },
    [state]
  )

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    ;(async function () {
      try {
        if (state.api) {
          const apiParams = qs.parse(location.search)
          await apiHandler(apiParams)
        }
      } catch (e: any) {
        message.error(`Error Code ${e.response.status} : 리스트를 불러오는 데 실패하였습니다.`)
        throw e
      }
    })()
  }, [state.api, location.search])
  return <PageContext.Provider value={{state, dispatch, apiHandler}}>{props.children}</PageContext.Provider>
}

export default PageContext

import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, InputNumber, Modal} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import {uploadImage} from '../../../../../libs/uploadImage'
import {deleteBanner, IBanner, IPatchBanner, patchBanner, postBanner} from '../../../../../api/banners'
import ImageView from '../../../../../components/ImageView'
import Upload from '../../../../../components/Upload'
import dayjs from 'dayjs'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IBanner>
  onAction: (type: ActionType, record: Partial<IBanner>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IBanner}) {
  return (
    <div style={{padding: '0 28px 28px 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="이미지">
          <ImageView src={record.titleImage} width={100} height={100} />
        </Descriptions.Item>
        <Descriptions.Item label="모바일 이미지">
          <ImageView src={record.mobileTitleImage} width={100} height={100} />
        </Descriptions.Item>
        <Descriptions.Item label="순서">{record.order}</Descriptions.Item>
        <Descriptions.Item label="등록일">
          {dayjs(record.createdAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="수정일">
          {dayjs(record.updatedAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  useEffect(() => {
    form.setFieldsValue({})
  }, [record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item
        label="이름"
        name="name"
        rules={[
          {required: true, message: '이름을 입력해주세요'},
          {max: 25, message: '25자 이내로 입력해주세요'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="titleImage" label="이미지">
        <Upload title="titleImage" max={1} />
      </Form.Item>

      <Form.Item name="mobileTitleImage" label="이미지">
        <Upload title="mobileTitleImage" max={1} />
      </Form.Item>

      <Form.Item label="순서" name="order" rules={[{required: true, message: '순서을 입력해주세요'}]}>
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

const BannerModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '배너 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchBanner) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values

        if (rest?.titleImage?.length) rest.titleImage = await uploadImage(rest.titleImage)
        if (rest?.mobileTitleImage?.length) rest.mobileTitleImage = await uploadImage(rest.mobileTitleImage)

        if (id) {
          await patchBanner(id, {...rest})
        } else {
          await postBanner({...rest})
        }

        onAction(type, values)
      } catch (e: any) {
        if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteBanner(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit type={type} form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default BannerModal

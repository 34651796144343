import React from 'react'
import {useRecoilValue} from 'recoil'
import {Navigate} from 'react-router-dom'
import {userState} from '../states/user.state'

export default function withRequireAuth<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  roles: string[] = []
): React.ComponentType<P> {
  return function (props: P) {
    const user = useRecoilValue(userState)
    if (!user) {
      return <Navigate replace to="/auth" />
    }
    return <WrappedComponent {...props} />
  }
}

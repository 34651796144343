import {Button, Checkbox, Form, Input, Modal} from 'antd'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import AuthLayout from '../../layouts/AuthLayout'
import rules from '../../libs/rules'
import withRequireNoAuth from '../../hocs/withRequireNoAuth'
import {postAuth} from '../../api/auth'
import {useRecoilState} from 'recoil'
import {userState} from '../../states/user.state'
import styled from 'styled-components'

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 17
  }
}

function Login() {
  const [loading, setLoading] = useState(false)
  const [, setUser] = useRecoilState(userState)
  const navigate = useNavigate()

  const handleFinish = async (values: any) => {
    const {accountId, password, remember} = values
    try {
      setLoading(true)
      const res = await postAuth(accountId, password, remember)
      navigate('/console/system/administrators', {replace: true})
      setUser(res)
    } catch (e: any) {
      if (e.response.status === 404) {
        Modal.error({
          title: '로그인 실패',
          content: '아이디 혹은 패스워드가 틀렸습니다'
        })
      } else {
        Modal.error({
          title: '로그인 실패',
          content: '500 잘못된 요청입니다.'
        })
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <AuthLayout title="관리자 로그인">
      <DividerBox />
      <Form {...layout} name="basic" initialValues={{remember: true}} onFinish={handleFinish}>
        <FormItemFixedWidth
          label="아이디"
          name="accountId"
          rules={[
            {
              required: true,
              message: '아이디를 입력해주세요'
            }
          ]}
        >
          <InputRound />
        </FormItemFixedWidth>

        <FormItemFixedWidth
          label="패스워드"
          name="password"
          rules={[
            {
              required: true,
              message: '패스워드를 입력해주세요'
            },
            ...rules.password
          ]}
        >
          <InputRoundPw />
        </FormItemFixedWidth>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>로그인 정보 기억하기</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button style={{width: '200px'}} type="primary" shape="round" htmlType="submit" loading={loading}>
            로그인
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default withRequireNoAuth(Login)

const DividerBox = styled.div`
  width: 50px;
  height: 3.5px;

  margin: 30px 0;

  background: ${process.env.REACT_APP_THEME_COLOR};
`

const FormItemFixedWidth = styled(Form.Item)`
  max-width: 400px;
`

const InputRound = styled(Input)`
  width: 284px;
  border-radius: 10px;
`

const InputRoundPw = styled(Input.Password)`
  width: 284px;
  border-radius: 10px;
`

import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, Modal, Select} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import {IPatchOrder, patchOrder} from '../../../../../api/orders'
import {getParcels} from '../../../../../api/parcels'
import {CloseOutlined, FileOutlined} from '@ant-design/icons'
import dayjs from 'dayjs'
import {getFileCheck} from '../../../../../api/files'
import downloadFile from '../../../../../libs/downloadFile'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPatchOrder>
  onAction: (type: ActionType, record: Partial<IPatchOrder>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: any}) {
  const [fileName, setFileName] = useState<string>('')

  async function importFileNames() {
    const slashSplitedPath = record.file.split('/')
    const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
    setFileName(fileName)
  }

  useEffect(() => {
    importFileNames()
  }, [])

  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="번호">{record.id}</Descriptions.Item>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="내용">{record.content}</Descriptions.Item>
        <Descriptions.Item label="첨부파일">
          {!!record.file ? (
            (() => {
              const splitedFileUrl = record.file.split('/')
              const urlFileName = splitedFileUrl[splitedFileUrl.length - 1]
              return (
                <>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      downloadFile(record.file, fileName || urlFileName)
                    }}
                    href={splitedFileUrl.join('/').replace('/files', '//files')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FileOutlined /> {fileName || urlFileName}
                  </a>
                  <br />
                </>
              )
            })()
          ) : (
            <CloseOutlined />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="생성일자">{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({form, record, handleFinish}) {
  const [parcels, setParcels] = useState<any>([])

  async function handleFetch() {
    const res: any = await getParcels()
    setParcels(res.data)
  }

  useEffect(() => {
    handleFetch()
    form.setFieldsValue({status: record.orderStatus, id: record.orderId})
  }, [record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      <Form.Item name="id" label="ID" hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item name="parcelId" label="배송업체">
        <Select>
          {parcels
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((parcel) => (
              <Select.Option value={parcel.id}>{parcel.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="상태" name="status">
        <Select>
          <Select.Option value="registered">registered</Select.Option>
          <Select.Option value="confirmed">confirmed</Select.Option>
          <Select.Option value="inspection">inspection</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="관리자 메모" name="adminMemo" rules={[{max: 2000, message: '최대 200자까지 입력 가능합니다.'}]}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="송장번호" name="trackingNumber">
        <Input />
      </Form.Item>
    </Form>
  )
}

const OrderModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '주문 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchOrder) {
    setLoading(true)
    try {
      const {id, status, ...rest} = values
      id && (await patchOrder(id, {...rest, status}))
      ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
      onAction(type, values)
    } catch (e: any) {
      setLoading(false)
      if (e.response && e.response.data.message === 'already_in_use') {
        Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
      } else if (e.response) {
        Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
      throw e
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1200}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'edit')
          return <AddOrEdit form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default OrderModal

import React, {useContext, useRef, useState, useEffect} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import dayjs from 'dayjs'
import PageContext from '../../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import VendorModal from './components/VendorModal'
import {getVendors, patchVendorPassword} from '../../../../api/vendors'
import {Button, Form, Input, Modal, Space, Tag, message} from 'antd'
import {getVendorGroups, IVendorGroup} from '../../../../api/vendor-groups'
import {useForm} from 'antd/es/form/Form'

function Users() {
  const [vendorGroups, setVendorGroups] = useState<IVendorGroup[]>([])
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const [trackingModal, setTrackingModal] = useState<number>(-1)
  const [passwordForm] = useForm()

  function ascending(a, b) {
    var a = a.name.toString()
    var b = b.name.toString()
    return a.localeCompare(b)
  }

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {
      dataIndex: 'brands',
      title: '브랜드',
      render: (brands) =>
        !!brands.length && brands.map((brand) => <Tag color={process.env.REACT_APP_THEME_COLOR}>{brand.name}</Tag>)
    },
    {key: 'name', dataIndex: 'name', title: '이름'},
    {dataIndex: 'accountId', title: 'ID'},
    {dataIndex: 'address', title: '주소'},
    {dataIndex: 'tel', title: '전화번호'},
    {dataIndex: 'phone', title: '휴대폰 번호'},
    {
      key: 'groupId',
      dataIndex: ['group', 'name'],
      title: '그룹',
      filters: vendorGroups.sort(ascending).map((vendorGroup) => ({text: vendorGroup.name, value: vendorGroup.id})),
      filterMultiple: false,
      filterSearch: true
    },
    {dataIndex: ['priceGroup', 'name'], title: '판매가 그룹'},
    {dataIndex: 'status', title: '상태'},
    {dataIndex: 'manager', title: '담당자'},
    {
      dataIndex: 'id',
      title: '비밀번호 초기화',
      render: (id) => (
        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            setTrackingModal(id)
          }}
        >
          비밀번호 초기화
        </Button>
      )
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    ;(async function () {
      await handleGetVendorGroups()
    })()
  }, [])

  useEffect(() => {
    if (trackingModal === -1) {
      passwordForm.resetFields()
      onModalAction()
    }
  }, [trackingModal])

  async function handleGetVendorGroups() {
    const {data} = await getVendorGroups()
    setVendorGroups(data)
  }

  async function handleResetPassword(values) {
    if (!values.password) Modal.error({content: '새로운 비밀번호를 입력해주세요.'})
    else
      try {
        await patchVendorPassword(trackingModal, values)
        message.success('성공적으로 재설정되었습니다.')
        setTrackingModal(-1)
      } catch (e: any) {
        message.error(`재설정 도중 오류가 발생했습니다. (${e.response.status} : ${e.response.data.message})`)
      }
  }

  return (
    <>
      <BasicTable
        showSearch
        rowKey="id"
        columns={columns}
        scroll={{x: 'auto'}}
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            업체 등록
          </Button>
        ]}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'delete', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <VendorModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
      <Modal
        footer={false}
        title={`${trackingModal}번 ${state.data.data?.find(({id}) => id === trackingModal)?.name} 업체 비밀번호 재설정`}
        open={trackingModal !== -1}
        onCancel={() => setTrackingModal(-1)}
      >
        <Form form={passwordForm}>
          <Form.Item label="새 비밀번호" name="password">
            <Input.Password />
          </Form.Item>
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <Button type="primary" onClick={() => handleResetPassword(passwordForm.getFieldsValue())}>
              재설정
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default withPageContext(Users, getVendors)

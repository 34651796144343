import axios from 'axios'

const blobProcess = async (url) => {
  return axios(url, {
    method: 'GET',
    responseType: 'blob'
  })
}

const downloadFile = (url, name?) => {
  blobProcess(url).then((res) => {
    const blob = new Blob([res.data])

    const fileUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = fileUrl
    link.style.display = 'none'

    link.download = name || url.split('/')[url.split('/').length - 1]

    document.body.appendChild(link)
    link.click()
    link.remove()
  })
}

export default downloadFile

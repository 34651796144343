import api from './index'

export interface ICanalsLength {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostCanalsLength extends Omit<ICanalsLength, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchCanalsLength extends IPostCanalsLength {}

interface IGetCanalsLengthParams {
  start: number
  perPage: number
  search?: string
}

export async function postCanalsLength(data: IPostCanalsLength): Promise<{id: number}> {
  try {
    const res = await api.post(`/canalsLength`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCanalsLengths(
  params: IGetCanalsLengthParams = {start: 0, perPage: 1000}
): Promise<{data: ICanalsLength[]; total: number}> {
  try {
    const res = await api.get(`/canalsLength`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCanalsLength(id: number): Promise<ICanalsLength> {
  try {
    const res = await api.get(`/canalsLength/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchCanalsLength(id: number, data: IPatchCanalsLength): Promise<{id: number}> {
  try {
    const res = await api.patch(`/canalsLength/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteCanalsLength(id: number): Promise<void> {
  try {
    const res = await api.delete(`/canalsLength/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

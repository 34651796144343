import api from './index'

export interface IBentStyle {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostBentStyle extends Omit<IBentStyle, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchBentStyle extends IPostBentStyle {}

interface IGetBentStyleParams {
  start: number
  perPage: number
  search?: string
}

export async function postBentStyle(data: IPostBentStyle): Promise<{id: number}> {
  try {
    const res = await api.post(`/bentStyles`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getBentStyles(
  params: IGetBentStyleParams = {start: 0, perPage: 1000}
): Promise<{data: IBentStyle[]; total: number}> {
  try {
    const res = await api.get(`/bentStyles`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getBentStyle(id: number): Promise<IBentStyle> {
  try {
    const res = await api.get(`/bentStyles/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchBentStyle(id: number, data: IPatchBentStyle): Promise<{id: number}> {
  try {
    const res = await api.patch(`/bentStyles/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteBentStyle(id: number): Promise<void> {
  try {
    const res = await api.delete(`/bentStyles/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IBanner {
  id: number
  name: string
  titleImage: string
  mobileTitleImage: string
  order: number
  createdAt: string
  updatedAt: string
}

export interface IPostBanner extends Omit<IBanner, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchBanner extends IPostBanner {
  id?: number
}

interface IGetBannerParams {
  start: number
  perPage: number
}

export async function postBanner(data: IPostBanner): Promise<{id: number}> {
  try {
    const res = await api.post(`/banners`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getBanners(
  params: IGetBannerParams = {start: 0, perPage: 1000}
): Promise<{data: IBanner[]; total: number}> {
  try {
    const res = await api.get(`/banners`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getBanner(id: number): Promise<IBanner> {
  try {
    const res = await api.get(`/banners/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchBanner(id: number, data: IPatchBanner): Promise<{id: number}> {
  try {
    const res = await api.patch(`/banners/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteBanner(id: number): Promise<void> {
  try {
    const res = await api.delete(`/banners/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

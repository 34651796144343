import React, {ReactNode} from 'react'
import {Input, Space, Table, TablePaginationConfig} from 'antd'
import {TableProps} from 'antd/lib/table'
import {useLocation, useNavigate} from 'react-router-dom'
import qs from 'query-string'

interface IBasicTable<T> extends TableProps<T> {
  showSearch?: boolean
  searchPlaceHolder?: string
  searchKey?: string
  total?: number
  topButtons?: ReactNode[]
}

export default function BasicTable<T extends object = any>({
  onChange,
  pagination,
  showSearch,
  searchKey,
  searchPlaceHolder,
  topButtons,
  total,
  ...props
}: IBasicTable<T>) {
  const location = useLocation()
  const navigate = useNavigate()
  const query = qs.parse(location.search) as Record<string, string>

  if (props.columns) {
    const {sort, order, page, pageSize, search, ...rest} = query
    if (sort && order) {
      const column = props.columns.find((c: any) => (c.dataIndex || c.key) === sort)
      if (column) {
        column.defaultSortOrder = order === 'DESC' ? 'descend' : 'ascend'
      }
    }
    Object.entries(rest).forEach(([key, value]) => {
      if (props.columns) {
        const column = props.columns.find((c: any) => (c.dataIndex || c.key) === key)
        if (column) {
          column.defaultFilteredValue = Array.isArray(value) ? value : [value]
        }
      }
    })
  }

  function onChangeTable(pagination: TablePaginationConfig, filters: Record<string, any>, sorter: Record<string, any>) {
    const search: Record<string, any> = {
      ...query,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (filters[key]) {
        search[key] = filters[key]
      } else delete search[key]
    })
    if (sorter.field && sorter.order) {
      search.sort = sorter.columnKey || sorter.field
      search.order = sorter.order === 'descend' ? 'DESC' : 'ASC'
    } else {
      delete search.sort
      delete search.order
    }
    navigate({
      pathname: location.pathname,
      search: qs.stringify(search)
    })
  }

  function onChangeSearch(text?: string) {
    const search = {...query}
    if (searchKey) {
      if (text) search[searchKey] = text
      else delete search[searchKey]
    } else {
      if (text) search.search = text
      else delete search.search
    }

    navigate({
      pathname: location.pathname,
      search: qs.stringify(search)
    })
  }

  return (
    <>
      {(showSearch || topButtons) && (
        <div
          style={{
            float: 'right',
            textAlign: 'right',
            marginBottom: 12
          }}
        >
          <Space>
            {showSearch && (
              <Input.Search
                allowClear
                placeholder={searchPlaceHolder || '검색'}
                style={{width: 300}}
                enterButton
                onSearch={onChangeSearch}
              />
            )}
            {topButtons}
          </Space>
        </div>
      )}
      <Table<T>
        {...props}
        pagination={
          pagination === false
            ? pagination
            : {
                showSizeChanger: true,
                current: parseInt(query.page, 10),
                pageSize: parseInt(query.pageSize, 10),
                total
              }
        }
        onChange={onChange || onChangeTable}
      />
    </>
  )
}

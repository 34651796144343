import api from './index'

export interface IFamily {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostFamilies extends Omit<IFamily, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchFamilies extends IPostFamilies {}

interface IGetFamiliesParams {
  start: number
  perPage: number
  search?: string
}

export async function postFamily(data: IPostFamilies): Promise<{id: number}> {
  try {
    const res = await api.post(`/families`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFamilies(
  params: IGetFamiliesParams = {start: 0, perPage: 1000}
): Promise<{data: IFamily[]; total: number}> {
  try {
    const res = await api.get(`/families`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getFamily(id: number): Promise<IFamily> {
  try {
    const res = await api.get(`/families/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchFamily(id: number, data: IPatchFamilies): Promise<{id: number}> {
  try {
    const res = await api.patch(`/families/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteFamily(id: number): Promise<void> {
  try {
    const res = await api.delete(`/families/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

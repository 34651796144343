import React, {useState, useEffect} from 'react'
import {Calendar, Modal, Badge, Table, Spin} from 'antd'
import withPageContext from '../../../../hocs/withPageContext'
import moment, {Moment} from 'moment'
import {getDeliveryDetails} from '../../../../api/deliveryDetail'
import dayjs from 'dayjs'
import styled from 'styled-components'

interface RecordType {
  id: number
  serialNumber: string
  status: string
  userName: string
  modelName: string
  type: string
  deliveryTime: string
  accountCode: string
  orderType: string
  date: string
}

interface ShowProps {
  records: RecordType[]
}

const Show: React.FC<ShowProps> = ({records}) => {
  const columns = [
    {
      title: '시리얼 번호',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: '유저명',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: '모델명',
      dataIndex: 'modelName',
      key: 'modelName'
    },
    {
      title: '배송 형태',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '배송 시점',
      dataIndex: 'deliveryTime',
      key: 'deliveryTime'
    },
    {
      title: 'Account Code',
      dataIndex: 'accountCode',
      key: 'accountCode'
    },
    {
      title: '제품군',
      dataIndex: 'orderType',
      key: 'orderType'
    },
    {
      title: '날짜',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    }
  ]

  return <Table columns={columns} dataSource={records} rowKey="id" pagination={{pageSize: 10}} />
}

const SAPLogCalendar: React.FC = () => {
  const [monthData, setMonthData] = useState<RecordType[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<Moment>(moment())

  useEffect(() => {
    fetchMonthData(selectedMonth)
  }, [selectedMonth])

  const fetchMonthData = async (date: Moment) => {
    setLoading(true)
    setMonthData([])

    let start = 0
    const perPage = 1000
    let hasMoreData = true

    while (hasMoreData) {
      try {
        const response = await getDeliveryDetails({
          start,
          perPage
        })

        if (response.data && response.data.length > 0) {
          setMonthData((prevData) => [...prevData, ...response.data])
          start += perPage

          const lastItemDate = moment(response.data[response.data.length - 1].date)
          if (lastItemDate.isBefore(date.startOf('month')) || lastItemDate.isAfter(date.endOf('month'))) {
            hasMoreData = false
          }
        } else {
          hasMoreData = false
        }
      } catch (error) {
        console.error('Error fetching delivery details:', error)
        hasMoreData = false
      }
    }

    setLoading(false)
  }

  const dateCellRender = (value: Moment) => {
    const listData = monthData.filter((item) => moment(item.date).isSame(value, 'day'))
    return (
      <ul className="events" style={{listStyle: 'none', padding: 0}}>
        {listData.map((item) => (
          <li key={item.id}>
            <Badge status={item.status === '납품대기' ? 'processing' : 'success'} text={item.modelName} />
          </li>
        ))}
      </ul>
    )
  }

  const handleDateSelect = (date: Moment) => {
    const selectedEvents = monthData.filter((item) => moment(item.date).isSame(date, 'day'))
    if (selectedEvents.length > 0) {
      Modal.info({
        title: 'SAP 로그 (' + date.format('YYYY-MM-DD') + ')',
        content: <Show records={selectedEvents} />,
        width: 1000,
        okText: 'Close',
        maskClosable: true
      })
    }
  }

  const onPanelChange = (date: Moment) => {
    setSelectedMonth(date)
  }

  return (
    <div>
      <Spin spinning={loading}>
        <StyledCalendar
          mode="month"
          dateCellRender={dateCellRender}
          onSelect={handleDateSelect}
          onPanelChange={onPanelChange}
        />
      </Spin>
    </div>
  )
}

export default withPageContext(SAPLogCalendar, getDeliveryDetails)

const StyledCalendar = styled(Calendar)`
  .ant-picker-calendar-mode-switch {
    display: none;
  }
`

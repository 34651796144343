import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import dayjs from 'dayjs'
import PageContext from '../../../../contexts/PageContext'
import {Button, Tag} from 'antd'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import CategoryModal from './components/CategoryModal'
import {getCategories} from '../../../../api/productCategories'
import ImageView from '../../../../components/ImageView'

function Categories() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {
      dataIndex: 'titleImage',
      title: '대표 이미지',
      render: (titleImage) => <ImageView src={titleImage} width={100} height={100} />
    },
    {
      dataIndex: 'parentId',
      title: '부모 카테고리',
      render: (parentId, record) => {
        const parents: any = []

        function getParents(category: any) {
          const searchedParent = state.data.data.find(({id}) => id === category.parentId)
          if (searchedParent) {
            if (searchedParent.parentId) getParents(searchedParent)
            parents.push(searchedParent)
          }
        }

        getParents(record)
        return parents.length
          ? parents.map((parent, index) =>
              parent.productBrand ? (
                <>
                  <Tag color="yellow">Brand</Tag>
                  {parent.productBrand.name}
                  {index !== parents.length - 1 && ' → '}
                </>
              ) : parent.productSalesPlatform ? (
                <>
                  <Tag color="green">Sales Platform</Tag>
                  {parent.productSalesPlatform.name}
                  {index !== parents.length - 1 && ' → '}
                </>
              ) : parent.productOrderType ? (
                <>
                  <Tag color="blue">Order Type</Tag>
                  {parent.productOrderType.name}
                  {index !== parents.length - 1 && ' → '}
                </>
              ) : parent.productSalesProduct ? (
                <>
                  <Tag color="orange">Sales Product</Tag>
                  {parent.productSalesProduct.name}
                  {index !== parents.length - 1 && ' → '}
                </>
              ) : parent.productSalesProductDetail ? (
                <>
                  <Tag color="red">Sales Product Detail</Tag>
                  {parent.productSalesProductDetail.name}
                  {index !== parents.length - 1 && ' → '}
                </>
              ) : (
                ''
              )
            )
          : ''
      }
    },
    {
      title: '이름',
      render: ({
        productBrand,
        productSalesPlatform,
        productOrderType,
        productSalesProduct,
        productSalesProductDetail
      }) =>
        productBrand ? (
          <>
            <Tag color="yellow">Brand</Tag>
            {productBrand.name}
          </>
        ) : productSalesPlatform ? (
          <>
            <Tag color="green">Sales Platform</Tag>
            {productSalesPlatform.name}
          </>
        ) : productOrderType ? (
          <>
            <Tag color="blue">Order Type</Tag>
            {productOrderType.name}
          </>
        ) : productSalesProduct ? (
          <>
            <Tag color="orange">Sales Product</Tag>
            {productSalesProduct.name}
          </>
        ) : productSalesProductDetail ? (
          <>
            <Tag color="red">Sales Product Detail</Tag>
            {productSalesProductDetail.name}
          </>
        ) : (
          ''
        )
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  return (
    <>
      <BasicTable
        rowKey="id"
        columns={columns}
        showSearch
        searchPlaceHolder="이름으로 검색"
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            상품 카테고리 등록
          </Button>
        ]}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'delete', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <CategoryModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(Categories, getCategories)

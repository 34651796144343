import React, {forwardRef, MutableRefObject, RefObject, useContext, useEffect, useRef, useState} from 'react'
import {Descriptions, Form, Input, InputNumber, Modal, Select, Switch, Table} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {deleteSalesProduct, ISalesProduct, patchSalesProduct, postSalesProducts} from '../../../../../api/salesProducts'
import AccProductModal from './AccProductModal'
import PageContext from '../../../../../contexts/PageContext'
import {getProducts, IProduct} from '../../../../../api/products'
import {getProductOrderTypes} from '../../../../../api/productOrderTypes'
import Upload from '../../../../../components/Upload'
import ImageView from '../../../../../components/ImageView'
import {uploadImage} from '../../../../../libs/uploadImage'

interface IModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<ISalesProduct>
  onAction: (type: ActionType, record: Partial<ISalesProduct>) => void | Promise<void>
}

interface IShowProps {
  record: ISalesProduct
  modalRef: React.RefObject<BasicTableModalRef>
  setModalActions: React.Dispatch<React.SetStateAction<ActionType[]>>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

const productColumn = [
  {key: 'id', dataIndex: 'id', title: 'ID'},
  {
    dataIndex: 'titleImage',
    title: '대표 이미지',
    render: (titleImage) => (titleImage ? <ImageView height={100} src={titleImage} /> : '-')
  },
  {dataIndex: 'materialDescription', title: '이름'},
  {dataIndex: 'quantity', title: '수량', render: (quantity, {quantityUnit}) => +quantity * +quantityUnit},
  {
    title: '등록일',
    dataIndex: 'createdAt',
    render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
  },
  {
    title: '수정일',
    dataIndex: 'updatedAt',
    render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
  }
]

function Show({record, setModalActions, modalRef}: IShowProps) {
  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="한국어명">{record.korean}</Descriptions.Item>
        <Descriptions.Item label="악세서리 목록">
          <Table
            dataSource={record.products}
            columns={productColumn}
            onRow={(record, rowIndex) => ({
              onClick: (e) => {
                setModalActions(['show'])
                showTableModal(e, record, rowIndex)
              }
            })}
          />
        </Descriptions.Item>
        <Descriptions.Item label="대표 이미지">
          <ImageView src={record.titleImage} height={100} />
        </Descriptions.Item>
        <Descriptions.Item label="숨기기">{record.hidden ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  const [accList, setAccList] = useState<IProduct[]>([])

  async function onFetch() {
    try {
      const {data: orderTypes} = await getProductOrderTypes()
      const accId = orderTypes.find((orderType) => orderType.name.toUpperCase() === 'ACC')?.id
      const {data} = await getProducts({start: 0, perPage: 1000, orderTypeId: accId})
      setAccList(data)
      record.productIds = record.products.map(({id}) => id)
    } catch (e: any) {
      Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
    }
  }

  useEffect(() => {
    if (form && record) {
      onFetch()
      form.setFieldsValue({...record})
    }
  }, [form, record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item label="이름" name="name" rules={[{required: true, message: '이름을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="한국어명" name="korean">
        <Input />
      </Form.Item>

      <Form.Item label="악세서리" name="productIds">
        <Select
          mode="multiple"
          filterOption={(inputVal, option) => option?.title.toLowerCase().includes(inputVal.toLowerCase())}
          allowClear
        >
          {accList
            .sort((a, b) => a.materialDescription.localeCompare(b.materialDescription))
            .map((accItem) => (
              <Select.Option key={accItem.id} value={accItem.id} title={accItem.materialDescription}>
                {accItem.materialDescription}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="순서" name="seq" rules={[{required: true, message: '순서를 입력해주세요'}]}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="대표 이미지" name="titleImage">
        <Upload max={1} title="titleImage" />
      </Form.Item>

      <Form.Item label="숨기기" name="hidden" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  )
}

const SalesProductModal = forwardRef<BasicTableModalRef, IModalProps>((props, ref) => {
  const {state, apiHandler} = useContext(PageContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '판매 제품 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)

  async function onModalAction() {
    await apiHandler(state.params)
  }

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: ISalesProduct) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values
        if (rest?.titleImage?.length) rest.titleImage = await uploadImage(rest.titleImage)
        if (id) {
          await patchSalesProduct(id, {...rest})
        } else {
          await postSalesProducts({...rest})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response && e.response.data.message === 'already_in_use') {
          Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteSalesProduct(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <>
      <BasicTableModal
        ref={ref}
        actions={actions}
        title={title}
        form={form}
        width={800}
        loading={loading}
        onAction={handleAction}
        render={(type, record) => {
          if (type === 'add' || type === 'edit')
            return (
              <AddOrEdit
                type={type}
                form={form}
                record={record}
                handleFinish={(values) => handleFinish(type, values)}
              />
            )
          return (
            <Form form={form}>
              <Show record={record} setModalActions={setModalActions} modalRef={modalRef} />
            </Form>
          )
        }}
      />
      <AccProductModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
})

export default SalesProductModal

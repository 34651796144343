import React, {useContext, useEffect, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import PageContext from '../../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import DeliveryTimeModal from './components/deliveryTimeModal'
import dayjs from 'dayjs'
import {Button} from 'antd'
import {PushpinOutlined} from '@ant-design/icons'
import {getDeliveryTimes} from '../../../../api/deliveryTimes'

function DeliveryTimes() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const [list, setList] = useState<any>([])

  const columns = [
    {
      dataIndex: 'isFixation',
      render: (isFixation) => (isFixation ? <PushpinOutlined /> : <></>)
    },
    {key: 'id', dataIndex: 'id', title: 'ID'},
    {dataIndex: 'departure', title: '배송 출발 시간'},
    {dataIndex: 'closeTime', title: '오더 접수 시간'},
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD A hh:mm:ss')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD A hh:mm:ss')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    if (state.data.data) {
      const tmp = [...state.data.data]
      const fixationed = tmp.filter(({isFixation}) => isFixation)
      const disfixationed = state.data.data.filter(({isFixation}) => !isFixation)
      setList([...fixationed, ...disfixationed])
    }
  }, [state])

  return (
    <>
      <BasicTable
        rowKey="id"
        columns={columns}
        loading={state.loading}
        dataSource={list}
        total={state.data.total}
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            배송 출발 시간 등록
          </Button>
        ]}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'edit', 'delete'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <DeliveryTimeModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(DeliveryTimes, getDeliveryTimes)

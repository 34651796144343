import React, {forwardRef, MutableRefObject, RefObject, useState} from 'react'
import {Descriptions, Form, Input, InputNumber, Modal, TimePicker} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {IPatchData} from '../../../../../api/datas'
import {deleteDeliveryTime, IDeliveryTime, patchDeliveryTime, postDeliveryTime} from '../../../../../api/deliveryTimes'
import moment from 'moment'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPatchData>
  onAction: (type: ActionType, record: Partial<IPatchData>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IDeliveryTime}) {
  return (
    <div style={{padding: '0 28px 28px 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="배송 출발 시간">{record.departure}</Descriptions.Item>
        <Descriptions.Item label="오더 접수 시간">{record.closeTime}</Descriptions.Item>
        <Descriptions.Item label="등록일">
          {dayjs(record.createdAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="수정일">
          {dayjs(record.updatedAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  form.setFieldsValue({departure: moment()})

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item label={`배송 출발 시간${record.departure ? ` (기존 값 : ${record.departure})` : ''}`} name="departure">
        <TimePicker format="HH:mm" />
      </Form.Item>

      <Form.Item label="오더 접수 시간" name="closeTime">
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

const DataModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '배송 출발 시간 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: any) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values

        rest.departure = moment(rest.departure).format('HH:mm')

        if (id) {
          await patchDeliveryTime(id, {...rest})
        } else {
          await postDeliveryTime({...rest})
        }

        onAction(type, values)
      } catch (e: any) {
        if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteDeliveryTime(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit type={type} form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default DataModal

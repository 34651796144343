import {FileExcelOutlined} from '@ant-design/icons'
import {Button, Modal, Space, Table, Upload} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import * as XLSX from 'xlsx'
import ConvertCamelCase from '../../../../libs/convertCamelCase'
import getColumnItem from '../../../../components/getColumnItem'
import chunk from '../../../../libs/chunk'
import {postCouponIssue} from '../../../../api/coupons'

const defaultKeys: any = [
  {key: 'code', title: '업체 코드', required: true},
  {key: 'couponId', title: '쿠폰 ID', required: true}
]

function CouponIssue() {
  const [excelData, setExcelData] = useState<any[]>([])
  const [keys, setKeys] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<any[]>([''])
  const excelDataChangedCnt = useRef(0)

  useEffect(() => {
    if (excelData[0] && !excelDataChangedCnt.current) {
      let keys: string[] = []
      setExcelData(
        excelData.map((excelDataItem) => {
          const itemKeys = Object.keys(excelDataItem)
          if (keys.length < itemKeys.length) {
            keys = [...keys, ...itemKeys]
          }
          return {...excelDataItem, tempId: `${excelDataItem.material}_${Math.random()}`}
        })
      )
      excelDataChangedCnt.current = 1
      setKeys(keys)
      setSelectedKeys(Object.keys(keys).map((defaultKey) => keys.find((key) => key === defaultKey)))
    } else {
      excelDataChangedCnt.current = 0
    }
  }, [excelData])

  const columns = defaultKeys.map(({title, required, enumVal, type}, index) =>
    getColumnItem({
      selectedKeys,
      setExcelData,
      setSelectedKeys,
      keys,
      index,
      title,
      required,
      type,
      enumVal
    })
  )

  function handleExcelReset() {
    setExcelData([])
    setKeys([])
  }

  function handleUploadExcel({file}) {
    if (file.status !== 'uploading') {
      const reader = new FileReader()
      handleExcelReset()
      reader.onload = function () {
        const fileData = reader.result
        const wb = XLSX.read(fileData, {type: 'binary'})
        wb.SheetNames.forEach(function (sheetName) {
          let rowObj: any = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
          rowObj = rowObj.map((objItem) => {
            const newObj = {}
            Object.keys(objItem).forEach((key) => {
              newObj[ConvertCamelCase(key)] = objItem[key]
            })
            return newObj
          })
          setExcelData((prev) => [...prev, ...rowObj])
        })
      }
      reader.readAsBinaryString(file.originFileObj)
    }
  }

  async function handleExcelAdd() {
    try {
      excelData.map((dataItem) => {
        defaultKeys.forEach(({key}, index) => {
          let temp = dataItem[selectedKeys[index]]
          if (typeof temp === 'number') temp = Math.round(temp)
          if (key === 'couponId') temp = +temp
          if (key === 'code') temp = `${temp}`
          dataItem[key] = temp
        })
        return dataItem
      })

      let isNoError = true
      const excelDataChunked = chunk(excelData, 50)
      for (const chunkedItem of excelDataChunked) {
        try {
          await postCouponIssue({data: chunkedItem})
        } catch (e: any) {
          Modal.error({
            title: '쿠폰 발행 실패',
            content: e.response.data.message.includes('coupon')
              ? '쿠폰 ID가 잘못되었습니다.'
              : '업체 코드가 잘못되었습니다.'
          })
          isNoError = false
          break
        }
      }

      if (isNoError) Modal.success({content: '성공적으로 발행되었습니다.'})
      handleExcelReset()
    } catch (e: any) {
      Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
    }
  }

  return (
    <>
      <Space align="end" style={{margin: '10px 0 10px auto'}}>
        <Upload accept=".xls, .xlsx" onChange={handleUploadExcel} showUploadList={false}>
          <Button type="primary" shape="round">
            <FileExcelOutlined />
            엑셀 파일 업로드
          </Button>
        </Upload>
        <Button shape="round" onClick={handleExcelReset}>
          초기화
        </Button>
      </Space>
      <Table rowKey="index" columns={columns} dataSource={excelData} style={{overflow: 'auto'}} />
      <div style={{paddingTop: 10, display: 'flex', justifyContent: 'end', width: '100%'}}>
        <Button type="primary" onClick={handleExcelAdd}>
          발행
        </Button>
      </div>
    </>
  )
}

export default CouponIssue

import api from './index'

export interface IProductReceiverPower {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductReceiverPower extends Omit<IProductReceiverPower, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductReceiverPower extends IPostProductReceiverPower {}

interface IGetProductReceiverPowerParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductReceiverPower(data: IPostProductReceiverPower): Promise<{id: number}> {
  try {
    const res = await api.post(`/productReceiverPower`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductReceiverPowers(
  params: IGetProductReceiverPowerParams = {start: 0, perPage: 1000}
): Promise<{data: IProductReceiverPower[]; total: number}> {
  try {
    const res = await api.get(`/productReceiverPower`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductReceiverPower(id: number): Promise<IProductReceiverPower> {
  try {
    const res = await api.get(`/productReceiverPower/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductReceiverPower(id: number, data: IPatchProductReceiverPower): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productReceiverPower/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductReceiverPower(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productReceiverPower/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

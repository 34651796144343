import api from './index'

export interface ILine {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostLines extends Omit<ILine, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchLines extends IPostLines {}

interface IGetLinesParams {
  start: number
  perPage: number
  search?: string
}

export async function postLine(data: IPostLines): Promise<{id: number}> {
  try {
    const res = await api.post(`/lines`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getLines(
  params: IGetLinesParams = {start: 0, perPage: 1000}
): Promise<{data: ILine[]; total: number}> {
  try {
    const res = await api.get(`/lines`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getLine(id: number): Promise<ILine> {
  try {
    const res = await api.get(`/lines/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchLine(id: number, data: IPatchLines): Promise<{id: number}> {
  try {
    const res = await api.patch(`/lines/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteLine(id: number): Promise<void> {
  try {
    const res = await api.delete(`/lines/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

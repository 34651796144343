import api from './index'

export interface IProductOrderType {
  id: number
  name: string
  korean: string
  code: string
  titleImage: string
  hidden: boolean
  seq: number
  receiverCount: number
  chargeCount: number
  hookCount: number
  createdAt: string
  updatedAt: string
}

export interface IPostProductOrderTypes extends Omit<IProductOrderType, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductOrderTypes extends IPostProductOrderTypes {}

interface IGetProductOrderTypesParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductOrderType(data: IPostProductOrderTypes): Promise<{id: number}> {
  try {
    const res = await api.post(`/productOrderTypes`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductOrderTypes(
  params: IGetProductOrderTypesParams = {start: 0, perPage: 1000}
): Promise<{data: IProductOrderType[]; total: number}> {
  try {
    const res = await api.get(`/productOrderTypes`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductOrderType(id: number): Promise<IProductOrderType> {
  try {
    const res = await api.get(`/productOrderTypes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductOrderType(id: number, data: IPatchProductOrderTypes): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productOrderTypes/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductOrderType(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productOrderTypes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {useEffect, useState} from 'react'
import withPageContext from '../../../../hocs/withPageContext'
import {Button, Col, Row, Space, Table, Upload} from 'antd'
import {getProducts, IProduct} from '../../../../api/products'
import {FileExcelOutlined} from '@ant-design/icons'
import ConvertCamelCase from '../../../../libs/convertCamelCase'
import * as XLSX from 'xlsx'
import getColumnItem from '../../../../components/getColumnItem'
import chunk from '../../../../libs/chunk'
import {postExcel} from '../../../../api/excels'

const defaultKeys: any = [
  {key: 'safetyQuantity', title: '안전재고량', required: true},
  {key: 'material', title: '품목번호', required: true}
]

function SafetyQuantities() {
  let [excelData, setExcelData] = useState<IProduct[]>([])
  const [keys, setKeys] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>(Array(21))

  const columns = defaultKeys.map(({title}, index) =>
    getColumnItem({selectedKeys, setSelectedKeys, setExcelData, index, title, isError: true, keys})
  )

  function handleExcelReset() {
    setExcelData([])
    setKeys([])
  }

  function handleUploadExcel({file}) {
    if (file.status !== 'uploading') {
      const reader = new FileReader()
      handleExcelReset()
      reader.onload = function () {
        const fileData = reader.result
        const wb = XLSX.read(fileData, {type: 'binary'})
        wb.SheetNames.forEach(function (sheetName) {
          let rowObj: any = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
          rowObj = rowObj.map((objItem) => {
            const newObj = {}
            Object.keys(objItem).forEach((key) => {
              newObj[ConvertCamelCase(key)] = objItem[key]
            })
            return newObj
          })
          setExcelData((prev) => [...prev, ...rowObj])
        })
      }

      reader.readAsBinaryString(file.originFileObj)
    }
  }

  async function handleExcelAdd() {
    try {
      excelData.map((dataItem) => {
        defaultKeys.forEach(({key}, index) => {
          const temp = dataItem[selectedKeys[index]]
          dataItem[key] = temp
        })
        return dataItem
      })

      const excelDataChunked = chunk(excelData, 50)
      for (let chunkedItem of excelDataChunked) {
        chunkedItem = chunkedItem.filter((chunk) => !!chunk.safetyQuantity)
        try {
          await postExcel({
            type: 'safetyQuantity',
            safetyQuantityData: chunkedItem.map((item) => item)
          })
          // handleExcelReset()
          //Modal.success({content: '업로드에 성공했습니다.'})
        } catch (e: any) {
          //Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
      }
    } catch (e: any) {}
  }

  useEffect(() => {
    if (excelData[0]) {
      let keys: string[] = []
      excelData = excelData.map((excelDataItem) => {
        const itemKeys = Object.keys(excelDataItem)
        if (keys.length < itemKeys.length) {
          keys = [...keys, ...itemKeys]
        }
        return {...excelDataItem, tempId: `${excelDataItem.material}_${Math.random()}`}
      })

      setKeys(keys)
      setSelectedKeys(Object.keys(keys).map((defaultKey) => keys.find((key) => key === defaultKey) as string))
    }
  }, [excelData])

  return (
    <>
      <Space align="end" style={{margin: '10px 0 10px auto'}}>
        <Upload accept=".xls, .xlsx" onChange={handleUploadExcel} showUploadList={false}>
          <Button type="primary" shape="round">
            <FileExcelOutlined />
            엑셀 파일 업로드
          </Button>
        </Upload>
        <Button shape="round" onClick={handleExcelReset}>
          초기화
        </Button>
      </Space>
      <Table rowKey="material" columns={columns} dataSource={excelData} style={{overflow: 'auto'}} />
      <Row>
        <Col offset={22} span={3}>
          <Button type="primary" style={{marginLeft: 15}} onClick={() => handleExcelAdd()}>
            업로드
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default withPageContext(SafetyQuantities, getProducts)

import {DropDownProps} from 'antd/es/dropdown'
import {Dropdown} from 'antd'
import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

const HeaderDropdownWrapper = styled(Dropdown)`
  .container > * {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05);
  }

  @media screen and (max-width: 480px) {
    .container {
      width: 100% !important;
    }

    .container > * {
      border-radius: 0 !important;
    }
  }
`

declare type OverlayFunc = () => React.ReactNode

export interface HeaderDropdownProps extends Omit<DropDownProps, 'overlay'> {
  overlayClassName?: string
  overlay: React.ReactNode | OverlayFunc | any
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter'
}

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({overlayClassName: cls, ...restProps}) => (
  <HeaderDropdownWrapper overlayClassName={classNames('container', cls)} {...restProps} />
)

export default HeaderDropdown

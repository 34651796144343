import React, {forwardRef, MutableRefObject, RefObject, useContext, useEffect, useState} from 'react'
import {
  Button,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Typography,
  Upload
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {deletePrice, IPatchPrice, IPostPrice, IPrice, patchPrice, postPrice} from '../../../../../api/prices'
import {getPriceGroups, IPriceGroup} from '../../../../../api/priceGroups'
import PageContext from '../../../../../contexts/PageContext'
import {getProducts, IProduct} from '../../../../../api/products'
import {FileExcelOutlined} from '@ant-design/icons'
import * as XLSX from 'xlsx'
import ConvertCamelCase from '../../../../../libs/convertCamelCase'
import styled from 'styled-components'
import getColumnItem from '../../../../../components/getColumnItem'
import chunk from '../../../../../libs/chunk'
import {postExcel} from '../../../../../api/excels'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPrice>
  onAction: (type: ActionType, record: Partial<IPrice>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

const defaultKeys: any = [
  {key: 'product', title: '상품', required: true, enumVal: ['']},
  {key: 'price', title: '가격', required: true, type: 'number', enumVal: ['']},
  {key: 'priceGroup', title: '가격리스트', required: true, enumVal: ['']},
  {key: 'code', title: '가격코드', required: false, enumVal: ['']}
]

function Show({record}: {record: IPrice}) {
  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="상품">{record?.product?.materialDescription}</Descriptions.Item>
        <Descriptions.Item label="판매가">{record.price}</Descriptions.Item>
        <Descriptions.Item label="판매가 그룹">{record?.priceGroup?.name}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({
  type,
  form,
  record,
  handleFinish,
  addType,
  setAddType,
  handleExcelReset,
  excelData,
  setExcelData,
  keys,
  setKeys,
  selectedKeys,
  setSelectedKeys,
  products,
  setProducts,
  priceGroups,
  setPriceGroups,
  setGroups
}) {
  const pageContext = useContext(PageContext)

  useEffect(() => {
    if (excelData[0]) {
      let keys: string[] = []
      excelData.forEach((excelDataItem, index) => {
        const itemKeys = Object.keys(excelDataItem)
        if (keys.length < itemKeys.length) {
          keys = [...keys, ...itemKeys]
        }
        excelDataItem.index = index
      })
      setKeys(keys)
      setSelectedKeys(Object.keys(keys).map((defaultKey) => keys.find((key) => key === defaultKey)))
    }
  }, [excelData])

  const columns = defaultKeys.map(
    ({key, title, required, enumVal, type, usingKeyValue, isUseKeyValue, disableSelect}, index) =>
      getColumnItem({
        selectedKeys,
        setExcelData,
        setSelectedKeys,
        keys,
        index,
        title,
        required,
        type,
        enumVal,
        dataLength: excelData.length,
        usingKeyValue,
        isUseKeyValue,
        key,
        excelData,
        disableSelect
      })
  )

  useEffect(() => {
    if (form && record) {
      form.setFieldsValue({...record, priceGroupId: record?.priceGroup?.id, productId: record?.product?.id})
      handleGetProducts()
      handleGetPriceGroups()
    }
  }, [form, record])

  async function handleGetProducts() {
    const {data} = await getProducts()
    const defKeyIdx = defaultKeys.findIndex(({key}) => key === 'product')
    defaultKeys[defKeyIdx].enumVal = data.map(({materialDescription}) => materialDescription)
    setProducts(data)
  }

  async function handleGetPriceGroups() {
    const {data} = await getPriceGroups()
    const defKeyIdx = defaultKeys.findIndex(({key}) => key === 'priceGroup')
    const priceIds = pageContext.state.data.data.map(({priceGroup: {id}}) => id)
    defaultKeys[defKeyIdx].enumVal = data.map(({name}) => name)
    setPriceGroups(data.filter(({id}) => !priceIds.includes(id)))
  }

  function handleUploadExcel({file}) {
    if (file.status !== 'uploading') {
      const reader = new FileReader()
      handleExcelReset()
      reader.onload = function () {
        const fileData = reader.result
        const wb = XLSX.read(fileData, {type: 'binary'})
        wb.SheetNames.forEach(function (sheetName) {
          let rowObj: any = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
          rowObj = rowObj.map((objItem) => {
            const newObj = {}
            Object.keys(objItem).forEach((key) => {
              newObj[ConvertCamelCase(key)] = objItem[key]
            })
            return newObj
          })
          setExcelData((prev) => [...prev, ...rowObj])
        })
      }

      reader.readAsBinaryString(file.originFileObj)
    }
  }

  // async function handleUploadExcel({file}) {
  //   if (file.status !== 'uploading') {
  //     let tmpSelected
  //     const reader = new FileReader()
  //     handleExcelReset()
  //     reader.onload = function () {
  //       const fileData = reader.result
  //       const wb = XLSX.read(fileData, {type: 'binary'})
  //       if (wb.SheetNames.length > 1) {
  //         Modal.confirm({
  //           title: '불러 올 시트를 선택하세요.',
  //           content: (
  //             <Select style={{width: 300}} onChange={(val) => (tmpSelected = val)}>
  //               {wb.SheetNames.map((name) => (
  //                 <Select.Option value={name}>{name}</Select.Option>
  //               ))}
  //             </Select>
  //           ),
  //           onOk() {
  //             if (!tmpSelected) {
  //               Modal.error({
  //                 content: '시트가 선택되지 않았습니다.'
  //               })
  //             } else {
  //               let rowObj: any = XLSX.utils.sheet_to_json(wb.Sheets[tmpSelected])
  //               rowObj = rowObj.map((objItem) => {
  //                 const newObj = {}
  //                 Object.keys(objItem).forEach((key) => {
  //                   newObj[ConvertCamelCase(key)] = objItem[key]
  //                 })
  //                 return newObj
  //               })
  //               rowObj.forEach((rowItem) =>
  //                 Object.keys(rowItem).forEach((key: any) => {
  //                   const rawKey = key
  //                   if (key.toLowerCase().includes('asp')) {
  //                     const aspIdx = key.toLowerCase().indexOf('asp')
  //                     key = key.split('')
  //                     !Number(key[3]) && key.splice(aspIdx + 3, 0, ' ')
  //                     key = key.join('').toUpperCase()
  //                     if (defaultKeys.findIndex(({title}) => title === key) === -1) {
  //                       defaultKeys.push({key: key, title: key, required: true, disableSelect: true})
  //                       setSelectedKeys((prev) => {
  //                         prev.push(rawKey)
  //                         return prev
  //                       })
  //                       setGroups((prev) => {
  //                         prev.push({key, rawKey})
  //                         return prev
  //                       })
  //                     }
  //                   } else if (key.toLowerCase()[0] === 'p' && !!Number(key[1])) {
  //                     key = key.toUpperCase()
  //                     if (defaultKeys.findIndex(({title}) => title === key) === -1) {
  //                       defaultKeys.push({key: key, title: key, required: true, disableSelect: true})
  //                       setSelectedKeys((prev) => {
  //                         prev.push(rawKey)
  //                         return prev
  //                       })
  //                       setGroups((prev) => {
  //                         prev.push({key, rawKey})
  //                         return prev
  //                       })
  //                     }
  //                   }
  //                 })
  //               )
  //               setExcelData((prev) => [...prev, ...rowObj])
  //             }
  //           },
  //           onCancel() {}
  //         })
  //       }
  //     }

  //     reader.readAsBinaryString(file.originFileObj)
  //   }
  // }

  const selectAddType = (
    <Radio.Group
      defaultValue={addType}
      onChange={(e) => {
        setAddType(e.target.value)
        handleExcelReset()
      }}
      style={{margin: '0 0 28px 0'}}
    >
      <Radio.Button value="default" defaultChecked>
        직접 입력
      </Radio.Button>
      <Radio.Button value="excel">엑셀 파일 업로드</Radio.Button>
    </Radio.Group>
  )

  const defaultAddType = (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item label="상품" name="productId" rules={[{required: true, message: '상품을 선택해주세요'}]}>
        <Select>
          {products
            .sort((a, b) => a.name.localeCompare(b.materialDescription))
            .map((product) => (
              <Select.Option key={product.id} value={product.id}>
                {product.materialDescription}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="판매가" name="price" rules={[{required: true, message: '판매가를 입력해주세요'}]}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="판매가 그룹" name="priceGroupId" rules={[{required: true, message: 'ID를 입력해주세요'}]}>
        <Select>
          {priceGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((priceGroup) => (
              <Select.Option key={priceGroup.id} value={priceGroup.id}>
                {priceGroup.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  )

  const excelAddType = (
    <>
      <Space align="end" style={{margin: '10px 0 10px auto'}}>
        <Upload accept=".xls, .xlsx" onChange={handleUploadExcel} showUploadList={false}>
          <Button type="primary" shape="round">
            <FileExcelOutlined />
            엑셀 파일 업로드
          </Button>
        </Upload>
        <Button shape="round" onClick={handleExcelReset}>
          초기화
        </Button>
      </Space>
      <Table rowKey="index" columns={columns} dataSource={excelData} style={{overflow: 'auto'}} />
    </>
  )

  return (
    <Wrapper>
      {type === 'add' && selectAddType}
      <StyledForm {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
        {addType === 'default' ? defaultAddType : excelAddType}
      </StyledForm>
    </Wrapper>
  )
}

const PriceModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '판매가 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [addType, setAddType] = useState<'default' | 'excel'>('default')
  const [excelData, setExcelData] = useState<IProduct[]>([])
  const [keys, setKeys] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([''])
  const [products, setProducts] = useState<IProduct[]>([])
  const [priceGroups, setPriceGroups] = useState<IPriceGroup[]>([])
  const [groups, setGroups] = useState<any[]>([])

  const columns = defaultKeys.map(({title}, index) =>
    getColumnItem({selectedKeys, setSelectedKeys, setExcelData, index, title, isError: true})
  )

  columns.push({
    dataIndex: 'error',
    title: <>에러 메세지</>,
    render: (error) => <Typography.Text type="danger">{error}</Typography.Text>
  })

  function handleExcelReset() {
    setExcelData([])
    setKeys([])
  }

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPrice) {
    async function handleDefaultAddOrEdit() {
      try {
        const {id, price, productId, priceGroupId, ...rest} = values
        if (id) {
          const data: IPatchPrice = {price, productId, priceGroupId}
          await patchPrice(id, {...rest, ...data})
        } else {
          const data: IPostPrice = {price, productId, priceGroupId}
          await postPrice({...rest, ...data})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 409)
            Modal.error({content: '409: 해당 판매가 그룹은 이미 다른 판매가로 등록되어 있습니다.'})
          else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    }

    async function handleExcelAdd() {
      try {
        excelData.map((dataItem) => {
          defaultKeys.forEach(({key}, index) => {
            let temp = dataItem[selectedKeys[index]]
            if (typeof temp === 'number') temp = Math.round(temp)
            dataItem[key] = temp
          })
          return dataItem
        })

        const excelDataChunked = chunk(excelData, 50)
        for (const chunkedItem of excelDataChunked) {
          try {
            await postExcel({type: 'price', priceData: chunkedItem})
          } catch {}
        }

        handleExcelReset()
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
    }

    async function handleDelete() {
      if (values.id) {
        await deletePrice(values.id)
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      }
    }

    setLoading(true)
    if ((type === 'add' && addType === 'default') || type === 'edit') await handleDefaultAddOrEdit()
    else if (type === 'add' && addType === 'excel') await handleExcelAdd()
    else if (type === 'delete') await handleDelete()
    setLoading(false)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit
              type={type}
              form={form}
              record={record}
              handleFinish={(values) => handleFinish(type, values)}
              addType={addType}
              setAddType={setAddType}
              handleExcelReset={handleExcelReset}
              excelData={excelData}
              setExcelData={setExcelData}
              keys={keys}
              setKeys={setKeys}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              products={products}
              setProducts={setProducts}
              priceGroups={priceGroups}
              setPriceGroups={setPriceGroups}
              setGroups={setGroups}
            />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 28px;
`

const StyledForm = styled(Form)`
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export default PriceModal

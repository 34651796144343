import React, {forwardRef, ReactNode, Ref, RefObject, useImperativeHandle, useState} from 'react'
import {Button, Modal, Result} from 'antd'
import {FormInstance} from 'antd/lib/form'

export type ActionType = 'show' | 'add' | 'edit' | 'delete'

interface BasicTableModalProps<T extends object = any> {
  ref: RefObject<BasicTableModalRef>
  loading?: boolean
  form?: FormInstance
  title: string
  subTitle?: string
  nameKey?: string
  width?: number
  actions: ActionType[]
  record?: any
  render: (type: ActionType, record?: T, coupons?: any) => ReactNode
  onAction: (type: ActionType, values: T) => void
  onOpen?: any
  footer?: ReactNode[]
}

type ShowModalProps =
  | {type: 'add'}
  | {type: 'show' | 'edit'; record: any}
  | {type: 'show' | 'edit'; record: any; coupons: any}

export interface BasicTableModalRef {
  showModal: (props: ShowModalProps) => void
  doneModal: (type?: ActionType) => void
  closeModal: () => void
}

const BasicTableModal = forwardRef((props: BasicTableModalProps, ref: Ref<any>) => {
  const [open, setOpen] = useState(false)
  const [done, setDone] = useState(false)
  const [type, setType] = useState<ActionType>('show')
  const [record, setRecord] = useState<any>({})
  const {
    onOpen,
    title,
    subTitle,
    nameKey,
    width = 640,
    render,
    onAction,
    loading,
    form,
    actions,
    footer: customFooter
  } = props

  function showModal(modalProps: ShowModalProps) {
    setType(modalProps.type)
    if (form) form.resetFields()
    if (modalProps.type !== 'add') {
      form?.setFieldsValue(modalProps.record || {})
      setRecord(modalProps.record)
    } else setRecord({})
    if (onOpen) onOpen()
    setOpen(true)
  }

  function doneModal(actionType: ActionType) {
    setType(actionType)
    setDone(true)
  }

  function closeModal(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    setDone(false)
    setOpen(false)
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    onAction(type, record)
  }

  function handleEdit(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    setType('edit')
  }

  useImperativeHandle(ref, () => {
    return {
      showModal,
      doneModal,
      closeModal
    }
  })

  const CancelButton = (
    <Button key="cancel" onClick={closeModal}>
      취소
    </Button>
  )

  const SubmitButton = (
    <Button key={type} type="primary" htmlType="submit" loading={loading} onClick={handleSubmit}>
      확인
    </Button>
  )

  const EditButton = (
    <Button key="edit" loading={loading} onClick={handleEdit}>
      수정
    </Button>
  )

  const DeleteButton = (
    <Button
      key="delete"
      danger
      loading={loading}
      onClick={() => {
        Modal.confirm({
          title: `${title} 삭제`,
          content: `${nameKey ? `${record[nameKey]} ` : ''}삭제하시겠습니까?`,
          okText: '삭제',
          cancelText: '취소',
          onOk: () => onAction('delete', record)
        })
      }}
    >
      삭제
    </Button>
  )

  let modalFooter
  if (done) modalFooter = {footer: null, onCancel: closeModal}
  else if (type === 'show') {
    const footer: ReactNode[] = customFooter ? [...customFooter] : []
    if (actions.indexOf('delete') > -1) footer.push(DeleteButton)
    if (actions.indexOf('edit') > -1) footer.push(EditButton)
    modalFooter = {footer, onCancel: closeModal}
  } else if (type === 'edit' || type === 'add') {
    modalFooter = {footer: [CancelButton, SubmitButton], onCancel: closeModal}
  }

  let msg = ''
  switch (type) {
    case 'edit':
      msg = '수정'
      break
    case 'add':
      msg = '등록'
      break
    case 'show':
      msg = '상세'
      break
    case 'delete':
      msg = '삭제'
      break
    default:
      break
  }

  function getModalContent() {
    if (done) {
      return (
        <Result
          status="success"
          title="성공"
          subTitle={`${title} ${msg} 완료되었습니다.`}
          extra={
            <Button type="primary" onClick={closeModal}>
              확인
            </Button>
          }
        />
      )
    }
    return render(type, record)
  }

  return (
    <Modal
      getContainer={false}
      title={!done && `${title} ${subTitle || msg}`}
      width={done ? 640 : width}
      bodyStyle={done ? {padding: '72px 0'} : {padding: '28px 0 0'}}
      destroyOnClose
      open={open}
      maskClosable={type === 'show'}
      {...modalFooter}
    >
      {getModalContent()}
    </Modal>
  )
})

export default BasicTableModal

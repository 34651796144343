import React, {forwardRef, RefObject} from 'react'
import {Descriptions} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {IProductReceiverPower} from '../../../../../api/productReceiverPower'
import {IAccProduct} from '../../../../../api/salesProducts'
import {Link} from 'react-router-dom'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProductReceiverPower>
  onAction: (type: ActionType, record: Partial<IProductReceiverPower>) => void | Promise<void>
}

function Show({record}: {record: IAccProduct}) {
  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record?.materialDescription}</Descriptions.Item>
        <Descriptions.Item label="품목번호">{record?.material}</Descriptions.Item>
        <Descriptions.Item label="바코드">{record?.barcode}</Descriptions.Item>
        <Descriptions.Item label="수량">{record?.quantity}</Descriptions.Item>
        <Descriptions.Item label="판매 단위">{record?.quantityUnit}</Descriptions.Item>
        <Descriptions.Item label="판매단위별 수">{record?.salesNumber}</Descriptions.Item>
        <Descriptions.Item label="구매단위별 수">{record?.purchaseNumber}</Descriptions.Item>
        <Descriptions.Item label="출하 유형">{record?.deliveryType}</Descriptions.Item>
        <Descriptions.Item label="최종 구매 통화">{record?.currencyType}</Descriptions.Item>
        <Descriptions.Item label="대표이미지">{record?.titleImage}</Descriptions.Item>
        <Descriptions.Item label="볼륨 컨트롤러">{record?.volumeController}</Descriptions.Item>
        <Descriptions.Item label="프로그램 버튼">{record?.programButton}</Descriptions.Item>
        <Descriptions.Item label="귀 모양 파일">
          <Link to={record?.earImpressionFile}>다운로드</Link>
        </Descriptions.Item>
        <Descriptions.Item label="추가 파일">
          <Link to={record?.addFile}>다운로드</Link>
        </Descriptions.Item>
        <Descriptions.Item label="메모">{record?.memo}</Descriptions.Item>
        <Descriptions.Item label="숨기기">{record?.onSale ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record?.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record?.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

const AccProductModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const {title = '악세서리 ', onAction, actions = ['show']} = props

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      width={800}
      onAction={onAction}
      render={(type, record) => <Show record={record} />}
    />
  )
})

export default AccProductModal

import axios from 'axios'
import {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'

interface IProps {
  src: string
  width?: number | string
  height?: number | string
  size?: string
  isBorder?: boolean
  preview?: boolean
}

export default function ImageView({src, width, height, size, isBorder, preview}: IProps) {
  const splitedSrc = src ? src.split('/') : []
  const [isErrored, setIsErrored] = useState<boolean>(false)

  splitedSrc.length && splitedSrc[3] === 'images' && splitedSrc.splice(3, 0, 'undefined')

  async function handleFetchImage() {
    try {
      await axios.get(splitedSrc.join('/'))
    } catch (e: any) {
      setIsErrored(true)
    }
  }

  useEffect(() => {
    handleFetchImage()
  }, [])

  return (
    <StyledImage
      src={splitedSrc.length ? (isErrored ? src : splitedSrc.join('/')) : '/defaultImage.svg'}
      width={width}
      height={height}
      size={size}
      isBorder={isBorder}
      preview={preview}
    />
  )
}

const StyledImage = styled.div<IProps>`
  background-image: url(${({src}) => src});
  background-size: ${({size}) => size || 'contain'};
  background-position: center center;
  background-repeat: no-repeat;
  width: ${({width}) => (width ? (typeof width === 'string' ? width : width + 'px') : 100)};
  height: ${({height}) => (height ? (typeof height === 'string' ? height : height + 'px') : 100)};
  overflow: hidden;
  ${({isBorder}) =>
    isBorder &&
    css`
      border: 1px solid #d0d0d0;
      border-radius: 5px;
    `}
`

import api from './index'

export interface IShellColor {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostShellColors extends Omit<IShellColor, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchShellColors extends IPostShellColors {}

interface IGetShellColorsParams {
  start: number
  perPage: number
  search?: string
}

export async function postShellColor(data: IPostShellColors): Promise<{id: number}> {
  try {
    const res = await api.post(`/shellColor`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getShellColors(
  params: IGetShellColorsParams = {start: 0, perPage: 1000}
): Promise<{data: IShellColor[]; total: number}> {
  try {
    const res = await api.get(`/shellColor`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getShellColor(id: number): Promise<IShellColor> {
  try {
    const res = await api.get(`/shellColor/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchShellColor(id: number, data: IPatchShellColors): Promise<{id: number}> {
  try {
    const res = await api.patch(`/shellColor/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteShellColor(id: number): Promise<void> {
  try {
    const res = await api.delete(`/shellColor/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IProductChannel {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductChannels extends Omit<IProductChannel, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductChannels extends IPostProductChannels {}

interface IGetProductChannelsParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductChannel(data: IPostProductChannels): Promise<{id: number}> {
  try {
    const res = await api.post(`/productChannels`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductChannels(
  params: IGetProductChannelsParams = {start: 0, perPage: 1000}
): Promise<{data: IProductChannel[]; total: number}> {
  try {
    const res = await api.get(`/productChannels`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductChannel(id: number): Promise<IProductChannel> {
  try {
    const res = await api.get(`/productChannels/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductChannel(id: number, data: IPatchProductChannels): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productChannels/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductChannel(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productChannels/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

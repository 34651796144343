import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, Modal, Select, Switch, Tag} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import {deleteNotice, INotice, IPatchNotice, patchNotice, postNotice} from '../../../../../api/notices'
import dayjs from 'dayjs'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {uploadImage} from '../../../../../libs/uploadImage'
import ImageEditor from '../../../../../components/Editor/ImageEditor'
import {getProductBrands, IProductBrand} from '../../../../../api/productBrands'
import styled from 'styled-components'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPatchNotice>
  onAction: (type: ActionType, record: Partial<IPatchNotice>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: INotice}) {
  return (
    <div style={{padding: '0 28px 28px 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="제목">{record.title}</Descriptions.Item>
        {!!record.brands.length && (
          <Descriptions.Item label="대상 브랜드">
            {record.brands.map((brand) => (
              <Tag>{brand.name}</Tag>
            ))}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="내용">
          <HtmlContainer dangerouslySetInnerHTML={{__html: record.content}} />
        </Descriptions.Item>
        <Descriptions.Item label="중요 여부">
          {record.isFixation ? <CheckOutlined /> : <CloseOutlined />}
        </Descriptions.Item>
        <Descriptions.Item label="등록일">
          {dayjs(record.createdAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="수정일">
          {dayjs(record.updatedAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({content, setContent, type, form, record, handleFinish}) {
  const [brands, setBrands] = useState<IProductBrand[]>([])

  async function onFetchBrands() {
    const {data} = await getProductBrands()
    setBrands(data)
  }

  useEffect(() => {
    form.setFieldsValue({})
    onFetchBrands()
    if (record.brands)
      form.setFieldValue(
        'brands',
        record.brands.map((brand) => JSON.stringify(brand))
      )
    type !== 'add' ? setContent(form.getFieldValue('content')) : form.setFieldValue('content', '')
  }, [record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item
        label="제목"
        name="title"
        rules={[
          {required: true, message: '제목을 입력해주세요'},
          {min: 4, max: 100, message: '4~100자 이내로 입력해주세요'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="대상 브랜드" name="brands">
        <Select mode="multiple">
          {brands
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((brand: any) => (
              <Select.Option key={brand.id} value={JSON.stringify({id: brand.id, name: brand.name})}>
                {brand.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="중요 여부" name="isFixation" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="내용" name="content" rules={[{required: true, message: '내용을 입력해주세요'}]}>
        <ImageEditor
          data={content}
          onChange={(e: React.ChangeEvent, editor: object | any) => setContent(editor.getData())}
        />
      </Form.Item>
    </Form>
  )
}

const NoticeModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '공지사항 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [content, setContent] = useState('')

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchNotice) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, files, ...rest} = values

        if (Array.isArray(files)) {
          for (let i = 0; i < files.length; i++) {
            files[i] = await uploadImage(files[i])
          }
        }

        if (rest.brands)
          rest.brands = rest.brands.map((brand) => (typeof brand === 'string' ? JSON.parse(brand) : brand))
        else rest.brands = []

        if (id) {
          await patchNotice(id, {files, ...rest, content})
        } else {
          await postNotice({files, ...rest, content})
        }

        onAction(type, values)
      } catch (e: any) {
        if (e.response.status === 500 && !e.response.data.message) {
          setLoading(false)
          ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
          onAction(type, values)
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteNotice(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit
              content={content}
              setContent={setContent}
              type={type}
              form={form}
              record={record}
              handleFinish={(values) => handleFinish(type, values)}
            />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const HtmlContainer = styled.div`
  img {
    max-width: 480px;
  }
`

export default NoticeModal

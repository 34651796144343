import {ICoupon} from './coupons'
import api from './index'
import {IProduct} from './products'

export interface IOrder {
  orderId: number
  orderProductId: number
  orderStatus: string
  buyerName: string
  product: IProduct
  amount: number
  quantity: number
}

export interface IPatchOrder {
  id?: number
  parcelId?: number
  status: 'registered' | 'confirmed' | 'inspection'
  adminMemo?: string
  trackingNumber?: string
}

interface IGetOrderParams {
  start: number
  perPage: number
}

export async function getOrders(
  params: IGetOrderParams = {start: 0, perPage: 1000}
): Promise<{data: IOrder[]; total: number}> {
  try {
    const res = await api.get(`/orders`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOrder(id: number): Promise<{data: IOrder[]; coupons: ICoupon[]}> {
  try {
    const res = await api.get(`/orders/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchOrder(id: number, data: IPatchOrder): Promise<{id: number}> {
  try {
    const res = await api.patch(`/orders/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

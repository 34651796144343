import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, InputNumber, Modal, Select, Switch, Tag} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {deleteFinalValue, IFinalValue, patchFinalValue, postFinalValue} from '../../../../../api/finalValues'
import {getProductColors, IProductColor} from '../../../../../api/productColors'
import Upload from '../../../../../components/Upload'
import {uploadImage} from '../../../../../libs/uploadImage'
import ImageView from '../../../../../components/ImageView'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IFinalValue>
  onAction: (type: ActionType, record: Partial<IFinalValue>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IFinalValue}) {
  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="한국어명">{record.korean}</Descriptions.Item>
        <Descriptions.Item label="설명">{record.comment}</Descriptions.Item>
        <Descriptions.Item label="대표 이미지">
          <ImageView height={100} preview={false} src={record.titleImage} />
        </Descriptions.Item>
        <Descriptions.Item label="변경 가능 컬러">
          {record.changeColors.map(({name, code}) => (
            <Tag>
              {name}({code})
            </Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="순서">{record.seq}</Descriptions.Item>
        <Descriptions.Item label="숨기기">{record.hidden ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  const [colors, setColors] = useState<IProductColor[]>([])
  useEffect(() => {
    if (form && record) {
      ;(async function () {
        const {data} = await getProductColors()
        setColors(data)
      })()
      const changeColorIds = record.changeColors?.map(({id}) => id)
      form.setFieldsValue({...record, changeColorIds})
    }
  }, [form, record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item label="이름" name="name" rules={[{required: true, message: '이름을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="한국어명" name="korean">
        <Input />
      </Form.Item>

      <Form.Item label="설명" name="comment">
        <Input />
      </Form.Item>

      <Form.Item label="변경 가능 컬러" name="changeColorIds">
        <Select mode="multiple">
          {colors
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((color) => (
              <Select.Option key={color.id} value={color.id}>
                {color.name} ({color.code})
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="대표 이미지" name="titleImage">
        <Upload title="titleImage" max={1} />
      </Form.Item>

      <Form.Item label="상태" name="status" rules={[{required: true, message: '상태를 선택해주세요'}]}>
        <Select>
          <Select.Option value="published">published</Select.Option>
          <Select.Option value="discontinued">discontinued</Select.Option>
          <Select.Option value="hidden">hidden</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="순서" name="seq" rules={[{required: true, message: '순서을 입력해주세요'}]}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="숨기기" name="hidden" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  )
}

const FinalValueModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '최종 분류 값 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IFinalValue) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values
        if (rest?.titleImage?.length) rest.titleImage = await uploadImage(rest.titleImage)
        if (id) {
          await patchFinalValue(id, {...rest})
        } else {
          await postFinalValue({...rest})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response && e.response.data.message === 'already_in_use') {
          Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteFinalValue(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit type={type} form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default FinalValueModal

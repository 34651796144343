import api from './index'
import {IPrice} from './prices'
import {IProduct} from './products'
import {IVendor} from './vendors'

interface ISafetyQuantity {
  safetyQuantity: number
  material: string
}

export interface IPostExcels {
  type: 'price' | 'vendor' | 'product' | 'safetyQuantity'
  priceData?: IPrice[]
  vendorData?: IVendor[]
  productData?: IProduct[]
  safetyQuantityData?: ISafetyQuantity[]
}

export async function postExcel(data: IPostExcels): Promise<{id: number}> {
  try {
    const res = await api.post(`/excels`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

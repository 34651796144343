import api from './index'

export interface IVolumeControllers {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostVolumeControllers extends Omit<IVolumeControllers, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchVolumeControllers extends IPostVolumeControllers {}

interface IGetVolumeControllersParams {
  start: number
  perPage: number
  search?: string
}

export async function postVolumeControllers(data: IPostVolumeControllers): Promise<{id: number}> {
  try {
    const res = await api.post(`/productVolumeController`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getVolumeControllers(
  params: IGetVolumeControllersParams = {start: 0, perPage: 1000}
): Promise<{data: IVolumeControllers[]; total: number}> {
  try {
    const res = await api.get(`/productVolumeController`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getVolumeControllersItem(id: number): Promise<IVolumeControllers> {
  try {
    const res = await api.get(`/productVolumeController/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchVolumeControllers(id: number, data: IPatchVolumeControllers): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productVolumeController/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteVolumeControllers(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productVolumeController/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export default function ConvertCamelCase(key) {
  return key
    .split(' ')
    .map((str, index) => {
      if (str)
        return str && !index ? `${str[0].toLowerCase()}${str.substr(1)}` : `${str[0].toUpperCase()}${str.substr(1)}`
      else return str
    })
    .join('')
}

import api from './index'

export interface IShellSize {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostShellSizes extends Omit<IShellSize, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchShellSizes extends IPostShellSizes {}

interface IGetShellSizesParams {
  start: number
  perPage: number
  search?: string
}

export async function postShellSize(data: IPostShellSizes): Promise<{id: number}> {
  try {
    const res = await api.post(`/shellSizes`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getShellSizes(
  params: IGetShellSizesParams = {start: 0, perPage: 1000}
): Promise<{data: IShellSize[]; total: number}> {
  try {
    const res = await api.get(`/shellSizes`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getShellSize(id: number): Promise<IShellSize> {
  try {
    const res = await api.get(`/shellSizes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchShellSize(id: number, data: IPatchShellSizes): Promise<{id: number}> {
  try {
    const res = await api.patch(`/shellSizes/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteShellSize(id: number): Promise<void> {
  try {
    const res = await api.delete(`/shellSizes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import {getFileUpload, postFileUpload} from '../api/files'
import axios from 'axios'

export const uploadImage = async (file: File | string, onProgress?: (event: {percent: number}) => void) => {
  if (Array.isArray(file) && file.length < 2) file = file[0]
  if (typeof file === 'string') return file

  const data = await getFileUpload({type: file.type.split('/')[0] === 'image' ? 'image' : 'file', mimeType: file?.type})

  await axios.put(data.url, file, {
    headers: {'Content-Type': file.type},
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round(progressEvent.loaded * 10000)
      if (onProgress) onProgress({percent: percentCompleted})
    }
  })

  const {url} = await postFileUpload({
    type: file.type.split('/')[0] === 'image' ? 'image' : 'file',
    kind: 'products',
    path: data.path,
    name: file.name
  })

  const splitedSrc = url ? url.split('/') : []
  // splitedSrc.length && splitedSrc.splice(3, 0, 'undefined')

  return splitedSrc.join('/')
}

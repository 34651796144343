import api from './index'
import {IImageCommon} from './productCategories'

export interface IteOption {
  id: number
  name: string
  memo: string
  productShellTypes: IImageCommon[]
  productShellColors: IImageCommon[]
  productPlates: IImageCommon[]
  productOutputs: IImageCommon[]
  productBents: IImageCommon[]
  productBentStyles: IImageCommon[]
  productHandles: IImageCommon[]
  productEarwaxOptions: IImageCommon[]
  productCoatings: IImageCommon[]
  productCanalLengths: IImageCommon[]
  productShellSizes: IImageCommon[]
  productWireless: IImageCommon[]
  productVolumeControllers: IImageCommon[]
  productProgramButtons: IImageCommon[]
  productAudiograms: IImageCommon[]
  createdAt: string
  updatedAt: string
}

export interface IPostIteOption {
  shellTypeIds?: number[]
  shellColorIds?: number[]
  plateIds?: number[]
  outputIds?: number[]
  bentIds?: number[]
  bentStyleIds?: number[]
  handleIds?: number[]
  earwaxOptionIds?: number[]
  coatingIds?: number[]
  shellSizeIds?: number[]
  wirelessIds?: number[]
  titleImage?: string
  memo?: string
}

export interface IPatchIteOption extends IPostIteOption {}

interface IGetIteOptionParams {
  start: number
  perPage: number
  search?: string
}

export async function postIteOption(data: IPostIteOption): Promise<{id: number}> {
  try {
    const res = await api.post(`/iteOption`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getIteOptions(
  params: IGetIteOptionParams = {start: 0, perPage: 1000}
): Promise<{data: IteOption[]; total: number}> {
  try {
    const res = await api.get(`/iteOption`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getIteOption(id: number): Promise<IteOption> {
  try {
    const res = await api.get(`/iteOption/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchIteOption(id: number, data: IPatchIteOption): Promise<{id: number}> {
  try {
    const res = await api.patch(`/iteOption/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteIteOption(id: number): Promise<void> {
  try {
    const res = await api.delete(`/iteOption/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

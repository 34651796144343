import api from './index'

interface IPriceGroup {
  id: number
  name: string
}

export interface IPrice {
  id: number
  price: number
  product?: {
    id: number
    materialDescription: string
    createdAt: string
    updatedAt: string
  }
  productId?: number
  priceGroup?: IPriceGroup
  priceGroupId?: number
  createdAt: string
  updatedAt: string
  code?: number
}

export interface IPostPrice extends Omit<IPrice, 'id' | 'createdAt' | 'updatedAt' | 'product' | 'priceGroup'> {}

export interface IPatchPrice extends IPostPrice {}

interface IGetPriceParams {
  start: number
  perPage: number
  search?: string
}

export async function postPrice(data: IPostPrice): Promise<{id: number}> {
  try {
    const res = await api.post(`/prices`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPrices(
  params: IGetPriceParams = {start: 0, perPage: 1000}
): Promise<{data: IPrice[]; total: number}> {
  try {
    const res = await api.get(`/prices`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getPrice(id: number): Promise<IPrice> {
  try {
    const res = await api.get(`/prices/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchPrice(id: number, data: IPatchPrice): Promise<{id: number}> {
  try {
    const res = await api.patch(`/prices/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deletePrice(id: number): Promise<void> {
  try {
    const res = await api.delete(`/prices/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

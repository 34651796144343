import api from './index'
import {IImageCommon} from './productCategories'

export interface ISalesProductDetail {
  id: number
  name: string
  korean: string
  titleImage: string
  seq: number
  hidden: boolean
  productSalesProduct: IImageCommon
  createdAt: string
  updatedAt: string
}

export interface IPostSalesProductDetail {
  name: string
  hidden?: boolean
  productSalesProductId: number
  titleImage?: string
}

export interface IPatchSalesProductDetail {
  name?: string
  hidden?: boolean
  productSalesProductId?: number
  titleImage?: string
}

interface IGetSalesProductDetailParams {
  start: number
  perPage: number
  search?: string
}

export async function postSalesProductDetail(data: IPostSalesProductDetail): Promise<{id: number}> {
  try {
    const res = await api.post(`/salesProductDetail`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSalesProductDetails(
  params: IGetSalesProductDetailParams = {start: 0, perPage: 1000}
): Promise<{data: ISalesProductDetail[]; total: number}> {
  try {
    const res = await api.get(`/salesProductDetail`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSalesProductDetail(id: number): Promise<ISalesProductDetail> {
  try {
    const res = await api.get(`/salesProductDetail/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchSalesProductDetail(id: number, data: IPatchSalesProductDetail): Promise<{id: number}> {
  try {
    const res = await api.patch(`/salesProductDetail/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteSalesProductDetail(id: number): Promise<void> {
  try {
    const res = await api.delete(`/salesProductDetail/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

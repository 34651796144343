import api from './index'

export interface IAudiogram {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostAudiogram extends Omit<IAudiogram, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchAudiogram extends IPostAudiogram {}

interface IGetAudiogramParams {
  start: number
  perPage: number
  search?: string
}

export async function postAudiogram(data: IPostAudiogram): Promise<{id: number}> {
  try {
    const res = await api.post(`/audiograms`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getAudiograms(
  params: IGetAudiogramParams = {start: 0, perPage: 1000}
): Promise<{data: IAudiogram[]; total: number}> {
  try {
    const res = await api.get(`/audiograms`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getAudiogram(id: number): Promise<IAudiogram> {
  try {
    const res = await api.get(`/audiograms/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchAudiogram(id: number, data: IPatchAudiogram): Promise<{id: number}> {
  try {
    const res = await api.patch(`/audiograms/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteAudiogram(id: number): Promise<void> {
  try {
    const res = await api.delete(`/audiograms/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

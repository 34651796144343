/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, InputNumber, Modal, Select, Space} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {
  deleteDataGroup,
  getDataGroups,
  IDataGroup,
  IPatchDataGroups,
  patchDataGroup,
  postDataGroups
} from '../../../../../api/dataGroups'
import {useParams} from 'react-router'
import {RightOutlined} from '@ant-design/icons'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPatchDataGroups>
  onAction: (type: ActionType, record: Partial<IPatchDataGroups>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IDataGroup}) {
  const [dataGroups, setDataGroups] = useState<IDataGroup[]>([])
  const [folderPathArr, setFolderPathArr] = useState<IDataGroup[]>([])

  async function onFetchDataGroups() {
    const {data} = await getDataGroups()
    setDataGroups(data)
  }

  useEffect(() => {
    onFetchDataGroups()
  }, [])

  useEffect(() => {
    let folderPathArr: any[] = [record]
    let targetFolder: any = record

    while (1) {
      if (targetFolder && targetFolder.parentId) {
        targetFolder = dataGroups.find(({id}) => id === +targetFolder.parentId)
        if (targetFolder) folderPathArr = [targetFolder, ...folderPathArr]
        else break
      } else break
    }

    setFolderPathArr(folderPathArr)
  }, [record, dataGroups])

  return (
    <div style={{padding: '0 28px 28px 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="폴더명">{record.name}</Descriptions.Item>
        <Descriptions.Item label="폴더 경로">
          {folderPathArr.map(({id, name}) => (
            <Space>
              {`${name}(${id})`}
              {id !== record.id && <RightOutlined />}
            </Space>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="정렬 순서">{record.sortOrder || '-'}</Descriptions.Item>
        <Descriptions.Item label="등록일">
          {dayjs(record.createdAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="수정일">
          {dayjs(record.updatedAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({content, setContent, type, form, record, handleFinish}) {
  const [dataGroups, setDataGroups] = useState<IDataGroup[]>([])
  const {id} = useParams()

  async function onFetchDataGroups() {
    const {data} = await getDataGroups()
    setDataGroups(data)
  }

  useEffect(() => {
    form.setFieldsValue({parentId: id ? +id : null})
    onFetchDataGroups()
    type !== 'add' ? setContent(form.getFieldValue('content')) : form.setFieldValue('content', '')
  }, [record, id])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item
        label="폴더명"
        name="name"
        rules={[
          {required: true, message: '제목을 입력해주세요'},
          {min: 4, max: 100, message: '4~100자 이내로 입력해주세요'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="parentId" label="상위 폴더">
        <Select allowClear>
          {dataGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            // .filter(({data}) => !data.length)
            .map((dataGroup: any) => {
              let folderPathArr: any[] = [dataGroup]
              let targetFolder = dataGroup

              while (1) {
                if (targetFolder.parentId) {
                  targetFolder = dataGroups.find(({id}) => id === targetFolder.parentId)
                  if (targetFolder) folderPathArr = [targetFolder, ...folderPathArr]
                  else break
                } else break
              }

              return (
                <Select.Option key={dataGroup.id} value={dataGroup.id}>
                  {folderPathArr.map(({name, id}) => (
                    <Space>
                      {`${name}(${id})`}
                      {id !== dataGroup.id && <RightOutlined />}
                    </Space>
                  ))}
                </Select.Option>
              )
            })}
        </Select>
      </Form.Item>

      <Form.Item label="순서" name="sortOrder" rules={[{required: true, message: '순서를 입력해주세요'}]}>
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

const DataGroupModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '자료실 폴더', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [content, setContent] = useState('')

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchDataGroups) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values

        if (id) {
          await patchDataGroup(id, {...rest})
        } else {
          await postDataGroups({...rest})
        }

        onAction(type, values)
      } catch (e: any) {
        if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteDataGroup(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit
              content={content}
              setContent={setContent}
              type={type}
              form={form}
              record={record}
              handleFinish={(values) => handleFinish(type, values)}
            />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default DataGroupModal

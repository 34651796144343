import React from 'react'
import qs from 'query-string'
import {useLocation} from 'react-router'
import {Navigate} from 'react-router-dom'
import {PageProvider} from '../contexts/PageContext'

export default function withPageContext(WrappedComponent: any, api?: any, pagination = true, defaultParams = {}) {
  return (props: any) => {
    const location = useLocation()
    const query: any = qs.parse(location.search)

    if (pagination && (!query.page || query.page <= 0 || !query.pageSize || query.pageSize <= 0)) {
      query.page = 1
      query.pageSize = 50
      return <Navigate to={{pathname: location.pathname, search: qs.stringify({...query, ...defaultParams})}} />
    }
    return (
      <PageProvider value={{api: api || props.api, params: {...query, ...defaultParams}}}>
        <WrappedComponent {...props} />
      </PageProvider>
    )
  }
}

import api from './index'

export interface IOutput {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostOutputs extends Omit<IOutput, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchOutputs extends IPostOutputs {}

interface IGetOutputsParams {
  start: number
  perPage: number
  search?: string
}

export async function postOutput(data: IPostOutputs): Promise<{id: number}> {
  try {
    const res = await api.post(`/outputs`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOutputs(
  params: IGetOutputsParams = {start: 0, perPage: 1000}
): Promise<{data: IOutput[]; total: number}> {
  try {
    const res = await api.get(`/outputs`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOutput(id: number): Promise<IOutput> {
  try {
    const res = await api.get(`/outputs/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchOutput(id: number, data: IPatchOutputs): Promise<{id: number}> {
  try {
    const res = await api.patch(`/outputs/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteOutput(id: number): Promise<void> {
  try {
    const res = await api.delete(`/outputs/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import {RouteObject} from 'react-router'
import Audiograms from '../pages/console/iteOption/audiograms'
import Bents from '../pages/console/iteOption/bents'
import BentStyles from '../pages/console/iteOption/bentStyles'
import CanalsLength from '../pages/console/iteOption/canalsLength'
import Coatings from '../pages/console/iteOption/coatings'
import EarwaxOptions from '../pages/console/iteOption/earwaxOptions'
import Handles from '../pages/console/iteOption/handles'
import IteOptions from '../pages/console/iteOption/iteOptions'
import Outputs from '../pages/console/iteOption/outputs'
import Plates from '../pages/console/iteOption/plates'
import ShellColor from '../pages/console/iteOption/shellColor'
import ShellSizes from '../pages/console/iteOption/shellSizes'
import ShellTypes from '../pages/console/iteOption/shellTypes'
import Wireless from '../pages/console/iteOption/wireless'
import VolumeControllers from '../pages/console/iteOption/volumeControllers'
import ProgramButtons from '../pages/console/iteOption/programButtons'

export const iteRoute: RouteObject[] = [
  {
    path: '/console/iteOption/audiograms',
    element: <Audiograms />
  },
  {
    path: '/console/iteOption/bents',
    element: <Bents />
  },
  {
    path: '/console/iteOption/bentStyles',
    element: <BentStyles />
  },
  {
    path: '/console/iteOption/canalsLength',
    element: <CanalsLength />
  },
  {
    path: '/console/iteOption/coatings',
    element: <Coatings />
  },
  {
    path: '/console/iteOption/earwaxOptions',
    element: <EarwaxOptions />
  },
  {
    path: '/console/iteOption/handles',
    element: <Handles />
  },
  {
    path: '/console/iteOption/outputs',
    element: <Outputs />
  },
  {
    path: '/console/iteOption/plates',
    element: <Plates />
  },
  {
    path: '/console/iteOption/shellColor',
    element: <ShellColor />
  },
  {
    path: '/console/iteOption/shellSizes',
    element: <ShellSizes />
  },
  {
    path: '/console/iteOption/shellTypes',
    element: <ShellTypes />
  },
  {
    path: '/console/iteOption/iteOptions',
    element: <IteOptions />
  },
  {
    path: '/console/iteOption/wireless',
    element: <Wireless />
  },
  {
    path: '/console/iteOption/volumeControllers',
    element: <VolumeControllers />
  },
  {
    path: '/console/iteOption/programButtons',
    element: <ProgramButtons />
  }
]

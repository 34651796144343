import {LogoutOutlined, UserOutlined} from '@ant-design/icons'
import {Button, Menu, Spin} from 'antd'
import React from 'react'
import HeaderDropdown from './HeaderDropdown'
import {deleteAuth} from '../../../api/auth'
import {useRecoilState} from 'recoil'
import {userState} from '../../../states/user.state'
import styled from 'styled-components'

const MenuWrapper = styled(Menu)`
  :global(.anticon) {
    margin-right: 8px;
  }

  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
`

export default function AvatarDropdown() {
  const [user, setUser] = useRecoilState(userState)

  async function onMenuClick(e: {key: any}) {
    const {key} = e
    if (key === 'logout') {
      await deleteAuth()
      setUser(false)
    }
  }

  const menuHeaderDropdown = (
    <MenuWrapper
      items={[
        {
          label: '로그아웃',
          key: 'logout',
          icon: <LogoutOutlined />
        }
      ]}
      selectedKeys={[]}
      onClick={onMenuClick}
    >
      {/*{user && (*/}
      {/*  <Menu.Item key="center">*/}
      {/*    <UserOutlined />*/}
      {/*    정보조회*/}
      {/*  </Menu.Item>*/}
      {/*)}*/}
      {/*{user && <Menu.Divider />}*/}
    </MenuWrapper>
  )
  return (
    <>
      {typeof user !== 'boolean' ? (
        <HeaderDropdown overlay={menuHeaderDropdown} trigger={['click']}>
          <Button shape="round" type="primary" htmlType="button" style={{boxShadow: '2px 0 8px 0 rgb(29 35 41 / 5%)'}}>
            <UserOutlined />
            {user?.name}
          </Button>
        </HeaderDropdown>
      ) : (
        <span className="action account">
          <Spin
            size="small"
            style={{
              marginLeft: 8,
              marginRight: 8
            }}
          />
        </span>
      )}
    </>
  )
}

import api from './index'

export interface ISegment {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostSegments extends Omit<ISegment, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchSegments extends IPostSegments {}

interface IGetSegmentsParams {
  start: number
  perPage: number
  search?: string
}

export async function postSegment(data: IPostSegments): Promise<{id: number}> {
  try {
    const res = await api.post(`/segments`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSegments(
  params: IGetSegmentsParams = {start: 0, perPage: 1000}
): Promise<{data: ISegment[]; total: number}> {
  try {
    const res = await api.get(`/segments`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSegment(id: number): Promise<ISegment> {
  try {
    const res = await api.get(`/segments/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchSegment(id: number, data: IPatchSegments): Promise<{id: number}> {
  try {
    const res = await api.patch(`/segments/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteSegment(id: number): Promise<void> {
  try {
    const res = await api.delete(`/segments/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

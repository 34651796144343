import api from './index'

export interface ISalesPlatform {
  id: number
  name: string
  korean: string
  titleImage: string
  seq: number
  hidden: boolean
  createdAt: string
  updatedAt: string
  subName: string
  displaySubName: string
}

export interface IPostSalesPlatforms extends Omit<ISalesPlatform, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchSalesPlatforms extends IPostSalesPlatforms {}

interface IGetSalesPlatformsParams {
  start: number
  perPage: number
  search?: string
}

export async function postSalesPlatform(data: IPostSalesPlatforms): Promise<{id: number}> {
  try {
    const res = await api.post(`/salesPlatforms`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSalesPlatforms(
  params: IGetSalesPlatformsParams = {start: 0, perPage: 1000}
): Promise<{data: ISalesPlatform[]; total: number}> {
  try {
    const res = await api.get(`/salesPlatforms`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSalesPlatform(id: number): Promise<ISalesPlatform> {
  try {
    const res = await api.get(`/salesPlatforms/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchSalesPlatform(id: number, data: IPatchSalesPlatforms): Promise<{id: number}> {
  try {
    const res = await api.patch(`/salesPlatforms/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteSalesPlatform(id: number): Promise<void> {
  try {
    const res = await api.delete(`/salesPlatforms/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import dayjs from 'dayjs'
import PageContext from '../../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import VendorGroupModal from './components/VendorGroupModal'
import {getVendorGroups} from '../../../../api/vendor-groups'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {Button} from 'antd'

function Users() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {key: 'name', dataIndex: 'name', title: '이름'},
    {dataIndex: 'accountId', title: 'ID'},
    {dataIndex: 'address', title: '주소'},
    {dataIndex: 'tel', title: '전화번호'},
    {dataIndex: 'phone', title: '휴대폰 번호'},
    {dataIndex: 'status', title: '상태', render: (status) => (status ? <CheckOutlined /> : <CloseOutlined />)},
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  return (
    <>
      <BasicTable
        showSearch
        rowKey="id"
        columns={columns}
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            업체 등록
          </Button>
        ]}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'delete', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <VendorGroupModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(Users, getVendorGroups)

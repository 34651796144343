import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Divider, Form, Input, InputNumber, Modal, Select, Switch, Table, Typography} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {getVendors, IVendor} from '../../../../../api/vendors'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {
  deleteVendorGroup,
  getVendorGroup,
  IPatchVendorGroups,
  IPostVendorGroups,
  IVendorGroup,
  patchVendorGroup,
  postVendorGroup
} from '../../../../../api/vendor-groups'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IVendorGroup>
  onAction: (type: ActionType, record: Partial<IVendorGroup>) => void | Promise<void>
}

interface IVendorInGroup {
  id?: number
  vendorId?: number
  name?: string
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

const error400Guide = {
  name: {name: '이름', message: '1 ~ 40자 이내로 입력하세요.'},
  nickname: {name: '닉네임', message: '1 ~ 40자 이내로 입력하세요.'},
  englishName: {name: '영문명', message: '1 ~ 100자 이내의 숫자로 입력하세요.'},
  tel: {name: '전화번호', message: '8 ~ 11자 이내의 숫자로 하이픈(-) 없이 입력하세요.'},
  phone: {name: '휴대폰 번호', message: '01X-XXX(X)-XXXX 형태로 입력해주세요.'},
  unknownProp: {name: '알 수 없음', message: '특정 속성의 입력이 올바르지 않습니다. 관리자에게 문의하세요.'}
}

function Show({record}: {record: IVendorGroup}) {
  const [vendorInGroups, setVendorInGroups] = useState<IVendorInGroup[]>([])

  const columns = [
    {key: 'vendorId', dataIndex: 'vendorId', title: '업체 번호'},
    {key: 'name', dataIndex: 'name', title: '이름'}
  ]

  async function handleLoad() {
    const {vendors} = await getVendorGroup(record.id)
    vendors && setVendorInGroups(vendors)
  }

  useEffect(() => {
    handleLoad()
  }, [])

  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="ID">{record.accountId}</Descriptions.Item>
        <Descriptions.Item label="주소">{record.address}</Descriptions.Item>
        <Descriptions.Item label="전화번호">{record.tel}</Descriptions.Item>
        <Descriptions.Item label="휴대폰 번호">{record.phone}</Descriptions.Item>
        <Descriptions.Item label="상태">{record.status ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Typography.Title level={4}>소속 업체 목록</Typography.Title>
      <Table dataSource={vendorInGroups} columns={columns} />
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  const [vendors, setVendors] = useState<IVendor[]>([])

  useEffect(() => {
    if (form) {
      handleGetVendorGroup()
      handleGetVendors()
    }
  }, [form, record])

  async function handleGetVendorGroup() {
    const data = await getVendorGroup(record.id)
    const vendors = data.vendors.map((vendor) => vendor.vendorId)
    form.setFieldsValue({...data, vendors})
  }

  async function handleGetVendors() {
    const {data} = await getVendors()
    setVendors(data)
  }

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item
        label="ID"
        name="accountId"
        rules={[
          {required: true, message: 'ID를 입력해주세요'},
          {type: 'string', min: 1, max: 40, message: '1 ~ 40자 이내로 입력해주세요.'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="이름"
        name="name"
        rules={[
          {required: true, message: '이름를 입력해주세요'},
          {type: 'string', min: 1, max: 40, message: '1 ~ 40자 이내로 입력해주세요.'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="영문명"
        name="englishName"
        rules={[
          {required: true, message: '이름를 입력해주세요'},
          {type: 'string', min: 1, max: 100, message: '1 ~ 100자 이내로 입력해주세요.'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="대표명"
        name="representativeName"
        rules={[
          {required: true, message: '이름를 입력해주세요'},
          {type: 'string', min: 1, max: 40, message: '1 ~ 40자 이내로 입력해주세요.'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="전화번호" name="tel">
        <Input />
      </Form.Item>

      <Form.Item label="휴대폰 번호" name="phone">
        <Input />
      </Form.Item>

      <Form.Item label="소속 업체" name="vendors">
        <Select mode="multiple">
          {vendors
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((vendor) => (
              <Select.Option key={vendor.id} value={vendor.name}>
                {vendor.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="코드" name="code" rules={[{required: true, message: '코드를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="상태"
        name="status"
        valuePropName="checked"
        rules={[{required: true, message: '상태를 체크해주세요'}]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="사업자등록번호"
        name="businessNumber"
        rules={[{required: true, message: '사업자등록번호를 입력해주세요'}]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="채널" name="channel" rules={[{required: true, message: '채널을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="영문 채널"
        name="englishChannel"
        rules={[{required: true, message: '영문 채널을 입력해주세요'}]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="브랜드" name="brand" rules={[{required: true, message: '브랜드를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="등급" name="grade" rules={[{required: true, message: '등급을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="SLP명" name="SLPNAME" rules={[{required: true, message: 'SLP명을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="이메일" name="email" rules={[{required: true, message: '이메일을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="주소" name="address" rules={[{required: true, message: '주소를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="대지역" name="largeCategory" rules={[{required: true, message: '대지역를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="중지역" name="mediumCategory" rules={[{required: true, message: '중지역를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="소지역" name="smallCategory" rules={[{required: true, message: '소지역를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="계정 잔액"
        name="accountBalance"
        rules={[{required: true, message: '계정 잔엑을 입력해주세요'}]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="가상 계좌"
        name="virtualAccount"
        rules={[{required: true, message: '가상 계좌을 입력해주세요'}]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="신용한도" name="creditLimit" rules={[{required: true, message: '신용한도를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="결제 기간" name="paymentTerm" rules={[{required: true, message: '결제 기간을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="사업 카테고리"
        name="businessCategory"
        rules={[{required: true, message: '사업 카테고리를 입력해주세요'}]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="활성화" name="enabled" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  )
}

const VendorGroupModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '업체 그룹 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IVendorGroup) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        let {
          id,
          name,
          englishName,
          representativeName,
          tel,
          phone,
          vendors,
          status,
          businessNumber,
          channel,
          englishChannel,
          brand,
          grade,
          SLPNAME,
          email,
          accountBalance,
          virtualAccount,
          creditLimit,
          paymentTerm,
          businessCategory,
          code,
          address,
          largeCategory,
          mediumCategory,
          smallCategory,
          enabled,
          ...rest
        } = values
        const {data: vendorsData} = await getVendors()
        if (id) {
          if (typeof status !== 'boolean') status = false
          const data: IPatchVendorGroups = {
            name,
            englishName,
            representativeName,
            tel,
            phone,
            vendors: vendors.map((vendorName) => ({
              vendorId: vendorsData.find((vendor) => vendor.name === vendorName)?.id
            })),
            status,
            businessNumber,
            channel,
            englishChannel,
            brand,
            grade,
            SLPNAME,
            email,
            accountBalance,
            virtualAccount,
            creditLimit,
            paymentTerm,
            businessCategory,
            code,
            address,
            largeCategory,
            mediumCategory,
            smallCategory,
            enabled
          }
          await patchVendorGroup(id, {...rest, ...data})
        } else {
          const data: IPostVendorGroups = {
            name,
            englishName,
            representativeName,
            tel,
            phone,
            vendors: vendors.map((vendorName) => ({
              vendorId: vendorsData.find((vendor) => vendor.name === vendorName)?.id
            })),
            status,
            businessNumber,
            channel,
            englishChannel,
            brand,
            grade,
            SLPNAME,
            email,
            accountBalance,
            virtualAccount,
            creditLimit,
            paymentTerm,
            businessCategory,
            code,
            address,
            largeCategory,
            mediumCategory,
            smallCategory,
            enabled
          }
          await postVendorGroup({...rest, ...data})
        }
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 400) {
            const propTitles = e.response.data.message.split(', ').map((message) => message.split(' ')[0])
            Modal.error({
              title: 'Error 400',
              content: propTitles.map((title) =>
                Object.keys(error400Guide).includes(title) ? (
                  <div>{`${error400Guide[title].name}(이)가 잘못 입력되었습니다. ${error400Guide[title].message}`}</div>
                ) : (
                  <div>{error400Guide.unknownProp.message}</div>
                )
              )
            })
          } else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete') {
      await deleteVendorGroup(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit type={type} form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default VendorGroupModal

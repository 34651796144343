/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Button, Descriptions, Form, Input, InputNumber, Modal, Select, Space, Switch, Tag, Upload} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {CheckOutlined, CloseOutlined, FileOutlined, RightOutlined, UploadOutlined} from '@ant-design/icons'
import {uploadImage} from '../../../../../libs/uploadImage'
import {deleteData, IData, IPatchData, patchData, postData} from '../../../../../api/datas'
import {getProductBrands, IProductBrand} from '../../../../../api/productBrands'
import ImageEditor from '../../../../../components/Editor/ImageEditor'
import styled from 'styled-components'
import {getFileCheck} from '../../../../../api/files'
import {getDataGroups, IDataGroup} from '../../../../../api/dataGroups'
import {useParams} from 'react-router'
import downloadFile from '../../../../../libs/downloadFile'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPatchData>
  onAction: (type: ActionType, record: Partial<IPatchData>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: {record: IData}) {
  const [fileNames, setFileNames] = useState<string[]>([])

  async function importFileNames() {
    const fileNames: string[] = []
    for (const path of record.files) {
      const slashSplitedPath = path.split('/')
      const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
      fileNames.push(fileName)
    }
    setFileNames(fileNames)
  }

  useEffect(() => {
    importFileNames()
  }, [])

  return (
    <div style={{padding: '0 28px 28px 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="제목">{record.title}</Descriptions.Item>
        <Descriptions.Item label="첨부파일">
          {record.files &&
            !!record.files &&
            record.files.map((file, index) => {
              const splitedFileUrl = file.split('/')
              const fileNameByPath = splitedFileUrl[splitedFileUrl.length - 1]
              return (
                <>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      downloadFile(file, fileNames[index] || fileNameByPath)
                    }}
                  >
                    <FileOutlined />
                    {fileNames[index] || fileNameByPath}
                  </a>
                  <br />
                </>
              )
            })}
        </Descriptions.Item>
        {record.brands && !!record.brands.length && (
          <Descriptions.Item label="대상 브랜드">
            {record.brands.map((brand) => (
              <Tag>{brand.name}</Tag>
            ))}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="내용">
          <HtmlContainer dangerouslySetInnerHTML={{__html: record.content}} />
        </Descriptions.Item>
        <Descriptions.Item label="중요 여부">
          {record.isFixation ? <CheckOutlined /> : <CloseOutlined />}
        </Descriptions.Item>
        <Descriptions.Item label="정렬 순서">{record.sortOrder || '-'}</Descriptions.Item>
        <Descriptions.Item label="등록일">
          {dayjs(record.createdAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="수정일">
          {dayjs(record.updatedAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({content, setContent, type, form, record, handleFinish}) {
  const [brands, setBrands] = useState<IProductBrand[]>([])
  const [dataGroups, setDataGroups] = useState<IDataGroup[]>([])
  const [parentMap, setParentMap] = useState<any>({})
  const {id} = useParams()

  async function onFetchBrands() {
    const {data} = await getProductBrands()
    setBrands(data)
  }

  async function onFetchDataGroups() {
    const {data} = await getDataGroups()
    const parentMap = {}

    data.forEach((obj) => {
      if (parentMap[obj.id]) {
        obj.children = parentMap[obj.id].children || []
        parentMap[obj.id] = {...obj}
      } else {
        parentMap[obj.id] = obj
      }
    })

    Object.values(parentMap).forEach((obj: any) => {
      if (obj.parentId && parentMap[obj.parentId]) {
        parentMap[obj.parentId].children = parentMap[obj.parentId].children || []
        parentMap[obj.parentId].children.push(obj)
      }
    })

    setParentMap(parentMap)
    setDataGroups(data)
  }

  function handleChangeBefore(file) {
    if (file.size >= 2000000000) {
      Modal.error({content: `${file.name}은 파일이 2GB를 초과하기 때문에 업로드가 불가능합니다.`})
      return Upload.LIST_IGNORE
    }
    return true
  }

  function handleChange({fileList, file}) {
    file.status = 'done'
    form.setFieldValue(
      'files',
      fileList.map(({originFileObj}) => originFileObj)
    )
  }

  useEffect(() => {
    form.setFieldsValue({dataGroupId: id ? +id : null})
    onFetchBrands()
    onFetchDataGroups()
    type !== 'add' ? setContent(form.getFieldValue('content')) : form.setFieldValue('content', '')
  }, [record, id])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item name="dataGroupId" label="업로드 할 폴더">
        <Select>
          {dataGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            // .filter(({id}) => !parentMap[`${id}`].children)
            .map((dataGroup: any) => {
              let folderPathArr: any[] = [dataGroup]
              let targetFolder = dataGroup

              while (1) {
                if (targetFolder.parentId) {
                  targetFolder = dataGroups.find(({id}) => id === targetFolder.parentId)
                  if (targetFolder) folderPathArr = [targetFolder, ...folderPathArr]
                  else break
                } else break
              }

              return (
                <Select.Option key={dataGroup.id} value={dataGroup.id}>
                  {folderPathArr.map(({name, id}) => (
                    <Space>
                      {`${name}(${id})`}
                      {id !== dataGroup.id && <RightOutlined />}
                    </Space>
                  ))}
                </Select.Option>
              )
            })}
        </Select>
      </Form.Item>

      <Form.Item
        label="제목"
        name="title"
        rules={[
          {required: true, message: '제목을 입력해주세요'},
          {min: 4, max: 100, message: '4~100자 이내로 입력해주세요'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="대상 브랜드" name="brands">
        <Select mode="multiple">
          {brands
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((brand: any) => (
              <Select.Option key={brand.id} value={JSON.stringify(brand)}>
                {brand.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="중요 여부" name="isFixation" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="내용" name="content" rules={[{required: true, message: '내용을 입력해주세요'}]}>
        <ImageEditor
          data={content}
          onChange={(e: React.ChangeEvent, editor: object | any) => setContent(editor.getData())}
        />
      </Form.Item>

      <Form.Item label="첨부파일 (2GB 이하)" name="files">
        <Upload multiple onChange={handleChange} beforeUpload={handleChangeBefore}>
          <Button icon={<UploadOutlined />} shape="round">
            업로드
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item label="순서" name="sortOrder" rules={[{required: true, message: '순서를 입력해주세요'}]}>
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

const DataModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '자료실 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [content, setContent] = useState('')

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchData) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, files, ...rest} = values

        if (Array.isArray(files)) {
          for (let i = 0; i < files.length; i++) {
            files[i] = await uploadImage(files[i])
          }
        }

        if (rest.brands)
          rest.brands = rest.brands.map((brand) => (typeof brand === 'string' ? JSON.parse(brand) : brand))
        else rest.brands = []

        if (id) {
          await patchData(id, {files, ...rest, content})
        } else {
          await postData({files, ...rest, content})
        }

        onAction(type, values)
      } catch (e: any) {
        if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteData(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit
              content={content}
              setContent={setContent}
              type={type}
              form={form}
              record={record}
              handleFinish={(values) => handleFinish(type, values)}
            />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const HtmlContainer = styled.div`
  img {
    max-width: 480px;
  }
`

export default DataModal

import api from './index'

export interface IEarwaxOption {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostEarwaxOptions extends Omit<IEarwaxOption, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchEarwaxOptions extends IPostEarwaxOptions {}

interface IGetEarwaxOptionsParams {
  start: number
  perPage: number
  search?: string
}

export async function postEarwaxOption(data: IPostEarwaxOptions): Promise<{id: number}> {
  try {
    const res = await api.post(`/earwaxOptions`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getEarwaxOptions(
  params: IGetEarwaxOptionsParams = {start: 0, perPage: 1000}
): Promise<{data: IEarwaxOption[]; total: number}> {
  try {
    const res = await api.get(`/earwaxOptions`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getEarwaxOption(id: number): Promise<IEarwaxOption> {
  try {
    const res = await api.get(`/earwaxOptions/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchEarwaxOption(id: number, data: IPatchEarwaxOptions): Promise<{id: number}> {
  try {
    const res = await api.patch(`/earwaxOptions/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteEarwaxOption(id: number): Promise<void> {
  try {
    const res = await api.delete(`/earwaxOptions/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

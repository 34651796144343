import React, {useEffect, useRef, useState} from 'react'
import * as CustomEditor from 'ckeditor5-custom-build/build/ckeditor'
import styled from 'styled-components'

export default function Editor({config, onChange, data}: IEditorProps) {
  const editorRef = useRef<object>()
  const {CKEditor}: any = editorRef.current || {}
  const [editorLoaded, setEditorLoaded] = useState(false)

  useEffect(() => {
    editorRef.current = {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor //Added .CKEditor
    }
    setEditorLoaded(true)
  }, [])

  return editorLoaded ? (
    <EditorArea>
      <CKEditor editor={CustomEditor} onChange={onChange} config={config} data={data} />
    </EditorArea>
  ) : (
    <></>
  )
}

const EditorArea = styled.div`
  .ck-editor__editable_inline {
    max-height: 700px;
  }
`

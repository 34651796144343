import api from './index'
import {IImageCommon} from './productCategories'

export interface INotice {
  id: number
  title: string
  files: string[]
  content: string
  brands: IImageCommon[] | string[]
  isFixation: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostNotices extends Omit<INotice, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchNotice extends IPostNotices {
  id?: number
}

interface IGetNoticeParams {
  start: number
  perPage: number
}

export async function postNotice(data: IPostNotices): Promise<{id: number}> {
  try {
    const res = await api.post(`/notices`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getNotices(
  params: IGetNoticeParams = {start: 0, perPage: 1000}
): Promise<{data: INotice[]; total: number}> {
  try {
    const res = await api.get(`/notices`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getNotice(id: number): Promise<INotice> {
  try {
    const res = await api.get(`/notices/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchNotice(id: number, data: IPatchNotice): Promise<{id: number}> {
  try {
    const res = await api.patch(`/notices/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteNotice(id: number): Promise<void> {
  try {
    const res = await api.delete(`/notices/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IProductGrade {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductGrades extends Omit<IProductGrade, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductGrades extends IPostProductGrades {}

interface IGetProductGradesParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductGrade(data: IPostProductGrades): Promise<{id: number}> {
  try {
    const res = await api.post(`/productGrade`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductGrades(
  params: IGetProductGradesParams = {start: 0, perPage: 1000}
): Promise<{data: IProductGrade[]; total: number}> {
  try {
    const res = await api.get(`/productGrade`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductGrade(id: number): Promise<IProductGrade> {
  try {
    const res = await api.get(`/productGrade/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductGrade(id: number, data: IPatchProductGrades): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productGrade/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductGrade(id: number): Promise<void> {
  try {
    const res = await api.patch(`/productGrade/${id}`, {hidden: true})
    return res.data
  } catch (e) {
    throw e
  }
}

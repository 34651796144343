import api from './index'
import {IImageCommon} from './productCategories'

export interface IData {
  id: number
  title: string
  files: string[]
  content: string
  brands: IImageCommon[]
  isFixation: boolean
  sortOrder: number
  createdAt: string
  updatedAt: string
}

export interface IPostDatas extends Omit<IData, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchData extends IPostDatas {
  id?: number
}

interface IGetDataParams {
  start: number
  perPage: number
}

export async function postData(data: IPostDatas): Promise<{id: number}> {
  try {
    const res = await api.post(`/datas`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDatas(
  params: IGetDataParams = {start: 0, perPage: 1000}
): Promise<{data: IData[]; total: number}> {
  try {
    const res = await api.get(`/datas`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getData(id: number): Promise<IData> {
  try {
    const res = await api.get(`/datas/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchData(id: number, data: IPatchData): Promise<{id: number}> {
  try {
    const res = await api.patch(`/datas/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteData(id: number): Promise<void> {
  try {
    const res = await api.delete(`/datas/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React from 'react'
import {BrowserRouter, useRoutes} from 'react-router-dom'
import {authRoute} from './auth.route'
import {errorRoute} from './error.route'
import {indexRoute} from './index.route'
import withInitialize from '../hocs/withInitialize'
import {userRoute} from './user.route'
import BasicLayout from '../layouts/BasicLayout'
import {priceRoute} from './price.route'
import {productRoute} from './product.route'
import {etcRoute} from './etc.route'
import {iteRoute} from './iteOption.route'

export const routes = [
  ...authRoute,
  ...indexRoute,
  {
    path: '/console',
    element: <BasicLayout />,
    children: [...userRoute, ...priceRoute, ...productRoute, ...iteRoute, ...etcRoute]
  },
  ...errorRoute
]

function Routes() {
  return useRoutes(routes)
}

function DefaultRoutes() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default withInitialize(DefaultRoutes)

import {RouteObject} from 'react-router'
import Administrators from '../pages/console/user/administrators'
import VendorGroups from '../pages/console/user/vendorGroups'
import Vendors from '../pages/console/user/vendors'

export const userRoute: RouteObject[] = [
  {
    path: '/console/user/administrators',
    element: <Administrators />
  },
  {
    path: '/console/user/vendors',
    element: <Vendors />
  },
  {
    path: '/console/user/vendorGroups',
    element: <VendorGroups />
  }
]

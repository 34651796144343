import {Card, Image, Layout, Typography} from 'antd'
import React from 'react'
import styled from 'styled-components'

const {Content} = Layout

const Box = styled.div`
  width: 100vh;
`

type AuthLayoutProps = {
  title: string
}

export default function AuthLayout(props: React.PropsWithChildren<AuthLayoutProps>) {
  return (
    <Layout
      style={{
        minHeight: '100vh',
        minWidth: 1280,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '2px 0 8px 0 rgb(29 35 41 / 5%)'
      }}
    >
      <StyledCard
        style={{
          width: '1000px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '10px',
          background: 'transparent'
        }}
      >
        <StyledCard
          style={{
            width: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: process.env.REACT_APP_THEME_COLOR,
            borderRadius: '10px 0 0 10px'
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Image preview={false} src="/logoTransparentBg.png" width="180px" />
            <Typography.Title level={2} style={{color: 'white', margin: '0 35px 14px -23px'}}>
              Master Admin
            </Typography.Title>
          </div>
        </StyledCard>
        <StyledCard
          style={{
            width: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0 10px 10px 0'
          }}
        >
          <Content>
            <div>
              <Box>
                <Typography.Title level={2} style={{width: '400px', color: process.env.REACT_APP_THEME_COLOR}}>
                  {props.title}
                </Typography.Title>
                {props.children}
              </Box>
            </div>
          </Content>
        </StyledCard>
      </StyledCard>
    </Layout>
  )
}

const StyledCard = styled(Card)`
  height: 500px;
  padding: 0;

  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .ant-layout-content > div > div {
    width: 100%;
  }
`

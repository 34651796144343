import api from './index'

export interface IBent {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostBent extends Omit<IBent, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchBent extends IPostBent {}

interface IGetBentParams {
  start: number
  perPage: number
  search?: string
}

export async function postBent(data: IPostBent): Promise<{id: number}> {
  try {
    const res = await api.post(`/bents`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getBents(
  params: IGetBentParams = {start: 0, perPage: 1000}
): Promise<{data: IBent[]; total: number}> {
  try {
    const res = await api.get(`/bents`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getBent(id: number): Promise<IBent> {
  try {
    const res = await api.get(`/bents/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchBent(id: number, data: IPatchBent): Promise<{id: number}> {
  try {
    const res = await api.patch(`/bents/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteBent(id: number): Promise<void> {
  try {
    const res = await api.delete(`/bents/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import api from './index'

export interface IDeliveryTime {
  id: number
  departure: string
  closeTime: number
  createdAt: string
  updatedAt: string
}

export interface IPostDeliveryTime {
  departure: string
  closeTime: number
}

export interface IPatchDeliveryTime {
  departure?: string
  closeTime?: number
}

interface IGetDeliveryTimeParams {
  start: number
  perPage: number
  search?: string
}

export async function postDeliveryTime(data: IPostDeliveryTime): Promise<{id: number}> {
  try {
    const res = await api.post(`/deliveryTime`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDeliveryTimes(
  params: IGetDeliveryTimeParams = {start: 0, perPage: 1000}
): Promise<{data: IDeliveryTime[]; total: number}> {
  try {
    const res = await api.get(`/deliveryTime`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDeliveryTime(id: number): Promise<IDeliveryTime> {
  try {
    const res = await api.get(`/deliveryTime/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchDeliveryTime(id: number, data: IPatchDeliveryTime): Promise<{id: number}> {
  try {
    const res = await api.patch(`/deliveryTime/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteDeliveryTime(id: number): Promise<void> {
  try {
    const res = await api.delete(`/deliveryTime/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

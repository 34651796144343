import api from './index'

export interface IProductReceiverDirection {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostProductReceiverDirection
  extends Omit<IProductReceiverDirection, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchProductReceiverDirection extends IPostProductReceiverDirection {}

interface IGetProductReceiverDirectionParams {
  start: number
  perPage: number
  search?: string
}

export async function postProductReceiverDirection(data: IPostProductReceiverDirection): Promise<{id: number}> {
  try {
    const res = await api.post(`/productReceiverDirection`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductReceiverDirections(
  params: IGetProductReceiverDirectionParams = {start: 0, perPage: 1000}
): Promise<{data: IProductReceiverDirection[]; total: number}> {
  try {
    const res = await api.get(`/productReceiverDirection`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductReceiverDirection(id: number): Promise<IProductReceiverDirection> {
  try {
    const res = await api.get(`/productReceiverDirection/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProductReceiverDirection(
  id: number,
  data: IPatchProductReceiverDirection
): Promise<{id: number}> {
  try {
    const res = await api.patch(`/productReceiverDirection/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProductReceiverDirection(id: number): Promise<void> {
  try {
    const res = await api.delete(`/productReceiverDirection/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {Descriptions, Form, Input, InputNumber, Modal, Select, Switch} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {
  deleteSalesProductDetail,
  ISalesProductDetail,
  patchSalesProductDetail,
  postSalesProductDetail
} from '../../../../../api/salesProductDetails'
import Upload from '../../../../../components/Upload'
import ImageView from '../../../../../components/ImageView'
import {uploadImage} from '../../../../../libs/uploadImage'
import {getSalesProducts, ISalesProduct} from '../../../../../api/salesProducts'

interface IModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<ISalesProductDetail>
  onAction: (type: ActionType, record: Partial<ISalesProductDetail>) => void | Promise<void>
}

interface IShowProps {
  record: ISalesProductDetail
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Show({record}: IShowProps) {
  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="한국어명">{record.korean}</Descriptions.Item>
        <Descriptions.Item label="판매 제품">{record?.productSalesProduct?.name}</Descriptions.Item>
        <Descriptions.Item label="대표 이미지">
          <ImageView src={record.titleImage} height={100} />
        </Descriptions.Item>
        <Descriptions.Item label="순서">{record.seq}</Descriptions.Item>
        <Descriptions.Item label="숨기기">{record.hidden ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish}) {
  const [salesProductList, setSalesProductList] = useState<ISalesProduct[]>([])

  async function onFetch() {
    try {
      const {data} = await getSalesProducts({start: 0, perPage: 1000})
      setSalesProductList(data)
      record.productIds = record.products.map(({id}) => id)
    } catch (e: any) {
      Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
    }
  }

  useEffect(() => {
    if (form && record) {
      onFetch()
      form.setFieldsValue({...record})
    }
  }, [form, record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item label="이름" name="name" rules={[{required: true, message: '이름을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="한국어명" name="korean">
        <Input />
      </Form.Item>

      <Form.Item
        label="판매 상품"
        name="productSalesProductId"
        rules={[{required: true, message: '판매 상품을 선택해주세요'}]}
      >
        <Select
          filterOption={(inputVal, option) => option?.title.toLowerCase().includes(inputVal.toLowerCase())}
          allowClear
        >
          {salesProductList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((accItem) => (
              <Select.Option key={accItem.id} value={accItem.id} title={accItem.name}>
                {accItem.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="대표 이미지" name="titleImage">
        <Upload max={1} title="titleImage" />
      </Form.Item>

      <Form.Item label="순서" name="seq" rules={[{required: true, message: '순서을 입력해주세요'}]}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="숨기기" name="hidden" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  )
}

const SalesProductDetailModal = forwardRef<BasicTableModalRef, IModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '판매 제품 상세 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: ISalesProductDetail) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, ...rest} = values

        if (rest?.titleImage?.length) rest.titleImage = await uploadImage(rest.titleImage)

        if (id) {
          await patchSalesProductDetail(id, {...rest})
        } else {
          await postSalesProductDetail({...rest} as any)
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response && e.response.data.message === 'already_in_use') {
          Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
        } else if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteSalesProductDetail(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <>
      <BasicTableModal
        ref={ref}
        actions={actions}
        title={title}
        form={form}
        width={800}
        loading={loading}
        onAction={handleAction}
        render={(type, record) => {
          if (type === 'add' || type === 'edit')
            return (
              <AddOrEdit
                type={type}
                form={form}
                record={record}
                handleFinish={(values) => handleFinish(type, values)}
              />
            )
          return (
            <Form form={form}>
              <Show record={record} />
            </Form>
          )
        }}
      />
    </>
  )
})

export default SalesProductDetailModal

import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  DatePicker
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import {CheckOutlined, CloseOutlined, PlusOutlined} from '@ant-design/icons'
import {
  deleteCoupon,
  ICoupon,
  IPutCouponBundle,
  IPutCouponPrice,
  IPutCouponSpecial,
  putCouponBundle,
  putCouponPrice,
  putCouponSpecial,
  postCouponBundle,
  postCouponPrice,
  postCouponSpecial
} from '../../../../../api/coupons'
import regex from '../../../../../libs/regex'
import ImageView from '../../../../../components/ImageView'
import {getProductBrands} from '../../../../../api/productBrands'
import {getProductOrderTypes} from '../../../../../api/productOrderTypes'
import {getPriceGroups} from '../../../../../api/priceGroups'
import styled from 'styled-components'
import {getSalesPlatforms} from '../../../../../api/salesPlatforms'
import {getFinalValues} from '../../../../../api/finalValues'
import dayjs from 'dayjs'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPutCouponBundle | IPutCouponPrice | IPutCouponSpecial>
  onAction: (
    type: ActionType,
    record: Partial<IPutCouponBundle | IPutCouponPrice | IPutCouponSpecial>
  ) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

const PriceColumns = [
  {
    dataIndex: 'priceGroups',
    title: '대상 판매가 그룹',
    render: (priceGroups) => (priceGroups.length ? priceGroups.map(({name}) => name).join(', ') : '-')
  },
  {dataIndex: 'value', title: '판매가', render: (value) => value.toString().replace(regex.priceWithComma, ',') + '원'}
]

const BundleSpecialColumns = [
  {
    dataIndex: 'priceGroups',
    title: '대상 판매가 그룹',
    render: (priceGroups) => priceGroups.map(({name}) => name).join(', ')
  },
  {dataIndex: ['applyPriceGroup', 'name'], title: '적용 판매가 그룹'}
]

function Show({record}: {record: ICoupon | any}) {
  return (
    <div style={{padding: '0 28px 28px 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="타입">{record.type}</Descriptions.Item>
        <Descriptions.Item label="사용 가능 시작일">
          {dayjs(record.startDate).format('YYYY. MM. DD.')}
        </Descriptions.Item>
        <Descriptions.Item label="사용 가능 종료일">
          {record.endDate
            ? dayjs(record.endDate).format('YYYY. MM. DD. HH:mm:ss').includes('00:')
              ? dayjs(record.endDate).subtract(1, 'd').format('YYYY. MM. DD.')
              : dayjs(record.endDate).format('YYYY. MM. DD.')
            : '-'}
        </Descriptions.Item>
        {record.type === 'price' && (
          <>
            <Descriptions.Item label="할인 금액 및 대상 판매가 그룹">
              <Table columns={PriceColumns} dataSource={record.discounts} />
            </Descriptions.Item>
          </>
        )}
        {record.type === 'bundle' && (
          <>
            <Descriptions.Item label="할인 대상 판매가 그룹 & 적용 판매가 그룹">
              <Table columns={BundleSpecialColumns} dataSource={record.discounts} />
            </Descriptions.Item>
            <Descriptions.Item label="구입 수량 조건">{record.bundleCount}개</Descriptions.Item>
            <Descriptions.Item label="짝수 구매 시 적용여부">
              {record.isEven ? <CheckOutlined /> : <CloseOutlined />}
            </Descriptions.Item>
          </>
        )}
        {record.type === 'special' && (
          <Descriptions.Item label="할인 대상 판매가 그룹 & 적용 판매가 그룹">
            <Table columns={BundleSpecialColumns} dataSource={record.discounts} />
          </Descriptions.Item>
        )}
        {record.type !== 'special' && (
          <>
            <Descriptions.Item label="브랜드">
              <Space>
                <ImageView src={record.brand.titleImage} width={80} height={40} />
                {record.brand.name}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="제품군">
              {record.orderTypes.map((orderType, index) => (
                <Space>
                  <ImageView src={orderType.titleImage} width={40} height={40} />
                  {`${orderType.name}${index !== record.orderTypes.length - 1 ? ', ' : ''}`}
                </Space>
              ))}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="충전기 제외 할인 여부">
          {record.isCharge ? <CheckOutlined /> : <CloseOutlined />}
        </Descriptions.Item>
        <Descriptions.Item label="등록일">
          {dayjs(record.createdAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="수정일">
          {dayjs(record.updatedAt).format('YYYY. MM. DD. A hh:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({type, form, record, handleFinish, couponType, setCouponType}) {
  const [brands, setBrands] = useState<any[]>([])
  const [orderTypes, setOrderTypes] = useState<any[]>([])
  const [salesPlatforms, setSalesPlatforms] = useState<any[]>([])
  const [finalValues, setFinalValues] = useState<any[]>([])
  const [priceGroups, setPriceGroups] = useState<any[]>([])

  async function onBrandFetch() {
    const res = await getProductBrands()
    setBrands(res.data)
  }

  async function onOrderTypeFetch() {
    const res = await getProductOrderTypes()
    setOrderTypes(res.data)
  }

  async function onPriceGroupFetch() {
    const res = await getPriceGroups()
    setPriceGroups(res.data)
  }

  async function onSalesPlatformFetch() {
    const res = await getSalesPlatforms()
    setSalesPlatforms(res.data)
  }

  async function onFinalValueFetch() {
    const res = await getFinalValues()
    setFinalValues(res.data)
  }

  useEffect(() => {
    onBrandFetch()
    onOrderTypeFetch()
    onPriceGroupFetch()
    onSalesPlatformFetch()
    onFinalValueFetch()
    record.brandId = record.brand?.id
    if (record.orderTypes) record.orderTypeIds = record.orderTypes.map(({id}) => id)
    if (record.salesPlatforms) record.salesPlatformIds = record.salesPlatforms.map(({id}) => id)
    if (record.finalValues) record.finalValueIds = record.finalValues.map(({id}) => id)
    if (record.discounts)
      record.discounts = record.discounts.map((discount) => ({
        ...discount,
        priceGroupIds: discount.priceGroups.map(({id}) => id),
        applyPriceGroupId: discount?.applyPriceGroup?.id || -1
      }))

    if (record.type) setCouponType(record.type)
  }, [])

  useEffect(() => {
    if (type === 'edit')
      form.setFieldsValue({...record, startDateNew: dayjs(record.startDate), endDateNew: dayjs(record.endDate)})
  }, [record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      {type !== 'edit' && (
        <Form.Item label="쿠폰 타입" rules={[{required: true, message: '쿠폰 타입을 선택해주세요'}]}>
          <Radio.Group value={couponType} onChange={(e) => setCouponType(e.target.value)}>
            <Radio.Button value="bundle" defaultChecked>
              번들
            </Radio.Button>
            <Radio.Button value="price">가격</Radio.Button>
            <Radio.Button value="special">특별</Radio.Button>
          </Radio.Group>
        </Form.Item>
      )}

      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item
        label="이름"
        name="name"
        rules={[
          {required: true, message: '이름을 입력해주세요'},
          {min: 1, max: 100, message: '1 ~ 100자 이내로 입력해주세요'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="브랜드" name="brandId" rules={[{required: true, message: '브랜드를 선택해주세요'}]}>
        <Select>
          {brands
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((brand) => (
              <Select.Option value={brand.id}>{brand.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="제품군" name="orderTypeIds" rules={[{required: true, message: '제품군을 선택해주세요'}]}>
        <Select mode="multiple">
          {orderTypes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((orderType) => (
              <Select.Option value={orderType.id}>{orderType.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="판매 플랫폼" name="salesPlatformIds">
        <Select mode="multiple">
          {salesPlatforms
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((orderType) => (
              <Select.Option value={orderType.id}>{orderType.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="최종 분류 값 (상품 타입)" name="finalValueIds">
        <Select mode="multiple">
          {finalValues
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((orderType) => (
              <Select.Option value={orderType.id}>{orderType.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="사용 시작일" style={{marginBottom: 0}}>
        <Space>
          <Form.Item name="startDateNew" rules={[{required: true, message: '사용 시작일을 선택해주세요'}]}>
            <DatePicker />
          </Form.Item>
        </Space>
      </Form.Item>

      <Form.Item label="사용 종료일" style={{marginBottom: 0}}>
        <Space>
          <Form.Item name="endDateNew">
            <DatePicker />
          </Form.Item>
        </Space>
      </Form.Item>

      <Form.Item label="구입 수량 조건" name="bundleCount">
        <InputNumber />
      </Form.Item>

      <Form.Item label="기간 내 무제한 사용" name="isUnlimited" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="짝수 구매 시 적용 여부" name="isEven" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="대상 판매가 그룹 업체 일괄 발행" name="isAllSend" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="충전기 제외 할인 여부" name="isCharge" valuePropName="checked">
        <Switch />
      </Form.Item>

      {couponType === 'bundle' && (
        <>
          <DiscountTable>
            <DiscountTableTop>
              <Col span={13}>할인 대상 판매가 그룹</Col>
              <Col span={8}>적용 판매가 그룹</Col>
              <Col span={3} />
            </DiscountTableTop>
            <Form.List name="discounts">
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({name}, index) => (
                    <DiscountTableRow
                      style={{
                        borderLeft: '1px solid #f0f0f0',
                        borderRight: '1px solid #f0f0f0',
                        borderBottom: index !== fields.length ? '1px solid #f0f0f0' : ''
                      }}
                    >
                      <Col span={13}>
                        <StyledFormItem
                          name={[index, 'priceGroupIds']}
                          rules={[{required: true, message: '할인 대상 판매가 그룹을 선택해주세요'}]}
                        >
                          <Select mode="multiple" style={{width: 350}}>
                            {priceGroups
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((priceGroup) => (
                                <Select.Option value={priceGroup.id}>{priceGroup.name}</Select.Option>
                              ))}
                          </Select>
                        </StyledFormItem>
                      </Col>
                      <Col span={8}>
                        <StyledFormItem
                          name={[index, 'applyPriceGroupId']}
                          rules={[{required: true, message: '할인 시 적용할 판매가 그룹을 선택해주세요'}]}
                        >
                          <Select style={{width: 200}}>
                            {priceGroups
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((priceGroup) => (
                                <Select.Option value={priceGroup.id}>{priceGroup.name}</Select.Option>
                              ))}
                          </Select>
                        </StyledFormItem>
                      </Col>
                      <Col span={3}>
                        <Button danger onClick={() => remove(name)}>
                          삭제
                        </Button>
                      </Col>
                    </DiscountTableRow>
                  ))}
                  <Button
                    style={{margin: '10px auto'}}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    할인 추가
                  </Button>
                </>
              )}
            </Form.List>
          </DiscountTable>
        </>
      )}

      {couponType === 'price' && (
        <>
          <DiscountTable>
            <DiscountTableTop>
              <Col span={13}>할인 대상 판매가 그룹</Col>
              <Col span={8}>할인가</Col>
              <Col span={3} />
            </DiscountTableTop>
            <Form.List name="discounts">
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({name}, index) => (
                    <DiscountTableRow
                      style={{
                        borderLeft: '1px solid #f0f0f0',
                        borderRight: '1px solid #f0f0f0',
                        borderBottom: index !== fields.length ? '1px solid #f0f0f0' : ''
                      }}
                    >
                      <Col span={13}>
                        <StyledFormItem
                          name={[index, 'priceGroupIds']}
                          rules={[{required: true, message: '할인 대상 판매가 그룹을 선택해주세요'}]}
                        >
                          <Select mode="multiple" style={{width: 350}}>
                            {priceGroups
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((priceGroup) => (
                                <Select.Option value={priceGroup.id}>{priceGroup.name}</Select.Option>
                              ))}
                          </Select>
                        </StyledFormItem>
                      </Col>
                      <Col span={8}>
                        <StyledFormItem
                          name={[index, 'value']}
                          rules={[{required: true, message: '할인 가격을 입력해주세요'}]}
                        >
                          <InputNumber style={{width: 200}} />
                        </StyledFormItem>
                      </Col>
                      <Col span={3}>
                        <Button danger onClick={() => remove(name)}>
                          삭제
                        </Button>
                      </Col>
                    </DiscountTableRow>
                  ))}
                  <Button
                    style={{margin: '10px auto'}}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    할인 추가
                  </Button>
                </>
              )}
            </Form.List>
          </DiscountTable>
        </>
      )}

      {couponType === 'special' && (
        <>
          <DiscountTable>
            <DiscountTableTop>
              <Col span={13}>할인 대상 판매가 그룹</Col>
              <Col span={8}>적용 판매가 그룹</Col>
              <Col span={3} />
            </DiscountTableTop>
            <Form.List name="discounts">
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({name}, index) => (
                    <DiscountTableRow
                      style={{
                        borderLeft: '1px solid #f0f0f0',
                        borderRight: '1px solid #f0f0f0',
                        borderBottom: index !== fields.length ? '1px solid #f0f0f0' : ''
                      }}
                    >
                      <Col span={13}>
                        <StyledFormItem
                          name={[index, 'priceGroupIds']}
                          rules={[{required: true, message: '할인 대상 판매가 그룹을 선택해주세요'}]}
                        >
                          <Select mode="multiple" style={{width: 350}}>
                            {priceGroups
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((priceGroup) => (
                                <Select.Option value={priceGroup.id}>{priceGroup.name}</Select.Option>
                              ))}
                          </Select>
                        </StyledFormItem>
                      </Col>
                      <Col span={8}>
                        <StyledFormItem
                          name={[index, 'applyPriceGroupId']}
                          rules={[{required: true, message: '할인 시 적용할 판매가 그룹을 선택해주세요'}]}
                        >
                          <Select style={{width: 200}}>
                            {priceGroups
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((priceGroup) => (
                                <Select.Option value={priceGroup.id}>{priceGroup.name}</Select.Option>
                              ))}
                          </Select>
                        </StyledFormItem>
                      </Col>
                      <Col span={3}>
                        <Button danger onClick={() => remove(name)}>
                          삭제
                        </Button>
                      </Col>
                    </DiscountTableRow>
                  ))}
                  <Button
                    style={{margin: '10px auto'}}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    할인 추가
                  </Button>
                </>
              )}
            </Form.List>
          </DiscountTable>
        </>
      )}
    </Form>
  )
}

const CouponModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [couponType, setCouponType] = useState<any>('bundle')
  const {title = '쿠폰 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPutCouponBundle | IPutCouponPrice | IPutCouponSpecial) {
    setLoading(true)
    if (type === 'add' || type === 'edit') {
      try {
        const {id, startDateNew, endDateNew, ...rest}: any = values

        let startDate = dayjs(startDateNew).set('h', 0).set('m', 0).set('s', 0)
        let endDate = dayjs(endDateNew).set('h', 23).set('m', 59).set('s', 59)

        if (rest.couponUsageStatus) rest.couponUsageStatus = rest.couponUsageStatus.map((id) => ({vendorId: id}))
        if (!rest.isAllSend) rest.isAllSend = false
        if (!rest.isUnlimited) rest.isUnlimited = false
        if (!rest.bundleCount) rest.bundleCount = 0
        if (!rest.isCharge) rest.isCharge = false

        if (id) {
          if (couponType === 'bundle') await putCouponBundle(id, {startDate, endDate, ...rest})
          else if (couponType === 'price') await putCouponPrice(id, {startDate, endDate, ...rest})
          else await putCouponSpecial(id, {startDate: dayjs(startDate).format(''), endDate, ...rest})
        } else {
          if (couponType === 'bundle') await postCouponBundle({startDate, endDate, ...rest})
          else if (couponType === 'price') await postCouponPrice({startDate, endDate, ...rest})
          else await postCouponSpecial({startDate, endDate, ...rest})
        }

        onAction(type, values)
      } catch (e: any) {
        if (e.response) {
          Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    } else if (type === 'delete' && values.id) {
      await deleteCoupon(values.id)
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit
              type={type}
              form={form}
              record={record}
              handleFinish={(values) => handleFinish(type, values)}
              couponType={couponType}
              setCouponType={setCouponType}
            />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const DiscountTable = styled(Space)`
  width: 100%;
  margin: 40px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const DiscountTableRow = styled(Row)`
  width: 700px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 10px 0;
  }
`

const DiscountTableTop = styled(DiscountTableRow)`
  background: #f0f0f0;
  border-radius: 2px 2px 0 0;
  margin-bottom: -10px;

  & > div + div {
    border-left: 1px solid #cccccc;
  }
`

const StyledFormItem = styled(Form.Item)`
  margin: 0;
`

export default CouponModal

import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import dayjs from 'dayjs'
import PageContext from '../../../../contexts/PageContext'
import {Button} from 'antd'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import FinalValueModal from './components/FinalValueModal'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {getFinalValues} from '../../../../api/finalValues'
import ImageView from '../../../../components/ImageView'

function FinalValues() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {
      dataIndex: 'titleImage',
      title: '대표 이미지',
      render: (titleImage) => <ImageView height={100} preview={false} src={titleImage} />
    },
    {dataIndex: 'name', title: '이름'},
    {dataIndex: 'korean', title: '한국어명'},
    {dataIndex: 'comment', title: '설명'},
    {dataIndex: 'hidden', title: '숨기기', render: (hidden) => (hidden ? <CheckOutlined /> : <CloseOutlined />)},
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  return (
    <>
      <BasicTable
        rowKey="id"
        columns={columns}
        showSearch
        searchPlaceHolder="이름으로 검색"
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            최종 분류 값 등록
          </Button>
        ]}
        loading={state.loading}
        dataSource={state.data.data?.sort((a, b) => +(a.order > b.order) || +(a.order === b.order) - 1)}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'delete', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <FinalValueModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(FinalValues, getFinalValues)

import api from './index'

export interface IWireless {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostWireless extends Omit<IWireless, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IPatchWireless extends IPostWireless {}

interface IGetWirelessParams {
  start: number
  perPage: number
  search?: string
}

export async function postWireless(data: IPostWireless): Promise<{id: number}> {
  try {
    const res = await api.post(`/wireless`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getWireless(
  params: IGetWirelessParams = {start: 0, perPage: 1000}
): Promise<{data: IWireless[]; total: number}> {
  try {
    const res = await api.get(`/wireless`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getWirelessItem(id: number): Promise<IWireless> {
  try {
    const res = await api.get(`/wireless/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchWireless(id: number, data: IPatchWireless): Promise<{id: number}> {
  try {
    const res = await api.patch(`/wireless/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteWireless(id: number): Promise<void> {
  try {
    const res = await api.delete(`/wireless/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

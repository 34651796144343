import {RouteObject} from 'react-router'
import PriceGroups from '../pages/console/price/priceGroups'
import Prices from '../pages/console/price/prices'

export const priceRoute: RouteObject[] = [
  {
    path: '/console/price/prices',
    element: <Prices />
  },
  {
    path: '/console/price/priceGroups',
    element: <PriceGroups />
  }
]

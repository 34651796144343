import {InputNumberProps} from 'antd'
import {ReactNode} from 'react'
import api from './index'

interface ICommon {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

interface option {
  id: number
  name: string
}

export interface IProduct {
  id: number
  materialDescription: string
  summary: string
  description: string
  titleImage: string
  material: string
  barcode: string
  quantity: number
  safetyQuantity: number
  quantityUnit: number
  salesNumber: number
  purchaseNumber: number
  deliveryType: string
  currencyType: string
  channel: ICommon
  color: ICommon
  detailType1: ICommon
  detailType2: ICommon
  family: ICommon
  familyDetail: ICommon
  itemGroup: ICommon
  marketSegment: ICommon
  patientCategory: ICommon
  line: ICommon
  salesPlatform: ICommon
  segment: ICommon
  productGrade: ICommon
  productCharger: ICommon
  batteryType: string
  productReceiverDirection: ICommon
  productReceiverPower: ICommon
  productReceiverLength: ICommon
  brand: ICommon
  orderType: ICommon
  marketingPlatform: ICommon
  salesProduct: ICommon
  salesProductDetail: ICommon
  productFinalValue: ICommon
  iteOptionId: number
  onSale: boolean
  status: 'published' | 'discontinued' | 'hidden'
  createdAt: string
  updatedAt: string
  error?: ReactNode
  option?: {
    hook?: option[]
    charge?: option[]
    receiver?: {id: number; materialDescription: string; length: option; power: option; direction: option}[]
  }
}

export interface IPostProducts {
  channelId: number
  colorId?: number
  detailType1Id: number
  detailType2Id: number
  familyId?: number
  familyDetailId?: number
  itemGroupId: number
  marketSegmentId?: number
  patientCategoryId: number
  lineId?: number
  salesPlatformId?: number
  segmentId: number
  productChargerId?: number
  productReceiverDirectionId?: number
  productReceiverPowerId?: number
  productReceiverLengthId?: InputNumberProps
  materialDescription: string
  titleImage?: string
  material: string
  barcode: string
  quantityUnit: number
  salesNumber: number
  purchaseNumber: number
  deliveryType: string
  currencyType: string
  brandId: number
  marketingPlatformId?: number
  orderTypeId: number
  salesProductId?: number
  finalValueId?: number
  iteOptionId?: number
  onSale?: boolean
  status: 'published' | 'discontinued' | 'hidden'
}

export interface IPatchProducts extends IPostProducts {
  id?: number
}

interface IGetProductParams {
  start: number
  perPage: number
  search?: string
  orderTypeId?: number
}

export async function postProduct(data: IPostProducts): Promise<{id: number}> {
  try {
    const res = await api.post(`/products`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProducts(
  params: IGetProductParams = {start: 0, perPage: 1000}
): Promise<{data: IProduct[]; total: number}> {
  try {
    const res = await api.get(`/products`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProduct(id: number): Promise<IProduct> {
  try {
    const res = await api.get(`/products/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchProduct(id: number, data: IPatchProducts): Promise<{id: number}> {
  try {
    const res = await api.patch(`/products/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteProduct(id: number): Promise<void> {
  try {
    const res = await api.delete(`/products/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

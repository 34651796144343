import api from './index'
import {IImageCommon} from './productCategories'

interface Discount {
  id: number
  value: number
  priceGroups: IImageCommon[]
  applyPriceGroup: IImageCommon[]
}

interface Brand {
  name: string
  hidden: boolean
  id: number
  titleImage: string
  seq: number
  createdAt: string
  updatedAt: string
}

interface OrderTypes {
  name: string
  hidden: boolean
  id: number
  titleImage: string
  seq: number
  korean: string
  code: string
  createdAt: string
  updatedAt: string
}

export interface ICoupon {
  id: number
  name: string
  bundleCount: number
  isEven: boolean
  startDate: string
  endDate: string
  discounts: Discount[]
  type: 'price' | 'rate' | 'bundle' | 'special'
  brand: Brand
  orderTypes: OrderTypes[]
  createdAt: string
  updatedAt: string
}

export interface IPostCouponBundle {
  name: string
  bundleCount?: number
  isEven?: boolean
  startDate: string
  endDate?: string
  brandId: number
  discounts: {
    priceGroupIds: number[]
    applyPriceGroupId: number
  }[]
  orderTypeIds: number[]
}

export interface IPostCouponPrice {
  name: string
  startDate: string
  endDate?: string
  brandId: number
  discounts: {
    priceGroupIds: number[]
    value: number
  }
  orderTypeIds: number[]
}

export interface IPostCouponSpecial {
  name: string
  startDate: string
  endDate?: string
  discounts: {
    priceGroupIds: number[]
    applyPriceGroupId: number
  }
}

export interface IPutCouponBundle extends IPostCouponBundle {
  id?: number
}

export interface IPutCouponPrice extends IPostCouponPrice {
  id?: number
}

export interface IPutCouponSpecial extends IPostCouponSpecial {
  id?: number
}

interface IGetCouponParams {
  start: number
  perPage: number
}

export async function postCouponBundle(data: IPostCouponBundle): Promise<{id: number}> {
  try {
    const res = await api.post(`/coupons/bundle`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postCouponPrice(data: IPostCouponBundle): Promise<{id: number}> {
  try {
    const res = await api.post(`/coupons/price`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postCouponSpecial(data: IPostCouponBundle): Promise<{id: number}> {
  try {
    const res = await api.post(`/coupons/special`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCoupons(
  params: IGetCouponParams = {start: 0, perPage: 1000}
): Promise<{data: ICoupon[]; total: number}> {
  try {
    const res = await api.get(`/coupons`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCoupon(id: number): Promise<ICoupon> {
  try {
    const res = await api.get(`/coupons/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function putCouponBundle(id: number, data: IPutCouponBundle): Promise<{id: number}> {
  try {
    const res = await api.put(`/coupons/${id}/bundle`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function putCouponPrice(id: number, data: IPutCouponPrice): Promise<{id: number}> {
  try {
    const res = await api.put(`/coupons/${id}/price`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function putCouponSpecial(id: number, data: IPutCouponSpecial): Promise<{id: number}> {
  try {
    const res = await api.put(`/coupons/${id}/special`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteCoupon(id: number): Promise<void> {
  try {
    const res = await api.delete(`/coupons/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postCouponIssue(data: {data: {code: string; couponId: number}}): Promise<{id: number}> {
  try {
    const res = await api.post(`/coupons/excel/issue`, data)
    return res.data
  } catch (e) {
    throw e
  }
}
